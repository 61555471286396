import React from 'react'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import ImageEdit from "../common/ImageEdit";
import Switch from "react-switch";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import Flatpickr from 'react-flatpickr'
import moment from "moment";

export default class Subscription extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {
    this._load()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(this._getState(nextProps))
  }

  _getState(props) {
    return {
      ...props,
      company: props.company
    }
  }

  _load(){
    Backend.getCompanySubscription(this.state.company).then(subscriptions => {
      if(subscriptions.length > 0){
        this.setState({subscription: subscriptions[0]})
      }
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _update(){
    let {
      subscription
    } = this.state

    Backend.updateCompanySubscription(subscription).then(subscriptions => {
      Notify.success("Subscription updated")
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  render() {
    let {
      subscription
    } = this.state

    if(!subscription) return null

    return (
      <>
        <div className="form-group">
          <label>Package</label>
          <input
            className="form-control"
            type="text"
            value={subscription.package.title}
            disabled
          />
        </div>
        <div className="form-group">
          <label>Job Credits</label>
          <input
            className="form-control"
            type="text"
            value={subscription.credits}
            onChange={e => {
              subscription.credits = e.target.value
              this.setState({subscription})
            }}
          />
        </div>
        <div className="form-group">
          <label>Talent Credits</label>
          <input
            className="form-control"
            type="text"
            value={subscription.jobbions}
            onChange={e => {
              subscription.jobbions = e.target.value
              this.setState({subscription})
            }}
          />
        </div>
        <div className="form-group">
          <label>VPO</label>
          <div className="row">
            <div className="col-lg-4">
              Company App<br/>
              <Switch
                className="react-switch"
                onChange={checked => {
                  subscription.vpo.company_app = checked
                  this.setState({subscription})
                }}
                checked={subscription.vpo.company_app}
                aria-labelledby="neat-label"
                onColor="#5867dd"
                offColor="#d5d5d5"
                checkedIcon={null}
                uncheckedIcon={null}
              />
            </div>
            <div className="col-lg-4">
              Avatar<br/>
              <Switch
                className="react-switch"
                onChange={checked => {
                  subscription.vpo.avi = checked
                  this.setState({subscription})
                }}
                checked={subscription.vpo.avi}
                aria-labelledby="neat-label"
                onColor="#5867dd"
                offColor="#d5d5d5"
                checkedIcon={null}
                uncheckedIcon={null}
              />
            </div>
            <div className="col-lg-4">
              Media Posts<br/>
              <Switch
                className="react-switch"
                onChange={checked => {
                  subscription.vpo.media_posts = checked
                  this.setState({subscription})
                }}
                checked={subscription.vpo.media_posts}
                aria-labelledby="neat-label"
                onColor="#5867dd"
                offColor="#d5d5d5"
                checkedIcon={null}
                uncheckedIcon={null}
              />
            </div>
            <div className="col-lg-4">
              Team Collaboration<br/>
              <Switch
                className="react-switch"
                onChange={checked => {
                  subscription.vpo.team_collaboration = checked
                  this.setState({subscription})
                }}
                checked={subscription.vpo.team_collaboration}
                aria-labelledby="neat-label"
                onColor="#5867dd"
                offColor="#d5d5d5"
                checkedIcon={null}
                uncheckedIcon={null}
              />
            </div>
            <div className="col-lg-4">
              ATS<br/>
              <Switch
                className="react-switch"
                onChange={checked => {
                  subscription.vpo.ats = checked
                  this.setState({subscription})
                }}
                checked={subscription.vpo.ats}
                aria-labelledby="neat-label"
                onColor="#5867dd"
                offColor="#d5d5d5"
                checkedIcon={null}
                uncheckedIcon={null}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>Start Date</label>
          <div className="input-group">
            <DatePicker
              selected={Date.parse(subscription.created_at) || ''}
              dateFormat={'yyyy-MM-dd'}
              className="form-control"
              placeholderText={"Issued Date"}
              onChange={date => {
                subscription.created_at = moment(date).format('YYYY-MM-DD')
                this.setState({subscription})
              }}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              disabled={true}
            />
          </div>

        </div>
        <div className="form-group">
          <label>Expiration Date</label>
          <div className="input-group">
            <DatePicker
              selected={Date.parse(subscription.expires_at) || ''}
              dateFormat={'yyyy-MM-dd'}
              className="form-control"
              placeholderText={"Issued Date"}
              onChange={date => {
                subscription.expires_at = moment(date).format()
                this.setState({subscription})
              }}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
        </div>
        <div className="form-group float-right">
          <div className="text-center">
            <button
              id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
              onClick={() => this._update()}
            >
              Update
            </button>
          </div>
        </div>
      </>
    )
  }
}
