import React from 'react'
import { Modal, Tabs, Tab } from 'react-bootstrap';
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

export default class AddJobTypeModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      activeTab: "edit",
      show: props.show,
      jobType: {},
    }
  }

  _add(){
    let {
      jobType,
    } = this.state

    Backend.addJobType(jobType).then(response => {
      Notify.success("Job Type Added")
      this.props.onCancel()
    }).catch(e => Notify.error(e.message))
  }

  render() {
    let {
      loading,
      show,
      jobType,
    } = this.state

    if(!jobType || !show) return null

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
        className={this.props.modalClass}
      >
        <Modal.Header closeButton>
          <h5 className={"modal-title"}>Add Job Type</h5>
        </Modal.Header>

        <Modal.Body>

          <div className="form-group">
            <label>Name</label>
            <input
              className="form-control"
              type="text"
              value={jobType.name}
              onChange={e => {
                jobType.name = e.target.value
                this.setState({jobType})
              }}
            />
          </div>

        </Modal.Body>

        <Modal.Footer>
          <div className="col-md-4 text-center">
            <button
              id="reject" type="button" className="btn btn-secondary" style={{width: '100%'}}
              disabled={loading}
              onClick={() => this.props.onCancel()}
            >
              Close
            </button>
          </div>
          <div className="col-md-4 text-center">
            <button
              id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
              onClick={() => this._add()}
            >
              Add
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

AddJobTypeModal.defaultProps = {
  loading: false,
  modalClass: 'process-company',
  buttonClassName: 'primary',
}
