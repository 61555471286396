import React from 'react'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import ImageEdit from "../common/ImageEdit";
import Switch from "react-switch";
import AddressInput from "../common/AddressInput";

export default class Edit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(this._getState(nextProps))
  }

  _getState(props) {
    return {
      ...props,
      company: props.company,
    }
  }

  _load(){
    Backend.getCompany(this.state.company).then(company => {
      this.setState({company})
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _uploadImageFile(file, attribute){
    let {
      company
    } = this.state

    this.setState({ loading: true })
    Backend.uploadImage(file, "company")
      .then(image => {
        company[attribute] = image.image_url
        this.setState({ loading: false, company })
      })
      .catch(error => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _update(){
    let {
      company
    } = this.state

    Backend.updateCompany(company).then(e => {
      Notify.success("Company Updated")
    }).catch(e => Notify.error(e.message))
  }

  render() {
    let {
      company
    } = this.state

    return (
      <>
        <div className="form-group">
          <label>Logo</label>
          <ImageEdit
            image={company.image_logo}
            type={"avatar"}
            hidePreview={false}
            deletable={true}
            uploadImageFile={file => this._uploadImageFile(file, "image_logo")}
            onDeleteClicked={() => {
              company.image_logo = null
              this.setState({company})
            }}
          />
        </div>
        {
          company.branding_channel &&
          <div className="form-group">
            <label>Nav Logo</label>
            <ImageEdit
              image={company.image_nav_logo}
              type={"avatar"}
              hidePreview={false}
              deletable={true}
              uploadImageFile={file => this._uploadImageFile(file, "image_nav_logo")}
              onDeleteClicked={() => {
                company.image_nav_logo = null
                this.setState({company})
              }}
            />
          </div>
        }
        <div className="form-group">
          <label>Banner</label>
          <ImageEdit
            image={company.image_banner}
            type={"banner"}
            hidePreview={false}
            deletable={true}
            uploadImageFile={file => this._uploadImageFile(file, "image_banner")}
            onDeleteClicked={() => {
              company.image_banner = null
              this.setState({company})
            }}
          />
        </div>
        <div className="form-group">
          <label>Card</label>
          <ImageEdit
            image={company.image_card}
            type={"banner"}
            hidePreview={false}
            deletable={true}
            uploadImageFile={file => this._uploadImageFile(file, "image_card")}
            onDeleteClicked={() => {
              company.image_card = null
              this.setState({company})
            }}
          />
        </div>
        <div className="form-group">
          <label>Color</label>
          <input
            className="form-control"
            type="color"
            value={company.colour}
            onChange={e => {
              company.colour = e.target.value
              this.setState({company})
            }}
          />
        </div>
        <div className="form-group">
          <label>Status</label>
          <select
            className={"form-control"}
            value={company.status}
            onChange={e => {
              company.status = e.target.value
              this.setState({company})
            }}
          >
            <option value="active">Active</option>
            <option value="frozen">Frozen</option>
            <option value="inactive">Inactive</option>
            <option value="pending">Pending</option>
          </select>
        </div>
        <div className="form-group">
          <label>Sponsored</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              company.sponsored = checked
              this.setState({company})
            }}
            checked={company.sponsored}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        <div className="form-group">
          <label>Redirect</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              company.redirect = checked
              this.setState({company})
            }}
            checked={company.redirect}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        <div className="form-group">
          <label>Branded Company</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              company.branding_channel = checked
              this.setState({company})
            }}
            checked={company.branding_channel}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        <div className="form-group">
          <label>Address</label>
          <AddressInput
            address={company.location}
            onUpdated={addressInput => {
              let address = JSON.parse(JSON.stringify(addressInput))
              address.address   = addressInput.line_1
              address.city    = addressInput.city.name
              address.state   = addressInput.city.state.name
              address.country = addressInput.city.state.country.name
              address.country_short = addressInput.city.state.country.short_code
              address.latitude = addressInput.latitude.toFixed(7)
              address.longitude = addressInput.longitude.toFixed(7)

              company.location = address
              company = {...company, ...address}
              this.setState({ company })
            }}
          />
        </div>
        <div className="form-group float-right">
          <div className="text-center">
            <button
              id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
              onClick={() => this._update()}
            >
              Update
            </button>
          </div>
        </div>
      </>
    )
  }
}