import React from 'react'
import ApplicationsTable from "./components/tables/ApplicationsTable";

export default class Applications extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

    }
    this.documentsTable = React.createRef()
  }

  componentDidMount(){

  }

  render() {

    let {

    } = this.state

    return (
      <>
        <ApplicationsTable
          title={"Applications"}
        />
      </>
    )
  }
}
