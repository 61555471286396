import React from 'react'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import ImageEdit from "../common/ImageEdit";
import TextEditor from "../common/TextEditor";
import CountrySelect from "../common/CountrySelect";
import Switch from "react-switch";

export default class FeedControl extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {
    this._load()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(this._getState(nextProps))
  }

  _getState(props) {
    return {
      ...props,
      company: props.company,
    }
  }

  _load(){
    Backend.getCompanyFeedControl(this.state.company).then(feedControl => {
      this.setState({feedControl})
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _update(){
    let {
      feedControl
    } = this.state

    Backend.updateCompanyFeedControl(feedControl).then(e => {
      Notify.success("Job Feed Updated")
    }).catch(e => Notify.error(e.message))
  }

  render() {
    let {
      feedControl
    } = this.state

    if(!feedControl) return null

    return (
      <>
        <div className="form-group">
          <label>Career Builder</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              feedControl.career_builder = checked
              this.setState({feedControl})
            }}
            checked={feedControl.career_builder}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        <div className="form-group">
          <label>Click Cast</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              feedControl.click_cast = checked
              this.setState({feedControl})
            }}
            checked={feedControl.click_cast}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        <div className="form-group">
          <label>CV Library</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              feedControl.cv_library = checked
              this.setState({feedControl})
            }}
            checked={feedControl.cv_library}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        <div className="form-group">
          <label>Indeed</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              feedControl.indeed = checked
              this.setState({feedControl})
            }}
            checked={feedControl.indeed}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        <div className="form-group">
          <label>LinkedIn</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              feedControl.linkedin = checked
              this.setState({feedControl})
            }}
            checked={feedControl.linkedin}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        <div className="form-group">
          <label>Reed</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              feedControl.reed = checked
              this.setState({feedControl})
            }}
            checked={feedControl.reed}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        <div className="form-group float-right">
          <div className="text-center">
            <button
              id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
              onClick={() => this._update()}
            >
              Update
            </button>
          </div>
        </div>
      </>
    )
  }
}