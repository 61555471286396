import React from "react"

import Div from "../Div"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Email from "../../../utils/Email"
import Notify from "../../../utils/Notify"
import SmartList from "../common/SmartList";
import BlogPostModal from "../modal/BlogPostModal";

export default class BlogPost extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      blogPost: props.blogPost
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _delete(){
    let {
      blogPost,
    } = this.state

    Backend.deleteBlogPost(blogPost).then(() => {
      Notify.success("Blog Post Deleted")
      this.props.onUpdate()
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  render() {
    let {
      blogPost,
      showBlogPostModal
    } = this.state

    return (
      <>
        <div className="col-lg-4">
          <div
            className="kt-portlet kt-portlet--tabs kt-portlet--height-fluid pt-3 pl-3"
            style={{
              background: blogPost.image ? `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${blogPost.image}` : null,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 5
            }}
          >
            <h4 style={{color: "white"}}>{blogPost.title}</h4>
            <p style={{color: "white"}}>{blogPost.text}</p>
            <div className="form-group float-right">
              <button
                id="escalate" type="button" className="btn btn-success mr-2"
                onClick={() => this.setState({showBlogPostModal: true})}
              >
                Edit
              </button>
              <button
                id="escalate" type="button" className="btn btn-danger"
                onClick={() => this._delete()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
        {
          showBlogPostModal &&
          <BlogPostModal
            show={showBlogPostModal}
            blogPost={blogPost}
            onCancel={() => this.setState({showBlogPostModal: false})}
            onUpdate={() => {
              this.props.onUpdate()
              this.setState({showBlogPostModal: false})
            }}
          />
        }
      </>
    )
  }
}
