import React from 'react'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import ImageEdit from "../common/ImageEdit";
import moment from "moment";
import DatePicker from "react-datepicker";

export default class Stats extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {
    this._load()
  }

  componentWillReceiveProps(nextProps) {
    // this.setState(this._getState(nextProps), () => this._load())
  }

  _getState(props) {
    return {
      ...props,
      channel: props.channel,
      fromDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      stats: []
    }
  }

  _load(){
    let {
      toDate,
      channel,
      fromDate
    } = this.state

    Backend.getChannelStats(channel, fromDate, toDate).then(stats => {
      this.setState({stats})
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _export(){
    let {
      toDate,
      channel,
      fromDate
    } = this.state

    Backend.exportChannelStats(channel, fromDate, toDate).then(stats => {
      Notify.success("Export requested, check your email in a few minutes.")
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  render() {
    let {
      stats,
      toDate,
      fromDate
    } = this.state

    return (
      <>
        <div
          className={"row"}
        >
          <div className={"col-lg-3"}>
            <div className="form-group">
              <label>From Date</label>
              <DatePicker
                selected={Date.parse(fromDate) || ''}
                dateFormat={'yyyy-MM-dd'}
                className="form-control"
                placeholderText={"Post Date"}
                onChange={date => {
                  fromDate = moment(date).format('YYYY-MM-DD')
                  this.setState({fromDate})
                }}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>
          <div className={"col-lg-3"}>
            <div className="form-group">
              <label>To Date</label>
              <DatePicker
                selected={Date.parse(toDate) || ''}
                dateFormat={'yyyy-MM-dd'}
                className="form-control"
                placeholderText={"Post Date"}
                onChange={date => {
                  toDate = moment(date).format('YYYY-MM-DD')
                  this.setState({toDate})
                }}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>
          <div className={"col-lg-2"}/>
          <div className={"col-lg-2"}>
            <button
              id="escalate" type="button" className="btn btn-secondary mt-3" style={{width: '100%'}}
              onClick={() => this._export()}
            >
              Export
            </button>
          </div>
          <div className={"col-lg-2"}>
            <button
              id="escalate" type="button" className="btn btn-primary mt-3" style={{width: '100%'}}
              onClick={() => this._load()}
            >
              Load
            </button>
          </div>
        </div>
        <div
          style={{overflowY: 'scroll', textAlign: 'center'}}
        >
          <table
            className="table"
          >
            <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Applications</th>
              <th scope="col">Article Views</th>
              <th scope="col">Article Impression</th>
              <th scope="col">Channel Views</th>
              <th scope="col">Channel Impression</th>
              <th scope="col">Company Views</th>
              <th scope="col">Company Impression</th>
              <th scope="col">Job Views</th>
              <th scope="col">Job Impression</th>
              <th scope="col">Homepage Views</th>
              <th scope="col">Homepage Searches</th>
              <th scope="col">Clicks</th>
              <th scope="col">Redirects</th>
              <th scope="col">Registrations</th>
            </tr>
            </thead>
            <tbody>
            {
              stats.map(stat => (
                <tr>
                  <td>{stat.date}</td>
                  <td>{stat.applications}</td>
                  <td>{stat.article_views}</td>
                  <td>{stat.article_impressions}</td>
                  <td>{stat.channel_views}</td>
                  <td>{stat.channel_impressions}</td>
                  <td>{stat.company_views}</td>
                  <td>{stat.company_impressions}</td>
                  <td>{stat.job_views}</td>
                  <td>{stat.job_impressions}</td>
                  <td>{stat.home_page_views}</td>
                  <td>{stat.home_page_searches}</td>
                  <td>{stat.clicks}</td>
                  <td>{stat.redirects}</td>
                  <td>{stat.registrations}</td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </div>
      </>
    )
  }
}
