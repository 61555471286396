import React from 'react'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import ImageEdit from "../common/ImageEdit";
import Switch from "react-switch";

export default class Edit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {
    // this._load()
  }

  componentWillReceiveProps(nextProps) {
    // this.setState(this._getState(nextProps), () => this._load())
  }

  _getState(props) {
    return {
      ...props,
      packageObject: props.packageObject,
    }
  }

  _uploadImageFile(file, attribute){
    let {
      packageObject
    } = this.state

    this.setState({ loading: true })
    Backend.uploadImage(file, "package")
      .then(image => {
        packageObject[attribute] = image.image_url
        this.setState({ loading: false, packageObject })
      })
      .catch(error => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _update(){
    let {
      packageObject
    } = this.state

    Backend.updatePackage(packageObject).then(e => {
      this.props.onUpdate(packageObject)
      Notify.success("Package Updated")
    }).catch(e => Notify.error(e.message))
  }

  render() {
    let {
      packageObject
    } = this.state

    return (
      <>
        <div className="form-group">
          <label>Title</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.title}
            onChange={e => {
              packageObject.title = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Type</label>
          <select
            className={"form-control"}
            value={packageObject.type}
            onChange={e => {
              packageObject.type = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          >
            <option value="once-off">One Off</option>
            <option value="subscription">Subscription</option>
          </select>
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea
            className="form-control"
            value={packageObject.description}
            rows={3}
            onChange={e => {
              packageObject.description = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Price Per Month</label>
              <input
                className="form-control"
                type="number"
                value={packageObject.price}
                onChange={e => {
                  packageObject.price = e.target.value
                  if(packageObject.id){
                    this.setState({packageObject})
                  }else{
                    this.setState({packageObject})
                    this.props.onUpdate(packageObject)
                  }
                }}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>VAT</label>
              <input
                className="form-control"
                type="number"
                value={packageObject.vat}
                onChange={e => {
                  packageObject.vat = e.target.value
                  if(packageObject.id){
                    this.setState({packageObject})
                  }else{
                    this.setState({packageObject})
                    this.props.onUpdate(packageObject)
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Currency</label>
              <select
                className={"form-control"}
                value={packageObject.currency}
                onChange={e => {
                  packageObject.currency = e.target.value
                  if(packageObject.id){
                    this.setState({packageObject})
                  }else{
                    this.setState({packageObject})
                    this.props.onUpdate(packageObject)
                  }
                }}
              >
                <option value="">Select Currency</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
                <option value="USD">USD</option>
                <option value="CAD">CAD</option>
              </select>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Validity</label>
              <input
                className="form-control"
                type="number"
                value={packageObject.validity}
                onChange={e => {
                  packageObject.validity = e.target.value
                  if(packageObject.id){
                    this.setState({packageObject})
                  }else{
                    this.setState({packageObject})
                    this.props.onUpdate(packageObject)
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>VPO</label>
          <div className="row">
            <div className="col-lg-4">
              Company App<br/>
              <Switch
                className="react-switch"
                onChange={checked => {
                  packageObject.vpo.company_app = checked
                  if(packageObject.id){
                    this.setState({packageObject})
                  }else{
                    this.setState({packageObject})
                    this.props.onUpdate(packageObject)
                  }
                }}
                checked={packageObject.vpo.company_app}
                aria-labelledby="neat-label"
                onColor="#5867dd"
                offColor="#d5d5d5"
                checkedIcon={null}
                uncheckedIcon={null}
              />
            </div>
            <div className="col-lg-4">
              Avatar<br/>
              <Switch
                className="react-switch"
                onChange={checked => {
                  packageObject.vpo.avi = checked
                  if(packageObject.id){
                    this.setState({packageObject})
                  }else{
                    this.setState({packageObject})
                    this.props.onUpdate(packageObject)
                  }
                }}
                checked={packageObject.vpo.avi}
                aria-labelledby="neat-label"
                onColor="#5867dd"
                offColor="#d5d5d5"
                checkedIcon={null}
                uncheckedIcon={null}
              />
            </div>
            <div className="col-lg-4">
              Media Posts<br/>
              <Switch
                className="react-switch"
                onChange={checked => {
                  packageObject.vpo.media_posts = checked
                  if(packageObject.id){
                    this.setState({packageObject})
                  }else{
                    this.setState({packageObject})
                    this.props.onUpdate(packageObject)
                  }
                }}
                checked={packageObject.vpo.media_posts}
                aria-labelledby="neat-label"
                onColor="#5867dd"
                offColor="#d5d5d5"
                checkedIcon={null}
                uncheckedIcon={null}
              />
            </div>
            <div className="col-lg-4">
              Team Collaboration<br/>
              <Switch
                className="react-switch"
                onChange={checked => {
                  packageObject.vpo.team_collaboration = checked
                  if(packageObject.id){
                    this.setState({packageObject})
                  }else{
                    this.setState({packageObject})
                    this.props.onUpdate(packageObject)
                  }
                }}
                checked={packageObject.vpo.team_collaboration}
                aria-labelledby="neat-label"
                onColor="#5867dd"
                offColor="#d5d5d5"
                checkedIcon={null}
                uncheckedIcon={null}
              />
            </div>
            <div className="col-lg-4">
              ATS<br/>
              <Switch
                className="react-switch"
                onChange={checked => {
                  packageObject.vpo.ats = checked
                  if(packageObject.id){
                    this.setState({packageObject})
                  }else{
                    this.setState({packageObject})
                    this.props.onUpdate(packageObject)
                  }
                }}
                checked={packageObject.vpo.ats}
                aria-labelledby="neat-label"
                onColor="#5867dd"
                offColor="#d5d5d5"
                checkedIcon={null}
                uncheckedIcon={null}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Job Credits</label>
              <input
                className="form-control"
                type="number"
                value={packageObject.credits}
                onChange={e => {
                  packageObject.credits = e.target.value
                  if(packageObject.id){
                    this.setState({packageObject})
                  }else{
                    this.setState({packageObject})
                    this.props.onUpdate(packageObject)
                  }
                }}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Talent Credit</label>
              <input
                className="form-control"
                type="number"
                value={packageObject.jobbions}
                onChange={e => {
                  packageObject.jobbions = e.target.value
                  if(packageObject.id){
                    this.setState({packageObject})
                  }else{
                    this.setState({packageObject})
                    this.props.onUpdate(packageObject)
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>Form Title</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.form_title}
            onChange={e => {
              packageObject.form_title = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Hero Title</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.hero_title}
            onChange={e => {
              packageObject.hero_title = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Subtitle</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.subtitle}
            onChange={e => {
              packageObject.subtitle = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>CTA</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.cta}
            onChange={e => {
              packageObject.cta = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Image</label>
          <ImageEdit
            image={packageObject.image}
            type={"banner"}
            hidePreview={false}
            deletable={true}
            uploadImageFile={file => this._uploadImageFile(file, "image")}
            onDeleteClicked={() => {
              packageObject.image = null
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        {
          packageObject.id &&
          <div className="form-group float-right">
            <div className="text-center">
              <button
                id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
                onClick={() => this._update()}
              >
                Update
              </button>
            </div>
          </div>
        }

      </>
    )
  }
}
