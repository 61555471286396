import React from 'react'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import ImageEdit from "../common/ImageEdit";
import TextEditor from "../common/TextEditor";
import CountrySelect from "../common/CountrySelect";
import Switch from "react-switch";

export default class PartnerControl extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {
    this._load()
  }

  // componentWillReceiveProps(nextProps, nextContext) {
  //   this.setState(this._getState(nextProps), ())
  // }

  _getState(props) {
    return {
      ...props,
      company: props.company,
      channels: []
    }
  }

  _load(){
    Backend.getPartners(this.state.company).then(channels => {
      this.setState({channels})
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _update(){
    let {
      company,
      channels
    } = this.state

    Backend.updateCompanyPartner(company, channels).then(e => {
      Notify.success("Partner List Updated")
    }).catch(e => Notify.error(e.message))
  }

  render() {
    let {
      channels
    } = this.state

    return (
      <>
        {
          channels.map(channel => (
            <div className="form-group">
              <label>{channel.name}</label>
              <Switch
                className="react-switch pull-right"
                onChange={checked => {
                  channel.has_company = checked
                  this.setState({channel})
                }}
                checked={channel.has_company}
                aria-labelledby="neat-label"
                onColor="#5867dd"
                offColor="#d5d5d5"
                checkedIcon={null}
                uncheckedIcon={null}
              />
            </div>
          ))
        }

        <div className="form-group float-right">
          <div className="text-center">
            <button
              id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
              onClick={() => this._update()}
            >
              Update
            </button>
          </div>
        </div>
      </>
    )
  }
}