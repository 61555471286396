import React from 'react'
 import { Modal, Tabs, Tab } from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Switch from "react-switch";

import Notify from "../../../utils/Notify";
import Backend from "../../../utils/Backend"
import SmartList from "../common/SmartList";
import Edit from "../company/Edit";
import Members from "../company/Members";
import Subscription from "../company/Subscription";
import JobsTable from "../tables/JobsTable";
import ExternalDistributionTable from "../tables/ExternalDistributionTable";
import FeedControl from "../company/FeedControl";
import Card from "../company/Card";
import moment from "moment";
import DatePicker from "react-datepicker";
import PartnerControl from "../company/PartnerControl";

export default class CompanyModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {
    this._load()
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps), () => this._load())
  }

  _getState(props){
    return {
      ...props,
      activeTab: "edit",
      show: props.show,
      company: props.company,
      fromDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    }
  }

  _load(){
    Backend.getCompany(this.state.company).then(company => {
      this.setState({company})
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _requestExport(){
    let {
      fromDate,
      company,
      toDate
    } = this.state

    if(!fromDate || !toDate){
      Notify.error("Please include both dates")
      return
    }

    Backend.requestCompanyData(fromDate, toDate, company).then(() => {
      Notify.success("Export requested, please check your email in a little while.")
    }).catch(e => Notify.error(e.message))
  }

  render() {
    let {
      activeTab,
      loading,
      show,
      company,
      fromDate,
      toDate
    } = this.state

    if(!company || !show) return null

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
        className={this.props.modalClass}
      >
        <Modal.Header closeButton>
          <h5 className={"modal-title"}>Update {company.name}</h5>
        </Modal.Header>

        <Modal.Body>

          <Tabs
            activeKey={activeTab}
            onSelect={(tab) => this.setState({activeTab: tab})}
          >
            <Tab eventKey={"edit"} title={"Edit"}>
              <Edit
                company={company}
              />
            </Tab>
            <Tab eventKey={"card-entry"} title={"Card Entries"}>
              <Card
                company={company}
              />
            </Tab>
            <Tab eventKey={"members"} title={"Members"}>
              <Members
                company={company}
              />
            </Tab>
            <Tab eventKey={"subscription"} title={"Subscription"}>
              <Subscription
                company={company}
              />
            </Tab>
            <Tab eventKey={"jobs"} title={"Jobs"}>
              <JobsTable
                title={"Jobs"}
                company={company}
              />
            </Tab>
            <Tab eventKey={"external-distributions"} title={"External Distributions"}>
              <FeedControl
                company={company}
              />
            </Tab>
            <Tab eventKey={"partnership"} title={"Partners"}>
              <PartnerControl
                company={company}
              />
            </Tab>
            <Tab eventKey={"renewal-data"} title={"Renewal Data Request"}>
              <div
                className={"row"}
              >
                <div className={"col-lg-6"}>
                  <div className="form-group">
                    <label>From Date</label>
                    <DatePicker
                      selected={Date.parse(fromDate) || ''}
                      dateFormat={'yyyy-MM-dd'}
                      className="form-control"
                      placeholderText={"Post Date"}
                      onChange={date => {
                        fromDate = moment(date).format('YYYY-MM-DD')
                        this.setState({fromDate})
                      }}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>
                <div className={"col-lg-6"}>
                  <div className="form-group">
                    <label>To Date</label>
                    <DatePicker
                      selected={Date.parse(toDate) || ''}
                      dateFormat={'yyyy-MM-dd'}
                      className="form-control"
                      placeholderText={"Post Date"}
                      onChange={date => {
                        toDate = moment(date).format('YYYY-MM-DD')
                        this.setState({toDate})
                      }}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group float-right">
                <div className="text-center">
                  <button
                    id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
                    onClick={() => this._requestExport()}
                  >
                    Request Export
                  </button>
                </div>
              </div>
            </Tab>
          </Tabs>

        </Modal.Body>

        <Modal.Footer>
          <div className="col-md-4 text-center">
            <button
              id="reject" type="button" className="btn btn-secondary" style={{width: '100%'}}
              disabled={loading}
              onClick={() => this.props.onCancel()}
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

CompanyModal.defaultProps = {
  loading: false,
  modalClass: 'process-company',
  buttonClassName: 'primary',
}
