import React from 'react'
 import { Modal, Tabs, Tab } from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Switch from "react-switch";

import Notify from "../../../utils/Notify";
import Backend from "../../../utils/Backend"
import SmartList from "../common/SmartList";
import Edit from "../channel/Edit";
import Members from "../company/Members";
import Subscription from "../company/Subscription";
import JobsTable from "../tables/JobsTable";
import ExternalDistributionTable from "../tables/ExternalDistributionTable";
import FeedControl from "../company/FeedControl";
import LazyLoadingList from "../common/LazyLoadingList";
import FetchHelper from "../../../utils/FetchHelper";

export default class ChannelInterest extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      endpoint: `${window.Api.AdminChannels}/${props.channel.id}/interests?in_channel=${props.inChannel}&type=${props.type}`,
      interests: [],
      showLoadMore: false,
    }
  }

  componentDidMount() {
    let {
      type,
      channel,
      inChannel
    } = this.state

    this._load(channel, inChannel, type)
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      type: props.type,
      channel: props.channel,
      inChannel: props.inChannel
    }
  }

  _load(){
    let {
      type,
      channel,
      endpoint,
      interests,
      inChannel,
    } = this.state

    this.setState({ loading: true})

    FetchHelper.get(endpoint).then(response => {
      if(!inChannel){
        endpoint = response.next

        interests = [
          ...interests,
          ...response.results
        ]
      }else{
        interests = response
        endpoint = null
      }
      let showLoadMore = endpoint != null

      this.setState({
        endpoint,
        interests,
        showLoadMore,
        loading: false
      }, () => this._processInChannel())
    }).catch(e => {
      Notify.error(e.message)
      this.setState({ loading: false})
    })
  }

  _processInChannel(){
    let {
      interests,
      inChannel,
    } = this.state

    let processedInterests = []

    if(inChannel){
      processedInterests = interests.map(interest => interest.id)
      this.props.onProcessed(processedInterests)
    }
  }

  _renderLoadMore(){
    let {
      loading,
      showLoadMore,
    } = this.state

    if(loading){
      return <p className="w-100 text-center">Loading...</p>
    }

    if(!showLoadMore){
      return null
    }

    return (
      <div className="col-12">
        <a
          className="btn btn--secondary w-100"
          style={{
            color: "#222",
            borderColor: "#222",
            backgroundColor: "transparent"
          }}
          onClick={() => this._load()}
          disabled={!showLoadMore}
        >
          Load More
        </a>
      </div>
    )
  }

  render() {
    let {
      type,
      interests,
    } = this.state

    return (
      <>
        <div style={{height: 300, overflow: 'scroll', marginBottom: 30}}>
          <div className="row col-lg-12">
            {
              interests.map((interest, index) => {
                let title = interest.name
                return (
                  <div className={"col-lg-4"}>
                    <div className="d-flex align-items-center mb-3">
                      <Switch
                        className="react-switch pull-right"
                        onChange={checked => {
                          interest.in_channel = checked
                          interests[index] = interest
                          this.setState({interests}, () => this.props.onAdd(interest.id))
                        }}
                        checked={interest.in_channel}
                        aria-labelledby="neat-label"
                        onColor="#5867dd"
                        offColor="#d5d5d5"
                        checkedIcon={null}
                        uncheckedIcon={null}
                      />
                      <div className="d-flex flex-column font-weight-bold ml-3">
                        <a href="#" className="text-dark text-hover-primary mb-1 font-size-lg">{title}</a>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
          {this._renderLoadMore()}
        </div>
      </>
    )
  }
}

ChannelInterest.defaultProps = {
  loading: false,
  modalClass: 'process-company',
  buttonClassName: 'primary',
}
