import React from 'react'

import Aside from './components/common/Aside'
import PopularCompanies from "./components/homepage/PopularCompanies";
import BlogPost from "./components/homepage/BlogPost";
import Backend from "../utils/Backend";
import Card from "./components/Card";
import BlogPostModal from "./components/modal/BlogPostModal";

export default class Homepage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      blogPosts: [],
      popularCompanies: [],
    }
  }

  componentDidMount() {
    this._loadBlog()
    this._loadCompanies()
  }

  _loadBlog(){
    Backend.getBlogPosts().then(response => {
      this.setState({
        blogPosts: response.results
      })
    })
  }

  _loadCompanies(){
    Backend.getPopularCompanies().then(response => {
      this.setState({
        popularCompanies: response.results
      })
    })
  }

  render() {
    let {
      blogPosts,
      popularCompanies,
      showBlogPostModal
    } = this.state

    return (
      <>
        <div className="row">
          <div className="col-lg-12 p-0">
            <Card
              title={"Popular Companies"}
            >
              <div
                className="row"
                style={{
                  height: 660,
                  overflowY: "scroll"
                }}
              >
                <PopularCompanies/>
              </div>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 p-0">
            <Card
              title={"Blog Posts"}
              renderToolbar={() => {
                return (
                  <div className="mt-auto mb-auto">
                    <a
                      className="btn btn-primary ml-3 pull-right"
                      href="javascript:;"
                      onClick={() => this.setState({ showBlogPostModal: true })}
                    >
                      + Add Blog Posts
                    </a>
                  </div>
                )
              }}
            >
              <div className="col-lg-12 row">
                {
                  blogPosts.map(blogPost => (
                    <BlogPost
                      blogPost={blogPost}
                      onUpdate={() => this._loadBlog()}
                    />
                  ))
                }
              </div>
            </Card>
          </div>
        </div>
        {
          showBlogPostModal &&
          <BlogPostModal
            show={showBlogPostModal}
            onUpdate={() => {
              this.setState({showBlogPostModal: false})
              this._loadBlog()
            }}
            onCancel={() => this.setState({showBlogPostModal: false})}
          />
        }
      </>
    )
  }
}
