import React from 'react'
 import { Modal, Tabs, Tab } from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Switch from "react-switch";

import Notify from "../../../utils/Notify";
import Backend from "../../../utils/Backend"
import SmartList from "../common/SmartList";
import Edit from "../package/Edit";
import Pages from "../package/Pages";
import PopUp from "../package/PopUp";
import General from "../../../utils/General";
// import Members from "../packageObject/Members";
// import Subscription from "../packageObject/Subscription";
// import FeedControl from "../packageObject/FeedControl";
// import Card from "../packageObject/Card";

export default class PackageModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {
    this._load()
  }

  componentWillReceiveProps(nextProps){
    // this.setState(this._getState(nextProps), () => this._load())
  }

  _getState(props){
    return {
      ...props,
      activeTab: "edit",
      show: props.show,
      packageObject: props.packageObject,
      packageObjectLoaded: props.packageObject ? null : {type: "once-off", vpo: {ats: true, avi: true, company_app: true, media_posts: true, team_collaboration: true}, onboarding_info:{}, package_page_details: [{package_info: []},{},{}]}
    }
  }

  _load(){
    if(this.state.packageObject){
      Backend.getPackage(this.state.packageObject).then(packageObjectLoaded => {
        this.setState({packageObjectLoaded})
      }).catch(e => {
        Notify.error(e.message)
      })
    }
  }

  _isValid(packageObjectLoaded){
    let error = null;

    if(!packageObjectLoaded.title){
      error = 'Please include the package title'
    }else if(!packageObjectLoaded.description){
      error = 'Please include the package description'
    }else if(!packageObjectLoaded.price){
      error = 'Please include the package price'
    }else if(!packageObjectLoaded.vat){
      error = 'Please include the package vat'
    }else if(!packageObjectLoaded.currency){
      error = 'Please include the package currency'
    }else if(!packageObjectLoaded.validity){
      error = 'Please include the package validity'
    }else if(!packageObjectLoaded.jobbions){
      error = 'Please include the package job credits'
    }else if(!packageObjectLoaded.credits){
      error = 'Please include the package talent credits'
    }else if(!packageObjectLoaded.form_title){
      error = 'Please include the package form title'
    }else if(!packageObjectLoaded.hero_title){
      error = 'Please include the package hero title'
    }else if(!packageObjectLoaded.subtitle){
      error = 'Please include the package subtitle'
    }else if(!packageObjectLoaded.cta){
      error = 'Please include the package cta'
    }else if(!packageObjectLoaded.image){
      error = 'Please include the package image'
    }else if(!packageObjectLoaded.package_page_details[0].subtitle){
      error = 'Please include the package page 1 form subtitle'
    }else if(!packageObjectLoaded.package_page_details[0].secondary_title){
      error = 'Please include the package page 1 left box card title'
    }else if(!packageObjectLoaded.package_page_details[0].secondary_subtitle){
      error = 'Please include the package page 1 left box card subtitle'
    }else if(!packageObjectLoaded.package_page_details[1].title){
      error = 'Please include the package page 2 title'
    }else if(!packageObjectLoaded.package_page_details[1].subtitle){
      error = 'Please include the package page 2 subtitle'
    }else if(!packageObjectLoaded.package_page_details[1].secondary_title){
      error = 'Please include the package page 2 testimonial name and title'
    }else if(!packageObjectLoaded.package_page_details[1].secondary_subtitle){
      error = 'Please include the package page 2 testimonial company'
    }else if(!packageObjectLoaded.package_page_details[2].title){
      error = 'Please include the package page 3 title'
    }else if(!packageObjectLoaded.package_page_details[2].subtitle){
      error = 'Please include the package page 3 subtitle'
    }else if(!packageObjectLoaded.package_page_details[2].secondary_title){
      error = 'Please include the package page 3 left title'
    }else if(!packageObjectLoaded.package_page_details[2].secondary_subtitle){
      error = 'Please include the package page 3 left subtitle'
    }

    if(error){
      Notify.error(error)
    }

    return !error;
  }

  _add(){
    let {
      packageObjectLoaded
    } = this.state

    if(!this._isValid(packageObjectLoaded)){
      return
    }

    Backend.addPackage(packageObjectLoaded).then(packageObject => {
      Notify.success("Package Created")
      this.props.onAdded()
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  render() {
    let {
      activeTab,
      loading,
      show,
      packageObjectLoaded,
    } = this.state

    if(!packageObjectLoaded || !show) return null

    let title = !packageObjectLoaded.id ? 'Add Package' : `Update ${packageObjectLoaded.title}`

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
        className={this.props.modalClass}
      >
        <Modal.Header closeButton>
          <h5 className={"modal-title"}>{title}</h5>
        </Modal.Header>

        <Modal.Body>

          <Tabs
            activeKey={activeTab}
            onSelect={(tab) => this.setState({activeTab: tab})}
          >
            <Tab eventKey={"edit"} title={"Edit"}>
              <Edit
                packageObject={packageObjectLoaded}
                onUpdate={packageObjectLoaded => this.setState({packageObjectLoaded})}
              />
            </Tab>
            <Tab eventKey={"pages"} title={"On-boarding Pages"}>
              <Pages
                packageObject={packageObjectLoaded}
                onUpdate={packageObjectLoaded => this.setState({packageObjectLoaded})}
              />
            </Tab>
            <Tab eventKey={"pop-up"} title={"Pop Up"}>
              <PopUp
                packageObject={packageObjectLoaded}
                onUpdate={packageObjectLoaded => this.setState({packageObjectLoaded})}
              />
            </Tab>
          </Tabs>

        </Modal.Body>

        <Modal.Footer>
          <div className="col-md-4 text-center">
            <button
              id="reject" type="button" className="btn btn-secondary" style={{width: '100%'}}
              disabled={loading}
              onClick={() => this.props.onCancel()}
            >
              Close
            </button>
          </div>
          {
            !packageObjectLoaded.id &&
            <div className="col-md-4 text-center">
              <button
                id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
                onClick={() => this._add()}
              >
                Add
              </button>
            </div>
          }
        </Modal.Footer>
      </Modal>
    )
  }
}

PackageModal.defaultProps = {
  loading: false,
  modalClass: 'process-company',
  buttonClassName: 'primary',
}
