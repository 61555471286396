import React from 'react'
import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";
import ImageEdit from "../common/ImageEdit";
import TextEditor from "../common/TextEditor";
import CountrySelect from "../common/CountrySelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import AsyncSelect from "../common/AsyncSelect";

export default class Edit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {
    // this._load()
  }

  componentWillReceiveProps(nextProps) {
    // this.setState(this._getState(nextProps), () => this._load())
  }

  _getState(props) {
    return {
      ...props,
      article: props.article,
    }
  }

  _load(){
    Backend.getJob(this.state.job).then(job => {
      this.setState({job})
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _uploadImageFile(file, attribute){
    let {
      article
    } = this.state

    this.setState({ loading: true })
    Backend.uploadImage(file, "article")
      .then(image => {
        article[attribute] = image.image_url
        this.setState({ loading: false, article })
      })
      .catch(error => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _handleClick(){
    let {
      article
    } = this.state

    if(article.id){
      this._update(article)
    }else{
      this._create(article)
    }
  }

  _create(article){
    Backend.createArticle(article).then(e => {
      Notify.success("Article Create")
      this.props.onUpdate()
    }).catch(e => Notify.error(e.message))
  }

  _update(article){
    Backend.updateArticle(article).then(e => {
      Notify.success("Article Updated")
      this.props.onUpdate()
    }).catch(e => Notify.error(e.message))
  }

  render() {
    let {
      article
    } = this.state

    let categories = General.getAsyncOptions(article.categories)
    let channels = General.getAsyncOptions(article.channels)

    let buttonTitle = article.id ? `Update` : 'Create'

    return (
      <>
        <div className="form-group">
          <label>Title <small>Character Count ({article.title?.length})</small></label>
          <input
            className="form-control"
            type="text"
            value={article.title}
            onChange={e => {
              article.title = e.target.value
              this.setState({article})
            }}
          />
        </div>
        <div className="form-group">
          <label>Description <small>Character Count ({article.description?.length})</small></label>
          <textarea
            value={ article.description }
            className="form-control"
            placeholder="Article body goes here..."
            rows={3}
            onChange={e => {
              article.description = e.target.value
              this.setState({ article})
            }}
          />
        </div>
        <div className="form-group">
          <label>Text</label>
          <TextEditor
            text={ article.text }
            className="form-control"
            placeholder="Article body goes here..."
            toolbarOptions={{
              options: ['inline', 'link', 'list', 'image'],
              inline: {
                visible: true,
                className: "",
                options: ['bold', 'italic', 'underline'],
                bold: { className: "c-option toolbar-text-bold" },
                italic: { className: "c-option toolbar-text-italic" },
                underline: { className: "c-option toolbar-text-underline" },
                image: { className: "c-option toolbar-text-image" },
              },
              link: {
                visible: true,
                className: "",
                options: ['link', 'unlink'],
                link: { className: "c-option toolbar-text-link" },
                unlink: { className: "c-option toolbar-text-unlink" },
              },
              list: {
                options: ['unordered', 'ordered'],
                unordered: { className: "c-option" },
                ordered: { className: "c-option" },
              },
              image: {
                className: "c-option",
                urlEnabled: true,
                uploadEnabled: true,
                alignmentEnabled: true,
                uploadCallback: undefined,
                previewImage: false,
                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                alt: { present: false, mandatory: false },
                defaultSize: {
                  height: 'auto',
                  width: 'auto',
                },
              },
            }}
            onUpdated={text => {
              article.text = text
              this.setState({ article})
            }}
          />
        </div>
        <div className="form-group">
          <label>Card</label>
          <ImageEdit
            image={article.image}
            type={"banner"}
            hidePreview={false}
            deletable={true}
            uploadImageFile={file => this._uploadImageFile(file, "image")}
            onDeleteClicked={() => {
              article.image = null
              this.setState({article})
            }}
          />
        </div>
        <div className="form-group">
          <label>Author</label>
          <input
            type={"text"}
            className="form-control"
            value={article.posted_by}
            onChange={e => {
              article.posted_by = e.target.value
              this.setState({article})
            }}
          />
        </div>
        <div className="form-group">
          <label>Post Date</label>
          <DatePicker
            selected={Date.parse(article.posted_at) || ''}
            dateFormat={'yyyy-MM-dd'}
            className="form-control"
            placeholderText={"Post Date"}
            onChange={date => {
              article.posted_at = moment(date).format('YYYY-MM-DD')
              this.setState({article})
            }}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </div>
        <div className="form-group">
          <label>Categories</label>
          <AsyncSelect
            endpoint={window.Api.Categories}
            value={categories}
            placholder={"Categories"}
            isClearable={true}
            isMulti={true}
            onSelected={categories => {
              let selectedCategories = []
              if(categories){
                selectedCategories = categories.map(category => category.data)
              }
              article.categories = selectedCategories
              this.setState({ article })
            }}
            getOptions={categories => {
              return categories.map(category => ({
                  value: category.id,
                  label: `${category.name}`,
                  data: category
                })
              )
            }}
          />
        </div>
        <div className="form-group">
          <label>Channels</label>
          <AsyncSelect
            endpoint={window.Api.Channels}
            filter={'type=media'}
            value={channels}
            placholder={"Channels"}
            isClearable={true}
            isMulti={true}
            onSelected={channels => {
              let selectedChannels = []
              if(channels){
                selectedChannels = channels.map(channel => channel.data)
              }
              article.channels = selectedChannels
              this.setState({ article })
            }}
            getOptions={channels => {
              return channels.map(channel => ({
                  value: channel.id,
                  label: `${channel.name}`,
                  data: {id: channel.id, name: channel.name}
                })
              )
            }}
          />
        </div>
        <div className="form-group float-right">
          <div className="text-center">
            <button
              id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
              onClick={() => this._handleClick()}
            >
              {buttonTitle}
            </button>
          </div>
        </div>
      </>
    )
  }
}
