import React from 'react'
import { Modal, Tabs, Tab } from 'react-bootstrap';

import Edit from "../channel/Edit";
import Members from "../channel/Members";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

export default class ChannelModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      activeTab: "edit",
      show: props.show,
      channel: props.channel,
      rss: {"channel_id": props.channel.id, "job_ids": ""}
    }
  }

  _update(){
    let {
      rss
    } = this.state

    rss.job_ids = rss.job_ids.split(',')

    this.setState({loading: true})
    Backend.generateRSS(rss).then(() => {
      this.setState({loading: false})
      Notify.success("RSS Generated")
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  render() {
    let {
      activeTab,
      loading,
      show,
      channel,
      rss
    } = this.state

    if(!channel || !show) return null

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
        className={this.props.modalClass}
      >
        <Modal.Header closeButton>
          <h5 className={"modal-title"}>Update {channel.name}</h5>
        </Modal.Header>

        <Modal.Body>

          <Tabs
            activeKey={activeTab}
            onSelect={(tab) => this.setState({activeTab: tab})}
          >
            <Tab eventKey={"edit"} title={"Edit"}>
              <Edit
                channel={channel}
              />
            </Tab>
            <Tab eventKey={"members"} title={"Members"}>
              <Members
                channel={channel}
              />
            </Tab>
            <Tab eventKey={"rss-feed"} title={"RSS Generator"}>
              <div className="form-group">
                <label>Job IDs</label>
                <input
                  className="form-control"
                  type="text"
                  value={rss.job_ids}
                  onChange={e => {
                    rss.job_ids = e.target.value
                    this.setState({rss})
                  }}
                />
              </div>
              <div className="form-group float-right">
                <div className="text-center">
                  <button
                    id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
                    onClick={() => this._update()}
                    disabled={loading}
                  >
                    Update
                  </button>
                </div>
              </div>
            </Tab>
          </Tabs>

        </Modal.Body>

        <Modal.Footer>
          <div className="col-md-4 text-center">
            <button
              id="reject" type="button" className="btn btn-secondary" style={{width: '100%'}}
              disabled={loading}
              onClick={() => this.props.onCancel()}
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

ChannelModal.defaultProps = {
  loading: false,
  modalClass: 'process-company',
  buttonClassName: 'primary',
}
