import React from 'react'
 import { Modal, Tabs, Tab } from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Switch from "react-switch";

import Notify from "../../../utils/Notify";
import Backend from "../../../utils/Backend"
import SmartList from "../common/SmartList";
import Edit from "../article/Edit";
import Members from "../company/Members";
import Subscription from "../company/Subscription";
import JobsTable from "../tables/JobsTable";
import ExternalDistributionTable from "../tables/ExternalDistributionTable";
import FeedControl from "../job/FeedControl";

export default class ArticleModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      activeTab: "edit",
      show: props.show,
      article: props.article || {channels: [], categories: []},
    }
  }

  render() {
    let {
      activeTab,
      loading,
      show,
      article,
    } = this.state

    if(!article || !show) return null

    let title = article.id ? `Update ${article.title}` : 'Create Article'

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
        className={this.props.modalClass}
      >
        <Modal.Header closeButton>
          <h5 className={"modal-title"}>{title}</h5>
        </Modal.Header>

        <Modal.Body>

          <Tabs
            activeKey={activeTab}
            onSelect={(tab) => this.setState({activeTab: tab})}
          >
            <Tab eventKey={"edit"} title={"Edit"}>
              <Edit
                article={article}
                onUpdate={() => this.props.onCancel()}
              />
            </Tab>
          </Tabs>

        </Modal.Body>

        <Modal.Footer>
          <div className="col-md-4 text-center">
            <button
              id="reject" type="button" className="btn btn-secondary" style={{width: '100%'}}
              disabled={loading}
              onClick={() => this.props.onCancel()}
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

ArticleModal.defaultProps = {
  loading: false,
  modalClass: 'process-company',
  buttonClassName: 'primary',
}
