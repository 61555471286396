import React from 'react'

import AuthManager from '../utils/AuthManager'
import Email from '../utils/Email'

import Notify from "../utils/Notify"

const LOGO = 'https://d27hyvh7xqsx55.cloudfront.net/images/site/logo_dark.png'

const MODE_SIGNUP = "signup"
const MODE_FORGOT_PASSWORD = "forgot_password"
const MODE_RESET_PASSWORD = "reset_password"

export default class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      isLoading: false,
      mode: MODE_SIGNUP,
    }
  }

  _isFormValid() {
    let { email, password, confirmPassword, mode, code } = this.state

    if (!Email.isValid(email)) {
      this.setState({ error: 'Please enter a valid email address' })
      return false
    }

    if(mode == MODE_RESET_PASSWORD){
      if(!code){
        this.setState({ error: 'Please enter a valid code' })
        return false
      }
      if(password.length < 6){
        this.setState({ error: 'Password must be at least 6 characters' })
        return false
      }
      if(password != confirmPassword){
        this.setState({ error: 'Passwords do not match' })
        return false
      }
    }

    return true
  }

  _handleLogInClicked(e) {
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password } = this.state

    this.setState({ isLoading: true })
    AuthManager.login(email, password)
    .then(() => {
      let url = "/companies"
      window.location = url
    })
    .catch(error => {
      this.setState({
        isLoading: false,
        error: error.message
      })
    })
  }

  _handleRequestResetPassword(e){
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password, confirmPassword } = this.state

    this.setState({ isLoading: true })
    AuthManager.requestResetPassword(email)
    .then(() => {
      this.setState({
        isLoading: false,
        mode: MODE_RESET_PASSWORD,
        password: "",
        confirmPassword: "",
      })
    })
    .catch(error => {
      this.setState({
        isLoading: false,
        error: error.message
      })
    })
  }

  _handleResetPassword(e){
    e.preventDefault()

    if (!this._isFormValid()) {
      return
    }

    let { email, password, code } = this.state

    this.setState({ isLoading: true })
    AuthManager.resetPassword(email, password, code)
    .then(() => {
      this.setState({
        isLoading: false,
        mode: MODE_SIGNUP,
        email: "",
        password: "",
        confirmPassword: "",
      })
      Notify.success("Password reset successfully!")
    })
    .catch(error => {
      this.setState({
        isLoading: false,
        error: error.message
      })
    })

  }

  _renderError() {
    let { error } = this.state

    if (!error) {
      return null
    }

    return (
      <div
        className="kt-alert kt-alert--outline alert alert-danger alert-dismissible"
        role="alert"
      >
        <span>{error}</span>
      </div>
    )
  }

  render() {
    let {
      email,
      password,
      confirmPassword,
      isLoading,
      mode,
      code,
    } = this.state

    return (
  		<div className="kt-grid kt-grid--ver kt-grid--root">
  			<div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v6 kt-login--signin">
  				<div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
  					<div className="kt-grid__item  kt-grid__item--order-tablet-and-mobile-2  kt-grid kt-grid--hor kt-login__aside">
  						<div className="kt-login__wrapper">
  							<div className="kt-login__container">
  								<div className="kt-login__body">

  									<div
                      className="kt-login__signin"
                      style={{
                        display: mode === MODE_SIGNUP ? "block" : "none"
                      }}
                    >
  										<div className="kt-login__head">
  											<h3 className="kt-login__title">Sign In To Jobbio Console</h3>
  										</div>

  										<div className="kt-login__form">

                        { this._renderError() }

  											<form className="kt-form">
  												<div className="form-group">
  													<input
                              className="form-control"
                              type="text"
                              placeholder="Email"
                              name="email"
                              autoComplete="off"
                              value={email}
                              onChange={e => this.setState({ email: e.target.value, error: null })}
                            />
  												</div>
  												<div className="form-group">
  													<input
                              className="form-control form-control-last"
                              type="password"
                              placeholder="Password"
                              name="password"
                              value={password}
                              onChange={e => this.setState({ password: e.target.value, error: null })}
                            />
  												</div>
  												<div className="kt-login__extra">
  													<label/>
  													<a
                              onClick={() => this.setState({ email: "", mode: MODE_FORGOT_PASSWORD, error: null})}
                            >Forgot Password?</a>
  												</div>
  												<div className="kt-login__actions">
  													<button
                              className="btn btn-brand btn-pill btn-elevate"
                              onClick={e => this._handleLogInClicked(e)}
                              disabled={isLoading}
                            >
                              { isLoading  ? "Loading... " : "Sign In" }
                            </button>
  												</div>
  											</form>
  										</div>
  									</div>

  									<div
                      className="kt-login__forgot"
                      style={{
                        display: mode === MODE_FORGOT_PASSWORD ? "block" : "none"
                      }}
                    >
  										<div className="kt-login__head">
  											<h3 className="kt-login__title">Forgotten Password ?</h3>
  											<div className="kt-login__desc">Enter your email to reset your password:</div>
  										</div>
  										<div className="kt-login__form">

                        { this._renderError() }

  											<form className="kt-form">
  												<div className="form-group">
  													<input
                              className="form-control"
                              type="text"
                              placeholder="Email"
                              name="email"
                              autoComplete="off"
                              value={email}
                              onChange={e => this.setState({ email: e.target.value, error: null })}
                            />
  												</div>
  												<div className="kt-login__actions">
  													<button
                              className="btn btn-outline-brand btn-pill"
                              onClick={e => {
                                e.preventDefault()
                                this.setState({ email: "", password: "", mode: MODE_SIGNUP, error: null })
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn btn-brand btn-pill btn-elevate"
                              onClick={e => this._handleRequestResetPassword(e)}
                              disabled={isLoading}
                            >
                              { isLoading  ? "Loading... " : "Request" }
                            </button>
  												</div>
  											</form>
  										</div>
  									</div>


                    <div
                      className="kt-login__reset"
                      style={{
                        display: mode === MODE_RESET_PASSWORD ? "block" : "none"
                      }}
                    >
  										<div className="kt-login__head">
  											<h3 className="kt-login__title">Reset Password</h3>
  											<div className="kt-login__desc">A verification code was sent to your email, please enter it below along with your new password</div>
  										</div>
  										<div className="kt-login__form">

                        { this._renderError() }

  											<form className="kt-form">
  												<div className="form-group">
  													<input
                              className="form-control"
                              type="text"
                              placeholder="Code"
                              name="code"
                              autoComplete="off"
                              value={code}
                              onChange={e => this.setState({ code: e.target.value, error: null })}
                            />
  												</div>
                          <div className="form-group">
  													<input
                              className="form-control"
                              type="password"
                              placeholder="Password"
                              name="password"
                              autoComplete="off"
                              value={password}
                              onChange={e => this.setState({ password: e.target.value, error: null })}
                            />
  												</div>
                          <div className="form-group">
  													<input
                              className="form-control"
                              type="password"
                              placeholder="Confirm Password"
                              name="confirmPassword"
                              autoComplete="off"
                              value={confirmPassword}
                              onChange={e => this.setState({ confirmPassword: e.target.value, error: null })}
                            />
  												</div>
  												<div className="kt-login__actions">
  													<button
                              className="btn btn-outline-brand btn-pill"
                              onClick={e => {
                                e.preventDefault()
                                this.setState({ email: "", password: "", mode: MODE_SIGNUP, error: null })
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn btn-brand btn-pill btn-elevate"
                              onClick={e => this._handleResetPassword(e)}
                              disabled={isLoading}
                            >
                              { isLoading  ? "Loading... " : "Update Password" }
                            </button>
  												</div>
  											</form>
  										</div>
  									</div>

  								</div>
  							</div>
  						</div>
  					</div>

            <div className="kt-grid__item kt-grid__item--fluid kt-grid__item--center kt-grid kt-grid--ver kt-login__content">
              <div className="kt-login__section">
                <div className="kt-grid__item kt-grid__item--middle text-center" style={{margin: '0 auto'}}>
                  <img src="https://d27hyvh7xqsx55.cloudfront.net/images/site/logo_dark.png" alt="Jobbio Logo" height="100px" />
                </div>
              </div>
            </div>

  				</div>
  			</div>
  		</div>
    )
  }
}
