import React from "react"

import Card from '../Card'

import ReactTable from "react-table"
import "react-table/react-table.css"
import FetchHelper from '../../../utils/FetchHelper'
import General from '../../../utils/General'
import Notify from "../../../utils/Notify"
import moment from "moment";
import DatePicker from "react-datepicker";


export default class BaseTable extends React.Component {

  constructor(props){
    super(props)

    let filters = this.props.filters || []

    this.state = {
      filters,
      loading: true,
      data: [],
      pagesNo: 0,
      searchTerm: "",
      csvData: [],
      filterValue: this.props.filterValue,
      searchFilter: this.props.searchFilter,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    }

    this.reactTable = React.createRef();
    this.csvLink = React.createRef();
  }

  refresh(){
    let current = this.reactTable.current
    if(current){
      this._fetchData(current.state, current)
    }
  }

  _handleSearch = General.debounce(() => {
    let current = this.reactTable.current
    this.refresh()
  }, 500)

  _handleFilterChange(event){
    let { filterValue } = this.state

    filterValue[event.target.name] = event.target.value
    this.setState({filterValue}, () => this._handleSearch())
  }

  _handleDateChange(){
    let { filterValue, fromDate, toDate } = this.state

    filterValue['date_from'] = fromDate
    filterValue['date_to'] = toDate
    this.setState({filterValue}, () => this._handleSearch())
  }


  _getUrl(endpoint, state){

    let params = {
      ...this.props.params,
      page_size: state.pageSize,
      page: state.page + 1,
      pagination_type: "page",
      search: this.state.searchTerm
    }

    let sorted = state.sorted[0]
    if(sorted){
      let orderBy = sorted.id
      orderBy = orderBy.replace(/\./g, "__")
      if(sorted.desc){
        orderBy = `-${orderBy}`
      }
      params["order_by"] = orderBy
    }

    if(this.props.dateFilter){
      params['date_from'] = this.state.fromDate
      params['date_to'] = this.state.toDate
    }

    if(this.state.filterValue){
      Object.entries(this.state.filterValue).map(filter => {
        params[filter[0]] = filter[1]
      })
    }

    return this._addParams(endpoint, params)
  }

  _fetchData(state, instance) {
    this.setState({ loading: true });
    let url = this._getUrl(this.props.endpoint, state)

    if(this.props.endpoint === ""){
      this.setState({
        data: this.props.mockData,
        pagesNo: 1,
        loading: false
      });
      return
    }
    FetchHelper.get(url, false)
    .then(response => {
      this.setState({
        data: response.results,
        pagesNo: Math.ceil(response.count / state.pageSize),
        totalRecords: response.count,
        loading: false
      });
    })
    .catch(error => {
      console.log("Error", error)
    })
  }

  _addParams(url, params){
    if(Object.keys(params).length == 0){
      return url
    }

    // TODO: switch to an actual url helper here to avoid bugs/edge cases
    if(url.indexOf("?") == -1){
      url += "?"
    }
    else if(!url.endsWith("&")){
      url += "&"
    }

    Object.keys(params).forEach(function(key) {
        url += key+"="+params[key]+"&";
    });

    // remove last '&'
    url = url.slice(0, -1);
    return url
  }

  _exportPressed(){
    if(this.state.loading){
      return
    }

    if(this.state.fromDate === "Invalid date" || this.state.toDate === "Invalid date"){
      Notify.error("Please include a start and end date.")
    }

    let params = {}

    if(this.props.dateFilter){
      params['date_from'] = this.state.fromDate
      params['date_to'] = this.state.toDate
    }

    if(this.state.filterValue){
      Object.entries(this.state.filterValue).map(filter => {
        params[filter[0]] = filter[1]
      })
    }

    let exportEndpoint = this._addParams(this.props.exportEndpoint, params)

    this.setState({ loading: true })
    FetchHelper.get(exportEndpoint, true)
    .then(() => {
      Notify.success("Export requested, please check your email in a little while.")
      this.setState({ loading: false })
    })
    .catch(error => {
      this.setState({ loading: false })
      Notify.error(error.message)
    })
  }

  _renderDateFilters(){
    let {
      toDate,
      fromDate
    } = this.state

    return (
      <>
        <div className={"col"}>
          <div className="form-group">
            <label>From Date</label>
            <DatePicker
              selected={Date.parse(fromDate) || ''}
              dateFormat={'yyyy-MM-dd'}
              className="form-control"
              placeholderText={"Post Date"}
              onChange={date => {
                fromDate = moment(date).format('YYYY-MM-DD')
                this.setState({fromDate}, () => this._handleDateChange())
              }}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
        </div>
        <div className={"col"}>
          <div className="form-group">
            <label>To Date</label>
            <DatePicker
              selected={Date.parse(toDate) || ''}
              dateFormat={'yyyy-MM-dd'}
              className="form-control"
              placeholderText={"Post Date"}
              onChange={date => {
                toDate = moment(date).format('YYYY-MM-DD')
                this.setState({toDate}, () => this._handleDateChange())
              }}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
        </div>
      </>
    )
  }

  _renderHeaderLeftContent(){
    let {
      filters
    } = this.state

    return(
      <>
        <div class="col">
          <div class="search kt-input-icon kt-input-icon--left">
            <input
              type="text"
              class="form-control"
              placeholder={this.props.searchPlaceholder}
              onChange={e => {
                this.setState({
                  searchTerm: e.target.value
                }, () => this._handleSearch())
              }}
            />
            <span class="kt-input-icon__icon kt-input-icon__icon--left">
              <span><i class="la la-search"></i></span>
            </span>
          </div>
        </div>
        {
          filters &&
          filters.map(filter => {
            return (
              <div className="col my-2 my-md-0 ml-auto" style={{maxWidth: 300}}>
                <div className="d-flex align-items-center">
                  <div className="select-wrapper w-100">
                    <select
                      className="form-control"
                      name={filter.name.api}
                      onChange={e => {
                        this._handleFilterChange(e)
                      }}
                      defaultValue={this.props.searchFilter}
                    >
                      {
                        filter.values.map(filterOptions => {
                          return (
                            <option value={filterOptions.value || ""}>
                              {filterOptions.label}
                            </option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
              </div>
            )
          })
        }
      </>
    )
  }

  _renderExportButtons(){
    if(!this.props.exportButtonsEnabled){
      return null
    }

    return (
      <>
        <label style={{ opacity: 0 }}>Export</label>
        <div class="row">
          <div className="col-md-12 text-right">
            <a
              className="btn btn-secondary"
              onClick={() => this._exportPressed()}
            >
              Export
            </a>
          </div>
        </div>
      </>
    )
  }

  render() {
    const { data, pagesNo, loading, totalRecords } = this.state;
    return (
      <Card
        title={this.props.title}
        icon={this.props.icon}
        renderToolbar={this.props.renderToolbar}
        className={this.props.className}
        loading={loading}
      >
        <div>
          <div className="row">
            <div className="col-md-6">
              <div className="dataTables_filter">
                <div className="row">
                  {
                    this.props.dateFilter &&
                    this._renderDateFilters()
                  }
                </div>
              </div>
            </div>
            <div className="col-md-6 pull-right">
              { this._renderExportButtons() }
              { this.props.renderHeaderRightContent() }
            </div>
          </div>

          <div className="row pb-4">
            <div className="col-md-12">
              <div className="dataTables_filter">
                <div className="row">
                  {this._renderHeaderLeftContent()}
                </div>
              </div>
            </div>
          </div>
          <ReactTable
            ref={this.reactTable}
            manual
            data={data}
            pages={pagesNo}
            totalRecords={totalRecords}
            loading={loading}
            LoadingComponent={Loading}
            onFetchData={this._fetchData.bind(this)}
            columns={this.props.columns}
            getTdProps={this.props.getTdProps}
            getTrProps={this.props.getTrProps}
            getTheadProps={this.props.getTheadProps}
            getTheadThProps={this.props.getTheadThProps}
            showPagination={this.props.showPagination}
            showPaginationTop={this.props.showPaginationTop}
            showPaginationBottom={this.props.showPaginationBottom}
            defaultSorted={this.props.defaultSorted}
            SubComponent={this.props.SubComponent}
            NoDataComponent={() => {
              return (
                <div className="rt-noData">
                  { this.props.noDataMessage}
                </div>
              )
            }}
          />
        </div>
      </Card>
    )
  }
}

BaseTable.defaultProps = {
  params: {},
  label: "Search:",
  searchPlaceholder: "Search...",
  icon: null,
  filters: null,
  dateFilter: false,
  searchFilter: null,
  searchFilterName: "name",
  noDataMessage: 'No results found',
  showPagination: true,
  showPaginationTop: false,
  showPaginationBottom: true,
  exportButtonsEnabled: true,
  getTdProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        paddingLeft: 10
      }
    }
  },
  getTrProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        paddingTop: 10,
        paddingBottom: 10
      }
    }
  },
  getTheadProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        boxShadow: '0 1px 1px 0 rgba(0,0,0,0.1)',
        paddingTop: 10,
        paddingBottom: 10,
        textAlign: 'left'
      }
    }
  },
  getTheadThProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        textAlign: 'left',
        paddingLeft: 10
      }
    }
  },
  SubComponent: null,
  renderHeaderRightContent: () => null,
  renderToolbar: () => null
}

class Loading extends React.Component {
  render () {
    return (
      this.props.loading
        ? <div className='-loading -active'>
            <div className='-loading-inner'>
            </div>
          </div>
        : null
    )
  }
}
