import React from "react"

export default class Footer extends React.Component {
  render() {
    return (
      <div className="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
        <div class="kt-container  kt-container--fluid ">
          <div class="kt-footer__copyright">
            2021&nbsp;©&nbsp;Jobbio
          </div>
        </div>
			</div>
    )
  }
}
