import React from 'react'
import PackagesTable from "./components/tables/PackagesTable";

export default class Packages extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

    }
    this.documentsTable = React.createRef()
  }

  componentDidMount(){

  }

  render() {

    let {

    } = this.state

    return (
      <>
        <PackagesTable
          title={"Packages"}
        />
      </>
    )
  }
}
