import React from 'react'
import CompaniesTable from "./components/tables/CompaniesTable";

export default class Companies extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

    }
    this.documentsTable = React.createRef()
  }

  componentDidMount(){

  }

  render() {

    let {

    } = this.state

    return (
      <>
        <CompaniesTable
          title={"Companies"}
        />
      </>
    )
  }
}
