import React from 'react'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import ImageEdit from "../common/ImageEdit";
import Switch from "react-switch";
import SmartList from "../common/SmartList";

export default class Pages extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {
    // this._load()
  }

  componentWillReceiveProps(nextProps) {
    // this.setState(this._getState(nextProps), () => this._load())
  }

  _getState(props) {
    return {
      ...props,
      packageObject: props.packageObject,
    }
  }

  _uploadImageFile(file, index, attribute){
    let {
      packageObject
    } = this.state

    this.setState({ loading: true })
    Backend.uploadImage(file, "package")
      .then(image => {
        packageObject.package_page_details[index][attribute] = image.image_url
        this.setState({ loading: false, packageObject })
      })
      .catch(error => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _update(){
    let {
      packageObject
    } = this.state

    Backend.updatePackage(packageObject).then(e => {
      this.props.onUpdate(packageObject)
      Notify.success("Package Updated")
    }).catch(e => Notify.error(e.message))
  }

  render() {
    let {
      packageObject
    } = this.state

    return (
      <>
        <h3>Page 01</h3>
        <div className="form-group">
          <label>Form Subtitle</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.package_page_details[0]?.subtitle}
            onChange={e => {
              packageObject.package_page_details[0].subtitle = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Left Box Card Title</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.package_page_details[0]?.secondary_title}
            onChange={e => {
              packageObject.package_page_details[0].secondary_title = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Left Box Card Subtitle</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.package_page_details[0]?.secondary_subtitle}
            onChange={e => {
              packageObject.package_page_details[0].secondary_subtitle = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Left Box Card Text</label>
          <textarea
            className="form-control"
            value={packageObject.package_page_details[0]?.secondary_text}
            rows={3}
            onChange={e => {
              packageObject.package_page_details[0].secondary_text = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Info</label>
          <SmartList
            items={packageObject.package_page_details[0]?.package_info}
            value={(item, index) => (item.text)}
            disabled={() => (false)}
            onChange={(value, item, index) => {
              packageObject.package_page_details[0].package_info[index].text = value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
            onAddItemPressed={() => {
              packageObject.package_page_details[0].package_info.push({})
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
            onUpdated={items => {
              packageObject.package_page_details[0].package_info = items
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Background Image</label>
          <ImageEdit
            image={packageObject.package_page_details[0]?.background_image}
            type={"banner"}
            hidePreview={false}
            deletable={true}
            uploadImageFile={file => this._uploadImageFile(file, 0, "background_image")}
            onDeleteClicked={() => {
              packageObject.package_page_details[0].background_image = null
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Background Overlay</label>
          <input
            className="form-control"
            type="number"
            value={packageObject.package_page_details[0]?.background_overlay}
            onChange={e => {
              packageObject.package_page_details[0].background_overlay = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Counter</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              packageObject.package_page_details[0].counter = checked
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
            checked={packageObject.package_page_details[0]?.counter}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        <div className="form-group">
          <label>Logos</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              packageObject.package_page_details[0].logos = checked
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
            checked={packageObject.package_page_details[0]?.logos}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        <div className="form-group">
          <label>Footer</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              packageObject.package_page_details[0].footer = checked
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
            checked={packageObject.package_page_details[0]?.footer}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        <hr/>
        <h3>Page 02</h3>
        <div className="form-group">
          <label>Title</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.package_page_details[1]?.title}
            onChange={e => {
              packageObject.package_page_details[1].title = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Subtitle</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.package_page_details[1]?.subtitle}
            onChange={e => {
              packageObject.package_page_details[1].subtitle = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Testimonial Name & Title</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.package_page_details[1]?.secondary_title}
            onChange={e => {
              packageObject.package_page_details[1].secondary_title = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Testimonial Company</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.package_page_details[1]?.secondary_subtitle}
            onChange={e => {
              packageObject.package_page_details[1].secondary_subtitle = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Testimonial</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.package_page_details[1]?.secondary_text}
            onChange={e => {
              packageObject.package_page_details[1].secondary_text = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Testimonial Image</label>
          <ImageEdit
            image={packageObject.package_page_details[1]?.secondary_image}
            type={"banner"}
            hidePreview={false}
            deletable={true}
            uploadImageFile={file => this._uploadImageFile(file, 1, "secondary_image")}
            onDeleteClicked={() => {
              packageObject.package_page_details[1].secondary_image = null
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Logos</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              packageObject.package_page_details[1].logos = checked
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
            checked={packageObject.package_page_details[1]?.logos}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        <div className="form-group">
          <label>Footer</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              packageObject.package_page_details[1].footer = checked
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
            checked={packageObject.package_page_details[1]?.footer}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        <hr/>
        <h3>Page 03</h3>
        <div className="form-group">
          <label>Title</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.package_page_details[2]?.title}
            onChange={e => {
              packageObject.package_page_details[2].title = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Subtitle</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.package_page_details[2]?.subtitle}
            onChange={e => {
              packageObject.package_page_details[2].subtitle = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Left Title</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.package_page_details[2]?.secondary_title}
            onChange={e => {
              packageObject.package_page_details[2].secondary_title = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Left Subtitle</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.package_page_details[2]?.secondary_subtitle}
            onChange={e => {
              packageObject.package_page_details[2].secondary_subtitle = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Counter</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              packageObject.package_page_details[2].counter = checked
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
            checked={packageObject.package_page_details[2]?.counter}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        <div className="form-group">
          <label>Logos</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              packageObject.package_page_details[2].logos = checked
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
            checked={packageObject.package_page_details[2]?.logos}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        <div className="form-group">
          <label>Footer</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              packageObject.package_page_details[2].footer = checked
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
            checked={packageObject.package_page_details[2]?.footer}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        {
          packageObject.id &&
          <div className="form-group float-right">
            <div className="text-center">
              <button
                id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
                onClick={() => this._update()}
              >
                Update
              </button>
            </div>
          </div>
        }
      </>
    )
  }
}