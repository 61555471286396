import React from 'react'

import ImageEdit from "../common/ImageEdit";
import Card from "../Card";
import Notify from "../../../utils/Notify";
import Backend from "../../../utils/Backend";
import General from "../../../utils/General";

const ICONS = [
  'icon-User',
  'icon-Search-People',
  'icon-Search-onCloud',
  'icon-Data-Search',
  'icon-File-Search',
  'icon-Folder-Search',
  'icon-Mail-Search',
  'icon-Star',
  'icon-Hand-Touch',
  'icon-Life-Safer',
  'icon-Eye',
  'icon-Tag',
  'icon-Bookmark',
  'icon-Heart',
  'icon-Thumb',
  'icon-Upload-toCloud',
  'icon-Smartphone',
  'icon-Gear',
  'icon-Gears',
  'icon-Wrench',
  'icon-Volume-Up',
  'icon-Volume-Down',
  'icon-Direction-North',
  'icon-Direction-East',
  'icon-Direction-South',
  'icon-Direction-West'
]

export default class NavLink extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expand: props.expand || false,
      navLink: props.navLink,
      contentLinks: props.contentLinks
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({navLink: nextProps.navLink, contentLinks: nextProps.contentLinks})
  }

  _renderInput(title, attribute, type, size="col-md-6"){
    let {
      navLink
    } = this.state

    return (
      <div className={size}>
        <label>{title}</label>
        <input
          className="form-control"
          type={type}
          value={navLink[attribute]}
          onChange={e => {
            navLink[attribute] = e.target.value
            this.setState({navLink}, () => this.props.onUpdated(navLink))
          }}
        />
      </div>
    )
  }

  render() {
    let {
      expand,
      navLink,
      contentLinks
    } = this.state

    return (
      <>
        <Card
          title={navLink.title}
          visible={expand}
          renderToolbar={() => {
            return (
              <div className="mt-auto mb-auto">
                <a
                  className="btn btn-primary ml-3"
                  href="javascript:;"
                  onClick={() => this.setState({expand: !expand})}
                >
                  Edit
                </a>
                <a
                  className="btn btn-danger ml-3 pull-right"
                  href="javascript:;"
                  onClick={() => this.props.onDelete()}
                >
                  Delete
                </a>
              </div>
            )
          }}
        >
          <div className="form-group">
            <label style={{marginRight: 10}}>Icon</label>
            <small><a target="_blank" href="https://iconsmind.com/view_icons/">view icons</a></small>
            <select
              className="form-control"
              value={navLink.icon}
              onChange={e => {
                navLink.icon = e.target.value
                this.setState({navLink}, () => this.props.onUpdated(navLink))
              }}
            >
              <option value="">No Icon</option>
              {
                ICONS.map(icon => (
                  <option value={icon}>{icon}</option>
                ))
              }
            </select>
          </div>
          <div className="form-group row">
            {this._renderInput("Title", "title", "text", "col-md-12")}
          </div>
          <div className="form-group row">
            <div className={"col-md-12"}>
              <label>Link</label>
              <select
                className="form-control"
                value={navLink.link?.id ? navLink.link.id : navLink.link}
                onChange={e => {
                  navLink.link = e.target.value
                  this.setState({navLink}, () => this.props.onUpdated(navLink))
                }}
              >
                <option value="">Select Block</option>
                {
                  contentLinks.map(contentLink => (
                    <option value={contentLink.value}>{contentLink.name}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </Card>
      </>
    )
  }
}
