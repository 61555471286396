import React from 'react'

import ImageEdit from "../common/ImageEdit";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

const ICONS = [
  'icon-User',
  'icon-Search-People',
  'icon-Search-onCloud',
  'icon-Data-Search',
  'icon-File-Search',
  'icon-Folder-Search',
  'icon-Mail-Search',
  'icon-Star',
  'icon-Hand-Touch',
  'icon-Life-Safer',
  'icon-Eye',
  'icon-Tag',
  'icon-Bookmark',
  'icon-Heart',
  'icon-Thumb',
  'icon-Upload-toCloud',
  'icon-Smartphone',
  'icon-Gear',
  'icon-Gears',
  'icon-Wrench',
  'icon-Volume-Up',
  'icon-Volume-Down',
  'icon-Direction-North',
  'icon-Direction-East',
  'icon-Direction-South',
  'icon-Direction-West'
]

export default class BlockEntry extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      contentBlock: props.contentBlock,
      contentLinks: props.contentLinks,
      contentBlockEntry: props.contentBlockEntry,
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      contentBlock: nextProps.contentBlock,
      contentLinks: nextProps.contentLinks,
      contentBlockEntry: nextProps.contentBlockEntry
    })
  }

  _uploadImageFile(file, attribute){
    let {
      contentBlockEntry
    } = this.state

    this.setState({ loading: true })
    Backend.uploadImage(file, "company")
      .then(image => {
        contentBlockEntry[attribute] = image.image_url
        this.setState({ loading: false, contentBlockEntry })
      })
      .catch(error => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _renderInput(title, attribute, type, size="col-md-6"){
    let {
      contentBlockEntry
    } = this.state

    return (
      <div className={size}>
        <label>{title}</label>
        <input
          className="form-control"
          type={type}
          value={contentBlockEntry[attribute]}
          onChange={e => {
            contentBlockEntry[attribute] = e.target.value
            this.setState({contentBlockEntry}, () => this.props.onUpdated(contentBlockEntry))
          }}
        />
      </div>
    )
  }

  render() {
    let {
      contentBlock,
      contentLinks,
      contentBlockEntry,
      selectedPrimaryLinkType,
    } = this.state

    selectedPrimaryLinkType = contentBlockEntry.primary_cta_link?.includes("section-") ? 'block' : 'link'

    return (
      <>
        {
          contentBlock.block.id === 5 &&
          <>
            <div className="form-group">
              <label style={{marginRight: 10}}>Icon</label>
              <small><a target="_blank" href="https://iconsmind.com/view_icons/">view icons</a></small>
              <select
                className="form-control"
                value={contentBlockEntry.icon}
                onChange={e => {
                  contentBlockEntry.icon = e.target.value
                  this.setState({contentBlockEntry}, () => this.props.onUpdated(contentBlockEntry))
                }}
              >
                <option value="">No Icon</option>
                {
                  ICONS.map(icon => (
                    <option value={icon}>{icon}</option>
                  ))
                }
              </select>
            </div>
            <div className="form-group row">
              {this._renderInput("Title", "title", "text", "col-md-12")}
            </div>
            <div className="form-group row">
              <div className={"col-md-12"}>
                <label>Text</label>
                <textarea
                  className="form-control"
                  rows={3}
                  value={contentBlockEntry.text}
                  onChange={e => {
                    contentBlockEntry.text = e.target.value
                    this.setState({contentBlockEntry}, () => this.props.onUpdated(contentBlockEntry))
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              {this._renderInput("Primary CTA", "primary_cta_text", "text", "col-md-12")}
            </div>
            <div className="form-group row">
              <div className={"col-md-6"}>
                <label>Link Type</label>
                <select
                  className="form-control"
                  value={selectedPrimaryLinkType}
                  onChange={e => {
                    selectedPrimaryLinkType = e.target.value
                    if(selectedPrimaryLinkType === "link"){
                      contentBlockEntry.primary_cta_link = ""
                    }else{
                      contentBlockEntry.primary_cta_link = "section-"
                    }
                    this.setState({contentBlockEntry, selectedPrimaryLinkType}, () => this.props.onUpdated(contentBlockEntry))
                  }}
                >
                  <option value="link">Link</option>
                  <option value="block">Block</option>
                </select>
              </div>
              {
                selectedPrimaryLinkType === "link" ?
                  this._renderInput("Link", "primary_cta_link", "text") :
                  <div className={"col-md-6"}>
                    <label>Link</label>
                    <select
                      className="form-control"
                      value={contentBlockEntry.primary_cta_link}
                      onChange={e => {
                        contentBlockEntry.primary_cta_link = e.target.value
                        this.setState({contentBlockEntry}, () => this.props.onUpdated(contentBlockEntry))
                      }}
                    >
                      <option value="">Select Block</option>
                      {
                        contentLinks.map(contentLink => (
                          <option value={`section-${contentLink.value}`}>{contentLink.name}</option>
                        ))
                      }
                    </select>
                  </div>
              }
            </div>
            <div className="form-group row">
              {this._renderInput("Primary Colour", "primary_color", "color", "col-md-12")}
            </div>
          </>
        }
        {
          (contentBlock.block.id === 9 || contentBlock.block.id === 10) &&
          <>
            <div className="form-group row">
              {this._renderInput("Title", "title", "text", "col-md-12")}
            </div>
            <div className="form-group row">
              {this._renderInput("Subtitle", "subtitle", "text", "col-md-12")}
            </div>
            <div className="form-group">
              <label>Image</label>
              <ImageEdit
                image={contentBlockEntry.image}
                type={"banner"}
                hidePreview={false}
                deletable={true}
                uploadImageFile={file => this._uploadImageFile(file, "image")}
                onDeleteClicked={() => {
                  contentBlockEntry.image = null
                  this.setState({contentBlockEntry}, () => this.props.onUpdated(contentBlockEntry))
                }}
              />
            </div>
          </>
        }
        {
          contentBlock.block.id === 11 &&
          <>
            <div className="form-group row">
              {this._renderInput("Title", "title", "text", "col-md-12")}
            </div>
            <div className="form-group row">
              {this._renderInput("Subtitle", "subtitle", "text", "col-md-12")}
            </div>
            <div className="form-group row">
              <div className={"col-md-12"}>
                <label>Text</label>
                <textarea
                  className="form-control"
                  rows={3}
                  value={contentBlockEntry.text}
                  onChange={e => {
                    contentBlockEntry.text = e.target.value
                    this.setState({contentBlockEntry}, () => this.props.onUpdated(contentBlockEntry))
                  }}
                />
              </div>
            </div>
          </>
        }
        {
          (contentBlock.block.id === 13 || contentBlock.block.id === 14) &&
          <>
            <div className="form-group row">
              {this._renderInput("Title", "title", "text", "col-md-12")}
            </div>
            <div className="form-group row">
              <div className={"col-md-12"}>
                <label>Text</label>
                <textarea
                  className="form-control"
                  rows={3}
                  value={contentBlockEntry.text}
                  onChange={e => {
                    contentBlockEntry.text = e.target.value
                    this.setState({contentBlockEntry}, () => this.props.onUpdated(contentBlockEntry))
                  }}
                />
              </div>
            </div>
          </>
        }
        {
          (contentBlock.block.id === 15 || contentBlock.block.id === 16) &&
          <>
            <div className="form-group row">
              {this._renderInput("Title", "title", "text", "col-md-12")}
            </div>
            <div className="form-group row">
              <div className={"col-md-12"}>
                <label>Text</label>
                <textarea
                  className="form-control"
                  rows={3}
                  value={contentBlockEntry.text}
                  onChange={e => {
                    contentBlockEntry.text = e.target.value
                    this.setState({contentBlockEntry}, () => this.props.onUpdated(contentBlockEntry))
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              {this._renderInput("Video", "video", "text", "col-md-12")}
            </div>
            <div className="form-group">
              <label>Image</label>
              <ImageEdit
                image={contentBlockEntry.image}
                type={"banner"}
                hidePreview={false}
                deletable={true}
                uploadImageFile={file => this._uploadImageFile(file, "image")}
                onDeleteClicked={() => {
                  contentBlockEntry.image = null
                  this.setState({contentBlockEntry}, () => this.props.onUpdated(contentBlockEntry))
                }}
              />
            </div>
          </>
        }
        {
          (contentBlock.block.id === 18) &&
          <>
            <div className="form-group row">
              {this._renderInput("Title", "title", "text", "col-md-12")}
            </div>
            <div className="form-group row">
              <div className={"col-md-12"}>
                <label>Text</label>
                <textarea
                  className="form-control"
                  rows={3}
                  value={contentBlockEntry.text}
                  onChange={e => {
                    contentBlockEntry.text = e.target.value
                    this.setState({contentBlockEntry}, () => this.props.onUpdated(contentBlockEntry))
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Image</label>
              <ImageEdit
                image={contentBlockEntry.image}
                type={"banner"}
                hidePreview={false}
                deletable={true}
                uploadImageFile={file => this._uploadImageFile(file, "image")}
                onDeleteClicked={() => {
                  contentBlockEntry.image = null
                  this.setState({contentBlockEntry}, () => this.props.onUpdated(contentBlockEntry))
                }}
              />
            </div>
            <div  className="form-group row">
              {this._renderInput("CTA", "primary_cta_text", "text", "col-md-12")}
            </div>
            <div  className="form-group row">
              {this._renderInput("CTA Link", "primary_cta_link", "text", "col-md-12")}
            </div>
          </>
        }
      </>
    )
  }
}
