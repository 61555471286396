import React from 'react'

import Card from "./components/Card";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import ImageEdit from "./components/common/ImageEdit";
import Switch from "react-switch";
import SmartList from "./components/common/SmartList";
import TextEditor from "./components/common/TextEditor";
import CustomSelect from "./components/common/Select";
import BlockPickerModal from "./components/modal/BlockPickerModal";
import Block from "./components/company/Block";
import NavLink from "./components/company/NavLink";
import General from "../utils/General";
import $ from "jquery";

const ICONS = [
  'icon-User',
  'icon-Search-People',
  'icon-Search-onCloud',
  'icon-Data-Search',
  'icon-File-Search',
  'icon-Folder-Search',
  'icon-Mail-Search',
  'icon-Star',
  'icon-Hand-Touch',
  'icon-Life-Safer',
  'icon-Eye',
  'icon-Tag',
  'icon-Bookmark',
  'icon-Heart',
  'icon-Thumb',
  'icon-Upload-toCloud',
  'icon-Smartphone',
  'icon-Gear',
  'icon-Gears',
  'icon-Wrench',
  'icon-Volume-Up',
  'icon-Volume-Down',
  'icon-Direction-North',
  'icon-Direction-East',
  'icon-Direction-South',
  'icon-Direction-West'
]

export default class Company extends React.Component {
  constructor(props) {
    super(props)

    let companySlug = this.props.match.params.company_slug

    this.state = {
      companySlug,
      contentLinks: [],
      loadingBlocks: true,
      loadingNavLinks: true
    }
    this.documentsTable = React.createRef()
  }

  componentDidMount(){
    Backend.getCompanyBySlug(this.state.companySlug).then(company => {
      this.setState({company})
    }).catch(e => Notify.error(e.message))
    Backend.getBranding(this.state.companySlug).then(contentBlocks => {
      this.setState({contentBlocks, loadingBlocks: false}, () => this._processContentBlocks())
    }).catch(e => {
      Notify.error(e.message)
      this.setState({loadingBlocks: false})
    })
    Backend.getNavLinks(this.state.companySlug).then(navLinks => {
      this.setState({navLinks, loadingNavLinks: false})
    }).catch(e => {
      this.setState({loadingNavLinks: false})
      Notify.error(e.message)
    })
    Backend.getBlocks().then(blocks => {
      this.setState({blocks})
    }).catch(e => Notify.error(e.message))
  }

  _processContentBlocks(){
    let {
      contentBlocks
    } = this.state

    let contentLinks = contentBlocks.map(contentBlock => {
      return {
        name: contentBlock.block.name,
        value: contentBlock.id
      }
    })

    this.setState({contentLinks})
  }

  _isValid(navLinks){
    let error = null;

    navLinks.map((navLink, index) => {
      let contentNumber = General.toOrdinal(index+1)

      if(!navLink.title){
        error = `Please enter the title for the ${contentNumber} nav link`
      }else if(!navLink.link){
        error = `Please enter the link for the ${contentNumber} nav link`
      }else if(!navLink.icon){
        error = `Please enter the icon for the ${contentNumber} nav link`
      }
    })

    if(error){
      Notify.error(error)
    }

    return !error;
  }

  _handleUpdateNavLinks(){
    let {
      navLinks,
      companySlug
    } = this.state

    if(!this._isValid(navLinks)){
      return
    }

    Backend.updateNavLinks(navLinks, companySlug).then(() => {
      Notify.success("Nav Link Updated")
      this.setState({expand: !this.state.expand})
    }).catch(e => Notify.error(e.message))
  }

  _updateBlocksOrder(){
    let {
      company,
      contentBlocks
    } = this.state

    let orderedContentBlocks = contentBlocks.map(contentBlock => contentBlock.id)

    let containsNull = orderedContentBlocks.some(contentBlock => { return contentBlock === null})

    if(!containsNull){
      let data = {content: orderedContentBlocks}
      Backend.orderContentBlocks(data, company).then(() => {})
    }
  }

  _renderContentBlock(entry, index){
    let {
      contentLinks,
      contentBlocks
    } = this.state

    let expand = !entry.id && contentBlocks.length - 1 === index ? true : null

    return (
      <Block
        expand={expand}
        contentBlock={entry}
        contentLinks={contentLinks}
        onDelete={() => {
          contentBlocks.splice(index, 1)
          this.setState({contentBlocks})
        }}
      />
    )
  }

  render() {
    let {
      showBlockPickerModal,
      loadingNavLinks,
      contentBlocks,
      loadingBlocks,
      contentLinks,
      navLinks,
      company,
      blocks
    } = this.state

    return (
      <>
        <div className="row">
          <div className="col-lg-7 p-0">
            <Card
              title={"Blocks"}
              loading={loadingBlocks}
              renderToolbar={() => {
                return (
                  <div className="mt-auto mb-auto">
                    <a
                      className="btn btn-primary ml-3 pull-right"
                      href="javascript:;"
                      onClick={() => this.setState({ showBlockPickerModal: true })}
                    >
                      + Add Block
                    </a>
                  </div>
                )
              }}
            >
              <SmartList
                items={contentBlocks}
                canDelete={false}
                renderItem={(entry, index) => this._renderContentBlock(entry, index)}
                maximumItems={-1}
                onUpdated={items => {
                  contentBlocks = items
                  this.setState({contentBlocks}, () => this._updateBlocksOrder())
                }}
              />
            </Card>
          </div>
          <div className="col-lg-5 p-0">
            <Card
              title={"Nav Links"}
              loading={loadingNavLinks}
            >
              <SmartList
                items={navLinks}
                canDelete={false}
                renderItem={(navLink, index) => {
                  let expand = navLink.id ? null : true
                  return (
                    <NavLink
                      expand={expand}
                      navLink={navLink}
                      contentLinks={contentLinks}
                      onUpdated={navLink => {
                        navLinks[index] = navLink
                        this.setState({navLinks})
                      }}
                      onDelete={() => {
                        navLinks.splice(index, 1)
                        this.setState({navLinks})
                      }}
                    />
                  )
                }}
                onUpdated={items => {
                  navLinks = items
                  this.setState({navLinks})
                }}
                onAddItemPressed={() => {
                  navLinks.push({})
                  this.setState({navLinks})
                }}
              />
              <div className="form-group float-right">
                <div className="text-center">
                  <button
                    id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
                    onClick={() => this._handleUpdateNavLinks()}
                  >
                    Update
                  </button>
                </div>
              </div>
            </Card>
          </div>
        </div>
        {
          showBlockPickerModal &&
          <BlockPickerModal
            show={showBlockPickerModal}
            blocks={blocks}
            onCancel={() => this.setState({ showBlockPickerModal: false })}
            onAdd={block => {
              contentBlocks.push({block, company_id: company.id, padding_top: 60, padding_bottom: 60})
              this.setState({
                contentBlocks,
                showBlockPickerModal: false
              })
              $('html, body').animate({scrollTop: $(document).height()}, 'slow');
            }}
          />
        }
      </>
    )
  }
}
