import React from 'react'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import ImageEdit from "../common/ImageEdit";
import SmartList from "../common/SmartList";
import General from "../../../utils/General";

const ICONS = [
  {
    value: 'fa-tags',
    label: 'Industry'
  },
  {
    value: 'fa-map-marker',
    label: 'Location'
  },
  {
    value: 'fa-users',
    label: 'Headcount'
  },
  {
    value: 'fa-star',
    label: 'Values, Perks & Benefits'
  },
  {
    value: 'fa-thumbs-up',
    label: 'Blank'
  }
]

export default class Card extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(this._getState(nextProps))
  }

  _getState(props) {
    return {
      ...props,
      company: props.company,
    }
  }

  _load(){
    Backend.getCompany(this.state.company).then(company => {
      this.setState({company})
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _isValid(company){
    let error = null;

    company.cards.map((entry, index) => {
      let itemNumber = General.toOrdinal(index+1)
      if(!entry.icon){
        error = `Please select an icon for the ${itemNumber} card entry`
      }else if(!entry.text){
        error = `Please enter the text for the ${itemNumber} card entry`
      }
    })

    if(error){
      Notify.error(error)
    }

    return !error;
  }

  _update(){
    let {
      company
    } = this.state

    if(!this._isValid(company)){
      return
    }

    Backend.updateCompany(company).then(e => {
      Notify.success("Company Updated")
    }).catch(e => Notify.error(e.message))
  }

  _renderCardInput(entry, index){
    let {
      company
    } = this.state

    return (
      <>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Icon</label>
              <select
                className="form-control"
                value={entry.icon}
                onChange={e => {
                  company.cards[index].icon = e.target.value
                  this.setState({company})
                }}
              >
                <option value="">No Icon</option>
                {
                  ICONS.map(icon => (
                    <option value={icon.value}>{icon.label}</option>
                  ))
                }
              </select>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Text</label>
              <input
                className="form-control"
                type="text"
                value={entry.text}
                onChange={e => {
                  company.cards[index].text = e.target.value
                  this.setState({company})
                }}
              />
            </div>
          </div>
        </div>
        <hr/>
      </>
    )
  }

  render() {
    let {
      company
    } = this.state

    return (
      <>
        <div className="form-group">
          <SmartList
            items={company.cards}
            renderItem={(entry, index) => this._renderCardInput(entry, index)}
            onAddItemPressed={() => {
              company.cards.push({})
              this.setState({company})
            }}
            onUpdated={items => {
              company.cards = items
              this.setState({company})
            }}
          />
        </div>
        <div className="form-group float-right">
          <div className="text-center">
            <button
              id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
              onClick={() => this._update()}
            >
              Update
            </button>
          </div>
        </div>
      </>
    )
  }
}