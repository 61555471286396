import React from "react"
import { withRouter } from "react-router-dom";

import moment from 'moment'

import LazyLoadingList from './common/LazyLoadingList'
// import NotesModal from './modal/NotesModal'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

class QuickPanel extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      notes: []
    }

    this.noteList = React.createRef()
    this.feedList = React.createRef()

  }

  _renderNotes(){
    let { notes } = this.state

    return (
      <div className="kt-notes">
        <div className="kt-notes__items">

        </div>
      </div>
    )
  }

  _renderFeed(){

    return (
      <></>
    )
  }

  render() {
    let {
      title,
      selectedJob,
      selectedCompany,
      showNotesModal
    } = this.state

    return (
      <div id="kt_quick_panel" className="kt-quick-panel">
        <a href="#" className="kt-quick-panel__close" id="kt_quick_panel_close_btn"><i className="flaticon2-delete"></i></a>
        <div className="kt-quick-panel__nav">
          <ul className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand  kt-notification-item-padding-x" role="tablist">
            <li className="nav-item active">
              <a className="nav-link active" data-toggle="tab" href="#kt_quick_panel_tab_notifications" role="tab">Feed</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#kt_quick_panel_tab_logs" role="tab">Notes</a>
            </li>
          </ul>
        </div>
        <div className="kt-quick-panel__content">
          <div className="tab-content">
            <div className="tab-pane fade show kt-scroll active" id="kt_quick_panel_tab_notifications" role="tabpanel">
              <div className="kt-notification">
                { this._renderFeed() }
              </div>
            </div>
            <div className="tab-pane fade kt-scroll" id="kt_quick_panel_tab_logs" role="tabpanel">
              <div className="kt-notification-v2">
                { this._renderNotes() }
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default withRouter(QuickPanel);
