import React from 'react'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import ImageEdit from "../common/ImageEdit";
import moment from "moment";
import DatePicker from "react-datepicker";

export default class WidgetStats extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {
    // this._load()
  }

  componentWillReceiveProps(nextProps) {
    // this.setState(this._getState(nextProps), () => this._load())
  }

  _getState(props) {
    return {
      ...props,
      channel: props.channel,
      fromDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      stats: [],
      type: ''
    }
  }

  _load(){
    let {
      type,
      toDate,
      fromDate
    } = this.state

    if(!type){
      Notify.error("Please select a widget type")
      return
    }

    Backend.getChannelWidgetStats(type, fromDate, toDate).then(stats => {
      this.setState({stats})
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _export(){
    let {
      type,
      toDate,
      fromDate
    } = this.state

    if(!type){
      Notify.error("Please select a widget type")
      return
    }

    Backend.exportChannelWidgetStats(type, fromDate, toDate).then(stats => {
      Notify.success("Export requested, check your email in a few minutes.")
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  render() {
    let {
      stats,
      toDate,
      channel,
      fromDate
    } = this.state

    return (
      <>
        <div
          className={"row"}
        >
          <div className={"col-lg-2"}>
            <div className="form-group">
              <label>From Date</label>
              <DatePicker
                selected={Date.parse(fromDate) || ''}
                dateFormat={'yyyy-MM-dd'}
                className="form-control"
                placeholderText={"Post Date"}
                onChange={date => {
                  fromDate = moment(date).format('YYYY-MM-DD')
                  this.setState({fromDate})
                }}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>
          <div className={"col-lg-2"}>
            <div className="form-group">
              <label>To Date</label>
              <DatePicker
                selected={Date.parse(toDate) || ''}
                dateFormat={'yyyy-MM-dd'}
                className="form-control"
                placeholderText={"Post Date"}
                onChange={date => {
                  toDate = moment(date).format('YYYY-MM-DD')
                  this.setState({toDate})
                }}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>
          <div className={"col-lg-3"}>
            <div className="form-group">
              <label>Widget</label>
              <select
                className={"form-control"}
                onChange={e => {
                  this.setState({type: e.target.value})
                }}
              >
                <option>Select Widget</option>
                <option value={`${channel.slug}_sidebar_jobs_widget`}>Sidebar Jobs</option>
                <option value={`${channel.slug}_sidebar_companies_widget`}>Sidebar Companies</option>
                <option value={`${channel.slug}_sidebar_articles_widget`}>Sidebar Articles</option>
                <option value={`${channel.slug}_sidebar_job_widget`}>Sidebar Job</option>
                <option value={`${channel.slug}_sidebar_company_widget`}>Sidebar Company</option>
                <option value={`${channel.slug}_sidebar_article_widget`}>Sidebar Article</option>
                <option value={`${channel.slug}_horizontal_jobs_widget`}>Horizontal Jobs</option>
                <option value={`${channel.slug}_horizontal_companies_widget`}>Horizontal Companies</option>
                <option value={`${channel.slug}_horizontal_articles_widget`}>Horizontal Articles</option>
                <option value={`${channel.slug}_horizontal_job_widget`}>Horizontal Job</option>
                <option value={`${channel.slug}_horizontal_company_widget`}>Horizontal Company</option>
                <option value={`${channel.slug}_horizontal_article_widget`}>Horizontal Article</option>
                <option value={`${channel.slug}_masonry_jobs_widget`}>Masonry Jobs</option>
                <option value={`${channel.slug}_masonry_companies_widget`}>Masonry Companies</option>
                <option value={`${channel.slug}_masonry_articles_widget`}>Masonry Articles</option>
              </select>
            </div>
          </div>
          <div className={"col-lg-1"}/>
          <div className={"col-lg-2"}>
            <button
              id="escalate" type="button" className="btn btn-secondary mt-3" style={{width: '100%'}}
              onClick={() => this._export()}
            >
              Export
            </button>
          </div>
          <div className={"col-lg-2"}>
            <button
              id="escalate" type="button" className="btn btn-primary mt-3" style={{width: '100%'}}
              onClick={() => this._load()}
            >
              Load
            </button>
          </div>
        </div>
        <div
          style={{overflowY: 'scroll', textAlign: 'center'}}
        >
          <table
            className="table"
          >
            <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Type</th>
              <th scope="col">Impression</th>
              <th scope="col">Clicks</th>
            </tr>
            </thead>
            <tbody>
            {
              stats.map(stat => (
                <tr>
                  <td>{stat.date}</td>
                  <td>{stat.type}</td>
                  <td>{stat.impressions}</td>
                  <td>{stat.clicks}</td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </div>
      </>
    )
  }
}
