import React from 'react'
import JobTypesTable from "./components/tables/JobTypesTable";

export default class JobTypes extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

    }
    this.documentsTable = React.createRef()
  }

  componentDidMount(){

  }

  render() {

    let {

    } = this.state

    return (
      <>
        <JobTypesTable
          title={"Job Types"}
        />
      </>
    )
  }
}
