import FetchHelper from './FetchHelper'
import AuthManager from './AuthManager'
import moment from "moment";

export default class Backend {

  static getJob(job){
    return FetchHelper.get(`${window.Api.Jobs}/${job.id}`)
  }

  static getJobFeedControl(job){
    return FetchHelper.get(`${window.Api.Jobs}/${job.id}/jobfeedcontrol`)
  }

  static getCompanyFeedControl(company){
    return FetchHelper.get(`${window.Api.Companies}/${company.id}/external-distributions`)
  }

  static getCompany(company){
    return FetchHelper.get(`${window.Api.Companies}/${company.id}`)
  }

  static getChannel(channel){
    return FetchHelper.get(`${window.Api.Channels}/${channel.id}`)
  }

  static getChannelBySlug(channelSlug){
    return FetchHelper.get(`${window.Api.Channels}/${channelSlug}`)
  }

  static getCompanyBySlug(companySlug){
    return FetchHelper.get(`${window.Api.Companies}/${companySlug}`)
  }

  static getChannelInfo(channel){
    return FetchHelper.get(`${window.Api.Channels}/${channel.id}/info`)
  }

  static updateCompany(company){
    let data = JSON.parse(JSON.stringify(company))
    return FetchHelper.patch(`${window.Api.Companies}/${company.id}`, data)
  }

  static addChannel(channel){
    let data = JSON.parse(JSON.stringify(channel))
    return FetchHelper.post(`${window.Api.Channels}`, data)
  }

  static updateChannel(channel){
    let data = JSON.parse(JSON.stringify(channel))
    if(data.companies_details){
      data.companies_details = data.companies_details.map(company => {
        return company.id
      })
    }
    if(data.featured_companies){
      data.featured_companies = data.featured_companies.map(featuredCompany => {
        return featuredCompany.id ? featuredCompany.id : featuredCompany.data.id
      })
    }
    if(data.featured_jobs){
      if(data.featured_jobs.results && data.featured_jobs.results.length > 0){
        data.featured_jobs = data.featured_jobs.results.map(job => job.id)
      }
    }
    return FetchHelper.patch(`${window.Api.AdminChannels}/${channel.id}`, data)
  }

  static updateChannelInfo(channel, channelInfo){
    let data = JSON.parse(JSON.stringify(channelInfo))
    return FetchHelper.patch(`${window.Api.AdminChannels}/${channel.id}/info`, data)
  }

  static updateJob(job){
    let data = JSON.parse(JSON.stringify(job))
    data.type = data.type.id
    return FetchHelper.patch(`${window.Api.Companies}/jobs/${job.id}`, data)
  }

  static updateJobFeedControl(feedControl){
    let data = JSON.parse(JSON.stringify(feedControl))
    delete data.job
    return FetchHelper.patch(`${window.Api.Jobs}/${feedControl.job}/jobfeedcontrol`, data)
  }

  static updateCompanyFeedControl(feedControl){
    let data = JSON.parse(JSON.stringify(feedControl))
    delete data.company
    return FetchHelper.patch(`${window.Api.Companies}/${feedControl.company}/external-distributions`, data)
  }

  static getCompanySubscription(company){
    return FetchHelper.get(`${window.Api.Companies}/${company.id}/subscriptions`)
  }

  static updateCompanySubscription(subscription){
    let data = JSON.parse(JSON.stringify(subscription))
    return FetchHelper.patch(`${window.Api.Companies}/${subscription.company}/subscriptions/${subscription.id}`, data)
  }

  static getPopularCompanies(){
    return FetchHelper.get(`${window.Api.Companies}?popular=true`)
  }

  static createMember(member, company){
    let data = JSON.parse(JSON.stringify(member))
    return FetchHelper.post(`${window.Api.Companies}/${company.id}/members`, data)
  }

  static updateMember(member){
    let data = JSON.parse(JSON.stringify(member))
    return FetchHelper.patch(`${window.Api.CompanyMembers}/${data.user.id}`, data)
  }

  static uploadImage(image, type){
    let data = new FormData()

    data.append("image", image, image.name)

    return FetchHelper.post(`${window.Api.Images}?type=${type}`, data, true)
  }

  static getBlogPosts(){
    return FetchHelper.get(`${window.Api.BlogPosts}`)
  }

  static createBlogPost(blogPost){
    let data = JSON.parse(JSON.stringify(blogPost))
    return FetchHelper.post(`${window.Api.BlogPosts}`, data)
  }

  static updateBlogPost(blogPost){
    let data = JSON.parse(JSON.stringify(blogPost))
    return FetchHelper.patch(`${window.Api.BlogPosts}/${blogPost.id}`, data)
  }

  static deleteBlogPost(blogPost){
    return FetchHelper.delete(`${window.Api.BlogPosts}/${blogPost.id}`)
  }

  static updateAccount(data){
    return FetchHelper.patch(`${window.Api.Admins}`, data)
  }

  static addPackage(packageObject){
    let data = JSON.parse(JSON.stringify(packageObject))
    return FetchHelper.post(`${window.Api.Packages}`, data)
  }

  static getPackage(packageObject){
    return FetchHelper.get(`${window.Api.Packages}/${packageObject.id}`)
  }

  static updatePackage(packageObject){
    let data = JSON.parse(JSON.stringify(packageObject))
    return FetchHelper.patch(`${window.Api.Packages}/${packageObject.id}`, data)
  }

  static addJobType(jobType){
    let data = JSON.parse(JSON.stringify(jobType))
    return FetchHelper.post(`${window.Api.JobTypes}`, data)
  }

  static addCoupon(coupon){
    let data = JSON.parse(JSON.stringify(coupon))
    return FetchHelper.post(`${window.Api.Coupons}`, data)
  }

  static updateCoupon(coupon){
    let data = JSON.parse(JSON.stringify(coupon))
    return FetchHelper.patch(`${window.Api.Coupons}/${coupon.id}`, data)
  }

  static _parseArticle(article){
    let data = JSON.parse(JSON.stringify(article))
    data.categories = data.categories.map(category => category.id)
    data.channels = data.channels.map(channel => channel.id)

    return data
  }

  static createArticle(article){
    let data = this._parseArticle(article)
    return FetchHelper.post(`${window.Api.Articles}`, data)
  }

  static updateArticle(article){
    let data = this._parseArticle(article)
    return FetchHelper.patch(`${window.Api.Articles}/${article.id}`, data)
  }

  static deleteArticle(article){
    return FetchHelper.delete(`${window.Api.Articles}/${article.id}`)
  }

  static getBlocks(){
    return FetchHelper.get(`${window.Api.Blocks}`)
  }

  static getBranding(companySlug){
    return FetchHelper.get(`${window.Api.Blocks}/content?company_slug=${companySlug}`)
  }

  static getNavLinks(companySlug){
    return FetchHelper.get(`${window.Api.Channels}/${companySlug}/navlinks`)
  }

  static addBlock(contentBlock){
    let data = JSON.parse(JSON.stringify(contentBlock))
    data.block_id = data.block.id
    delete data.block
    return FetchHelper.post(`${window.Api.Blocks}/content`, data)
  }

  static updateBlock(contentBlock){
    let data = JSON.parse(JSON.stringify(contentBlock))
    delete data.block
    delete data.company
    return FetchHelper.patch(`${window.Api.Blocks}/content/${contentBlock.id}`, data)
  }

  static deleteBlock(contentBlock){
    return FetchHelper.delete(`${window.Api.Blocks}/content/${contentBlock.id}`)
  }

  static updateNavLinks(navLinks, companySlug){
    let data = JSON.parse(JSON.stringify(navLinks))
    data.map(navLink => {
      navLink.link_id = navLink.link.id ? navLink.link.id : navLink.link
      delete navLink.link
    })
    data = {nav_link_list: data}
    return FetchHelper.patch(`${window.Api.Channels}/${companySlug}/navlinks`, data)
  }

  static getChannelActivities(channel, inChannel, type){
    return FetchHelper.get(`${window.Api.Channels}/${channel.id}/activities?in_channel=${inChannel}&type=${type}`)
  }

  static createAdmin(admin){
    let data = JSON.parse(JSON.stringify(admin))
    return FetchHelper.post(`${window.Api.Admins}`, data)
  }

  static updateAdmin(admin){
    let data = JSON.parse(JSON.stringify(admin))
    return FetchHelper.patch(`${window.Api.Admins}/staff/${admin.user.id}`, data)
  }

  static orderContentBlocks(contentBlocks, company){
    return FetchHelper.patch(`${window.Api.Companies}/${company.id}/blocks/content`, contentBlocks)
  }

  static updatePopularCompanies(companies){
    let data = JSON.parse(JSON.stringify(companies))
    data.companies = data.companies.map(company => company.id)
    return FetchHelper.patch(`${window.Api.Companies}`, data)
  }

  static requestCompanyData(fromDate, toDate, company){
    return FetchHelper.get(`${window.Api.Exports}/company-data?date_from=${fromDate}&date_to=${toDate}&company_id=${company.id}`)
  }

  static getChannelStats(channel, fromDate, toDate){
    return FetchHelper.get(`${window.Api.SourceStats}?source=${channel.slug}&date_from=${fromDate}&date_to=${toDate}`)
  }

  static exportChannelStats(channel, fromDate, toDate){
    return FetchHelper.get(`${window.Api.ExportSourceStats}?source=${channel.slug}&date_from=${fromDate}&date_to=${toDate}`)
  }

  static getChannelWidgetStats(type, fromDate, toDate){
    return FetchHelper.get(`${window.Api.WidgetStats}?source=${type}&date_from=${fromDate}&date_to=${toDate}`)
  }

  static exportChannelWidgetStats(type, fromDate, toDate){
    return FetchHelper.get(`${window.Api.ExportWidgetStats}?source=${type}&date_from=${fromDate}&date_to=${toDate}`)
  }

  static loadJobFilters(){
    return FetchHelper.get(window.Api.JobsFilters)
  }

  static loadCompanyFilters(){
    return FetchHelper.get(window.Api.CompanyFilters)
  }

  static loadApplicationFilters(){
    return FetchHelper.get(window.Api.ApplicationFilters)
  }

  static createPartnerMember(member){
    let data = JSON.parse(JSON.stringify(member))
    return FetchHelper.post(`${window.Api.PartnerMembers}`, data)
  }

  static updatePartnerMember(member){
    let data = JSON.parse(JSON.stringify(member))
    return FetchHelper.patch(`${window.Api.PartnerMembers}/${data.user.id}`, data)
  }

  static generateRSS(data){
    data = JSON.parse(JSON.stringify(data))
    return FetchHelper.post(window.Api.GenerateXML, data)
  }

  static getPartners(company){
    return FetchHelper.get(`${window.Api.Companies}/${company.id}/channels?paginated=false`)
  }

  static updateCompanyPartner(company, channels){
    let unparsedData = JSON.parse(JSON.stringify(channels))
    let data = {channels: []}

    unparsedData.map(channel => {
      if(channel.has_company){
        data.channels.push(channel.id)
      }
    })

    return FetchHelper.post(`${window.Api.Companies}/${company.id}/channels`, data)
  }

}
