import React from 'react'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import ImageEdit from "../common/ImageEdit";
import TextEditor from "../common/TextEditor";
import CountrySelect from "../common/CountrySelect";

export default class Edit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {
    this._load()
  }

  componentWillReceiveProps(nextProps) {
    // this.setState(this._getState(nextProps), () => this._load())
  }

  _getState(props) {
    return {
      ...props,
      job: props.job,
    }
  }

  _load(){
    Backend.getJob(this.state.job).then(job => {
      this.setState({job})
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _update(){
    let {
      job
    } = this.state

    Backend.updateJob(job).then(e => {
      Notify.success("Job Updated")
    }).catch(e => Notify.error(e.message))
  }

  render() {
    let {
      job
    } = this.state

    return (
      <>
        <div className="form-group">
          <label>Title</label>
          <input
            className="form-control"
            type="text"
            value={job.title}
            onChange={e => {
              job.title = e.target.value
              this.setState({job})
            }}
          />
        </div>
        <div className="form-group">
          <label>Description</label>
          <TextEditor
            text={ job.description }
            className="form-control"
            placeholder="Article body goes here..."
            onUpdated={text => {
              job.description = text
              this.setState({ job})
            }}
          />
        </div>
        <div className="form-group">
          <label>Country</label>
          <CountrySelect
            value={job.location.country_short}
            onSelected={country => {
              job.location.country = country.label
              job.location.country_short = country.value
              this.setState({job})
            }}
          />
        </div>
        <div className="form-group">
          <label>Address</label>
          <input
            className="form-control"
            type="text"
            value={job.location.address}
            onChange={e => {
              job.location.address = e.target.value
              this.setState({job})
            }}
          />
        </div>
        <div className="form-group">
          <label>City</label>
          <input
            className="form-control"
            type="text"
            value={job.location.city}
            onChange={e => {
              job.location.city = e.target.value
              this.setState({job})
            }}
          />
        </div>
        <div className="form-group">
          <label>State</label>
          <input
            className="form-control"
            type="text"
            value={job.location.state}
            onChange={e => {
              job.location.state = e.target.value
              this.setState({job})
            }}
          />
        </div>
        <div className="form-group">
          <label>Postcode</label>
          <input
            className="form-control"
            type="text"
            value={job.location.post_code}
            onChange={e => {
              job.location.post_code = e.target.value
              this.setState({job})
            }}
          />
        </div>
        <div className="form-group float-right">
          <div className="text-center">
            <button
              id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
              onClick={() => this._update()}
            >
              Update
            </button>
          </div>
        </div>
      </>
    )
  }
}