import React from 'react'
import {Route, Switch} from 'react-router-dom'

import LeftMenu from './components/LeftMenu'
import MobileHeader from './components/MobileHeader'
import Footer from './components/Footer'
import Header from './components/Header'
import QuickPanel from './components/QuickPanel'

import Dashboard from "./Dashboard";
import Jobs from "./Jobs";
import JobTypes from "./JobTypes";
import Companies from "./Companies";
import Company from "./Company";
import CompanyMembers from "./CompanyMembers";
import ExternalDistributions from "./ExternalDistributions";
import Applications from "./Applications";
import Talent from "./Talent";
import Channels from "./Channels";
import Channel from "./Channel";
import Packages from "./Packages";
import Coupons from "./Coupons";
import Homepage from "./Homepage";
import Articles from "./Articles";
import Settings from "./Settings";
import Team from "./Team";

export default class App extends React.Component {

  render() {
    return (
      <>
        <MobileHeader />

        <div className="kt-grid kt-grid--hor kt-grid--root" style={{ minHeight: "100%" }}>
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <LeftMenu />
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              <Header />

              {/* begin:: Content */}
              <div
                className="kt-content  kt-grid__item kt-grid__item--fluid"
                id="kt_content"
              >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                  <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route exact path="/companies" component={Companies} />
                    <Route exact path="/companies/:company_slug" component={Company} />
                    <Route exact path="/company-members" component={CompanyMembers} />
                    <Route exact path="/external-distributions" component={ExternalDistributions} />
                    <Route exact path="/jobs" component={Jobs} />
                    <Route exact path="/job-types" component={JobTypes} />
                    <Route exact path="/applications" component={Applications} />
                    <Route exact path="/talent" component={Talent} />
                    <Route exact path="/channels" component={Channels} />
                    <Route exact path="/channels/:channel_slug" component={Channel} />
                    <Route exact path="/packages" component={Packages} />
                    <Route exact path="/coupons" component={Coupons} />
                    <Route exact path="/homepage" component={Homepage} />
                    <Route exact path="/articles" component={Articles} />
                    <Route exact path="/settings" component={Settings} />
                    <Route exact path="/team" component={Team} />
                  </Switch>

                </div>
                {/*  end:: Content  */}
              </div>
              {/*  begin:: Footer  */}
              <Footer />
              {/*  end:: Footer  */}
            </div>
          </div>
          {/*  end:: Page  */}
          <QuickPanel/>
          {/*  begin::Scrolltop  */}
          {/* <div id="kt_scrolltop" className="kt-scrolltop">
            <i className="fa fa-arrow-up" />
          </div> */}
        </div>
      </>
    )
  }
}
