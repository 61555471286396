import React from 'react'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import ImageEdit from "../common/ImageEdit";
import LazyLoadingList from "../common/LazyLoadingList";
import CountrySelect from "../common/CountrySelect";

export default class Members extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
    }
    this.list = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props) {
    return {
      ...props,
      channel: props.channel,
      member: {channel_id: props.channel.id, role: "admin", user: {}}
    }
  }

  _isValid(member){
    let error = null;

    if(!member.user.first_name){
      error = "Please enter the user's first name"
    }else if(!member.user.last_name){
      error = "Please enter the user's last name"
    }else if(!member.user.email){
      error = "Please enter the user's email"
    }else if(!member.user.password){
      error = "Please enter the user's password"
    }else if(!member.user.country_code){
      error = "Please enter the user's country"
    }

    if(error){
      Notify.error(error)
    }

    return !error;
  }

  _createMember(){
    let {
      member,
      channel
    } = this.state

    if(!this._isValid(member)){
      return
    }

    Backend.createPartnerMember(member).then(response => {
      Notify.success(`User created.`)
      this.list.current.refresh()
      this.setState({member: {channel_id: channel.id, role: "admin", user: {}}, showMemberForm: false})
    }).catch(e => Notify.error(e.message))
  }

  _updateMember(member){
    Backend.updatePartnerMember(member).then(response => {
      Notify.success(`${member.user.first_name} ${member.user.last_name} updated.`)
      this.list.current.refresh()
    }).catch(e => Notify.error(e.message))
  }

  render() {
    let {
      channel,
      member,
      showMemberForm
    } = this.state

    return (
      <>
        {
          !showMemberForm &&
          <div className="row">
            <a
              className="btn btn-primary ml-3"
              href="javascript:;"
              onClick={() => this.setState({ showMemberForm: true })}
            >
              + Add Member
            </a>
          </div>
        }
        {
          showMemberForm &&
          <>
            <div className="form-group">
              <label>First Name</label>
              <input
                className="form-control"
                type="text"
                value={member.user.first_name}
                onChange={e => {
                  member.user.first_name = e.target.value
                  this.setState({member})
                }}
              />
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input
                className="form-control"
                type="text"
                value={member.user.last_name}
                onChange={e => {
                  member.user.last_name = e.target.value
                  this.setState({member})
                }}
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                className="form-control"
                type="text"
                value={member.user.email}
                onChange={e => {
                  member.user.email = e.target.value
                  this.setState({member})
                }}
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                className="form-control"
                type="password"
                value={member.user.password}
                onChange={e => {
                  member.user.password = e.target.value
                  this.setState({member})
                }}
              />
            </div>
            <div className="form-group">
              <label>Role</label>
              <select
                className={"form-control"}
                value={member.role}
                onChange={e => {
                  member.role = e.target.value
                  this.setState({member})
                }}
              >
                <option value="admin">Admin</option>
                <option value="staff">Staff</option>
              </select>
            </div>
            <div className="form-group">
              <label>Country</label>
              <CountrySelect
                value={member.user.country_code}
                onSelected={country => {
                  member.user.country_code = country.value
                  this.setState({member})
                }}
              />
            </div>
            <div className="form-group float-right">
              <div className="text-center">
                <button
                  id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
                  onClick={() => this._createMember()}
                >
                  Create
                </button>
              </div>
            </div>
          </>
        }
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
            </tr>
          </thead>
          <tbody>
            <LazyLoadingList
              ref={this.list}
              endpoint={`${window.Api.Partners}/${channel.id}/members`}
              renderItem={(member, index) => (
                <tr>
                  <td>{`${member.user.first_name} ${member.user.last_name}`}</td>
                  <td>{member.user.email}</td>
                </tr>
              )}
            />
          </tbody>
        </table>

      </>
    )
  }
}