import React from "react"

import Div from "../Div"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Notify from "../../../utils/Notify"

export default class PasswordSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      data: AuthManager.currentUser,
    }
  }

  isFormValid(){
    let {
      data
    } = this.state

    let {
      current_password,
      password,
      confirm_password
    } = data.user

    let isValid = true
    let error = null

    if(!current_password || current_password === ""){
      isValid = false
      error = "Please enter your current password"
    }
    if(!password || password === ""){
      isValid = false
      error = "Please enter a valid password"
    }
    else if(password !== confirm_password){
      isValid = false
      error = "Passwords don't match"
    }

    if(error){
      Notify.error(error)
    }

    return isValid
  }

  _update(){
    let {
      data
    } = this.state

    if(!this.isFormValid()){
      return
    }

    this.setState({ loading: true })
    Backend.updateAccount(data)
    .then(() => {
      data.user.password = ""
      data.user.confirm_password = ""
      data.user.current_password = ""
      this.setState({
        loading: false,
        data
      })
      Notify.success("Your password has been changed successfully!")
    })
    .catch(error => {
      Notify.error(error.message)
      this.setState({
        loading: false
      })
    })
  }

  _handleChange(e) {
    let data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _handleChangeUser(e) {
    let data = this.state.data
    data.user[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  render() {
    let {
      data,
      loading
    } = this.state

    return (
      <>
        <Div
          className="kt-wizard-v2__content"
          data-ktwizard-type="step-content"
          data-ktwizard-state="current"
          disabled={loading}
        >
          <div className="kt-heading kt-heading--md">Password Settings</div>
          <div className="kt-form__section kt-form__section--first">
            <div className="kt-wizard-v2__form">

              <div className="form-group">
                <label>Old Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="current_password"
                  value={data.user.current_password}
                  onChange={e => this._handleChangeUser(e)}
                />
              </div>

              <div className="form-group">
                <label>New Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={data.user.password}
                  onChange={e => this._handleChangeUser(e)}
                />
              </div>


              <div className="form-group">
                <label>Verify Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="confirm_password"
                  value={data.user.confirm_password}
                  onChange={e => this._handleChangeUser(e)}
                />
              </div>


            </div>
          </div>
        </Div>
        <div className="kt-form__actions pull-right">
          <button
            className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
            disabled={loading}
            onClick={() => this._update()}
          >
            Save Changes
          </button>
        </div>
      </>
    )
  }
}
