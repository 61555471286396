import React from 'react'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import ImageEdit from "../common/ImageEdit";
import Switch from "react-switch";

export default class PopUp extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {
    // this._load()
  }

  componentWillReceiveProps(nextProps) {
    // this.setState(this._getState(nextProps), () => this._load())
  }

  _getState(props) {
    return {
      ...props,
      packageObject: props.packageObject,
    }
  }

  _update(){
    let {
      packageObject
    } = this.state

    Backend.updatePackage(packageObject).then(e => {
      this.props.onUpdate(packageObject)
      Notify.success("Package Updated")
    }).catch(e => Notify.error(e.message))
  }

  render() {
    let {
      packageObject
    } = this.state

    return (
      <>
        <div className="form-group">
          <label>Enable Pop Up</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              packageObject.partner = checked
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
            checked={packageObject.partner}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        <div className="form-group">
          <label>Lock</label>
          <Switch
            className="react-switch pull-right"
            onChange={checked => {
              packageObject.lock = checked
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
            checked={packageObject.lock}
            aria-labelledby="neat-label"
            onColor="#5867dd"
            offColor="#d5d5d5"
            checkedIcon={null}
            uncheckedIcon={null}
          />
        </div>
        <div className="form-group">
          <label>Title</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.onboarding_info?.title}
            onChange={e => {
              if(!packageObject.onboarding_info){
                packageObject.onboarding_info = {}
              }
              packageObject.onboarding_info.title = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Text</label>
          <textarea
            className="form-control"
            value={packageObject.onboarding_info?.text}
            rows={3}
            onChange={e => {
              if(!packageObject.onboarding_info){
                packageObject.onboarding_info = {}
              }
              packageObject.onboarding_info.text = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Primary CTA</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.onboarding_info?.primary_cta_text}
            onChange={e => {
              if(!packageObject.onboarding_info){
                packageObject.onboarding_info = {}
              }
              packageObject.onboarding_info.primary_cta_text = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Primary CTA URL</label>
          <textarea
            className="form-control"
            value={packageObject.onboarding_info?.primary_cta_url}
            rows={3}
            onChange={e => {
              if(!packageObject.onboarding_info){
                packageObject.onboarding_info = {}
              }
              packageObject.onboarding_info.primary_cta_url = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Secondary CTA</label>
          <input
            className="form-control"
            type="text"
            value={packageObject.onboarding_info?.secondary_cta_text}
            onChange={e => {
              if(!packageObject.onboarding_info){
                packageObject.onboarding_info = {}
              }
              packageObject.onboarding_info.secondary_cta_text = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        <div className="form-group">
          <label>Secondary CTA URL</label>
          <textarea
            className="form-control"
            value={packageObject.onboarding_info?.secondary_cta_url}
            rows={3}
            onChange={e => {
              if(!packageObject.onboarding_info){
                packageObject.onboarding_info = {}
              }
              packageObject.onboarding_info.secondary_cta_url = e.target.value
              if(packageObject.id){
                this.setState({packageObject})
              }else{
                this.setState({packageObject})
                this.props.onUpdate(packageObject)
              }
            }}
          />
        </div>
        {
          packageObject.id &&
          <div className="form-group float-right">
            <div className="text-center">
              <button
                id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
                onClick={() => this._update()}
              >
                Update
              </button>
            </div>
          </div>
        }
      </>
    )
  }
}