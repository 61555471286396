import React, { Spinner } from 'react'

import {
    EditorState,
    convertToRaw,
    ContentState,
    convertFromRaw,
    convertFromHTML
} from 'draft-js';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html';

export default class TextEditor extends React.Component  {
  constructor(props){
    super(props)

    let editorState = null
    let text = props.text

    if(text){
        try{
          editorState = EditorState.createWithContent(stateFromHTML(text))
        }
        catch(error){
          const contentState = ContentState.createFromText(text);
          editorState = EditorState.createWithContent(contentState);
        }
    }
    else{
        editorState = EditorState.createEmpty()
    }

    this.state = {
      text,
      editorState,
      focus: false
    }
  }

  _onEditorStateChange(editorState){
    let {
      text
    } = this.state

    let contentState = editorState.getCurrentContent()

    text = stateToHTML(contentState)
    this.setState({
      text,
      editorState
    });

    this.props.onUpdated(text)
  };

  render(){
    let {
      text,
      focus,
      editorState
    } = this.state

    let wrapperClassName = "text-editor-wrapper c-wysiwyg"
    if(focus){
      wrapperClassName += " focus"
    }
    return (
      <Editor
        editorState={editorState}
        toolbarClassName="knowledgebase-toolbar"
        wrapperClassName={wrapperClassName}
        editorClassName={"text-editor form-control-solid"}
        onFocus={() => this.setState({ focus: true })}
        onBlur={() => this.setState({ focus: false })}
        onEditorStateChange={editorState => this._onEditorStateChange(editorState)}
        toolbar={this.props.toolbarOptions}
      />
    )
  }
}

TextEditor.defaultProps = {
  toolbarOptions: {
    options: ['inline', 'link', 'list'],
    inline: {
      visible: true,
      className: "",
      options: ['bold', 'italic', 'underline'],
      bold: { className: "c-option toolbar-text-bold" },
      italic: { className: "c-option toolbar-text-italic" },
      underline: { className: "c-option toolbar-text-underline" },
    },
    link: {
      visible: true,
      className: "",
      options: ['link', 'unlink'],
      link: { className: "c-option toolbar-text-link" },
      unlink: { className: "c-option toolbar-text-unlink" },
    },
    list: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['unordered', 'ordered'],
      unordered: { className: "c-option" },
      ordered: { className: "c-option" },
    },
  }
}
