import React from "react"
import {Link} from "react-router-dom"

import moment from 'moment'

import BaseTable from "./BaseTable"
import ActionCell from "./cells/ActionCell";
import General from "../../../utils/General";
import PillCell from "./cells/PillCell";
import CompanyModal from "../modal/CompanyModal";
import Backend from "../../../utils/Backend";

const STATUSES = {
  name: {
    api: "status",
    display: "Status",
  },
  values: [{
      label: "All Statuses",
      value: ''
    },
    {
      label: "Active",
      value: "active"
    },
    {
      label: "Frozen",
      value: "frozen"
    },
    {
      label: "Inactive",
      value: "inactive"
    },
    {
      label: "Pending",
      value: "pending"
    }
  ]
}

const SOURCES = {
  name: {
    api: "source",
    display: "Source",
  },
  values: [{
    label: "All Sources",
    value: null
  },
  ]
}

const ICON = (
  <g stroke="none" strokewidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <path d="M5.84026576,8 L18.1597342,8 C19.1999115,8 20.0664437,8.79732479 20.1528258,9.83390904 L20.8194924,17.833909 C20.9112219,18.9346631 20.0932459,19.901362 18.9924919,19.9930915 C18.9372479,19.9976952 18.8818364,20 18.8264009,20 L5.1735991,20 C4.0690296,20 3.1735991,19.1045695 3.1735991,18 C3.1735991,17.9445645 3.17590391,17.889153 3.18050758,17.833909 L3.84717425,9.83390904 C3.93355627,8.79732479 4.80008849,8 5.84026576,8 Z M10.5,10 C10.2238576,10 10,10.2238576 10,10.5 L10,11.5 C10,11.7761424 10.2238576,12 10.5,12 L13.5,12 C13.7761424,12 14,11.7761424 14,11.5 L14,10.5 C14,10.2238576 13.7761424,10 13.5,10 L10.5,10 Z" fill="#000000"></path>
      <path d="M10,8 L8,8 L8,7 C8,5.34314575 9.34314575,4 11,4 L13,4 C14.6568542,4 16,5.34314575 16,7 L16,8 L14,8 L14,7 C14,6.44771525 13.5522847,6 13,6 L11,6 C10.4477153,6 10,6.44771525 10,7 L10,8 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
  </g>
)

export default class CompaniesTable extends React.Component {

  constructor(props){
    super(props)

    let statuses = STATUSES
    let sources = SOURCES

    this.state = {
      loading: true,
      status: props.status,
      statuses,
      sources,
      mode: props.mode,
      source: props.source
    }

    this.table = React.createRef()
  }

  componentDidMount() {
    let {
      sources
    } = this.state

    Backend.loadCompanyFilters().then(filters => {
      filters.sources.map(source => {
        sources.values.push({
          label: General.toTitleCase(source.source),
          value: source.source
        })
      })
      this.setState({sources})
    })
  }

  refresh(){
    if(this.table && this.table.current && !this.state.showProcessModal){
      this.table.current.refresh()
    }
  }

  _getColumns() {
    let columns = [
      {
        Header: 'ID',
        id: 'id',
        accessor: company => (<a href={`${window.General.Site}/${company.slug}`} target="_blank">{company.id}</a>)
      },
      {
        Header: 'Date',
        id: 'created_at',
        accessor: company => moment(company.created_at).format('DD MMM YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Company',
        id: 'name',
        accessor: company => company.branding_channel ? <Link to={`/companies/${company.slug}`}>{company.name}</Link> : company.name,
      },
      {
        Header: 'Live Jobs',
        id: 'live_jobs',
        accessor: 'live_jobs',
      },
      {
        Header: 'Source',
        id: 'source',
        accessor: 'source',
      },
      {
        Header: 'Status',
        id: 'status',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let company = rowInfo.original
          let text = General.snakeCaseToTitleCase(company.status)
          let className = company.status === "frozen" ? 'label-light-primary' : company.status === "inactive" ? 'label-light-danger' : 'label-light-success'
          return (
            <PillCell
              text={text}
              className={className}
            />
          )
        }
      },
      {
        Header: 'Actions',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let company = rowInfo.original
          return (
            <ActionCell
              edit={true}
              onEditClicked={() => this.setState({selectedCompany: company, showCompanyModal: true})}
            />
          )
        }
      },
    ]
    return columns
  }

  render() {
    let {
      selectedCompany,
      showCompanyModal,
      statuses,
      sources
    } = this.state

    const columns = this._getColumns()

    let endpoint = `${window.Api.Companies}`;

    let props = {

    }

    return (
      <>
        <BaseTable
          {...props}
          ref={this.table}
          endpoint={endpoint}
          exportEndpoint={window.Api.ExportCompanies}
          dateFilter={true}
          filters={[statuses, sources]}
          filterValue={{status: '', source: ''}}
          className={''}
          title={ this.props.title }
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          showPagination={this.props.showPagination}
          showPaginationTop={this.props.showPaginationTop}
          showPaginationBottom={this.props.showPaginationBottom}
          exportButtonsEnabled={true}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? 'visible' : 'hidden'
              }
            }
          }}
          getTheadThProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                textAlign: 'left',
                paddingLeft: 10,
                visibility: column.Header === "Actions" ? 'hidden' : 'visible',
              }
            }
          }}
        />

        {
          showCompanyModal &&
          <CompanyModal
            show={showCompanyModal}
            company={selectedCompany}
            onCancel={() => {
              this.table.current.refresh()
              this.setState({showCompanyModal: false})
            }}
          />
        }
      </>
    )
  }
}

CompaniesTable.defaultProps = {
  mode: null
}
