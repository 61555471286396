import React from 'react'
import {withRouter} from 'react-router-dom'

import AuthManager from '../../utils/AuthManager'
// import Event from '../../utils/Event'

import {MENU_ITEMS} from './LeftMenu'

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      username: AuthManager.currentUser.user.first_name
    }
  }

  componentDidMount(){
    this._updateActive()
    // Event.on('USER_UPDATED', () => this.setState({
    //   username: AuthManager.currentUser.user.first_name
    // }));
  }

  componentWillUnmount() {
    // Event.off('USER_UPDATED');
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this._updateActive();
    }
  }

  _updateActive() {
    let pathname = this.props.location.pathname
    MENU_ITEMS.forEach(menuItem => {
      if(menuItem.url == pathname){
        this._setActive(menuItem.title)
      }
    })
  }

  _setActive(title){
    this.setState({ title })
  }

  _onLogoutPressed() {
    AuthManager.logout()
    .then(() => {
      this.props.history.push('/login')
    })
  }

  render() {
    let {
      username,
      title
    } = this.state

    let initial = username.charAt(0)

    return (
      <div
        id="kt_header"
        className="kt-header kt-grid__item  kt-header--fixed "
      >
        <button
          className="kt-header-menu-wrapper-close"
          id="kt_header_menu_mobile_close_btn"
        >
          <i className="la la-close" />
        </button>
        <div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
					<div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default ">
						<h4 style={{ marginTop: 20 }}>
							{ title }
						</h4>
					</div>
				</div>

        {/*  end:: Header Menu */}

        {/*  begin:: Header Topbar */}
        <div className="kt-header__topbar">

          {/* begin: User Bar */}
          <div className="kt-header__topbar-item kt-header__topbar-item--user">
            <div
              className="kt-header__topbar-wrapper"
              data-toggle="dropdown"
              data-offset="0px,0px"
            >
              <div className="kt-header__topbar-user">
              <span class="kt-header__topbar-icon"><i class="flaticon2-user-outline-symbol"></i></span>

              </div>
            </div>
            <div className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
              {/* begin: Head */}
              <div className="kt-user-card kt-user-card--skin-light kt-notification-item-padding-x">
                <div className="kt-user-card__avatar">
                  {/* use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) */}
                  <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold kt-hidden-">
                    {initial}
                  </span>
                </div>
                <div className="kt-user-card__name">{username}</div>
              </div>

              {/* end: Head */}

              {/* begin: Navigation */}
              <div className="kt-notification">
                <div className="kt-notification__custom">
                  <a
                    onClick={() => this._onLogoutPressed()}
                    className="btn btn-label-brand btn-sm btn-bold"
                  >
                    Log Out
                  </a>
                </div>
              </div>

              {/* end: Navigation */}
            </div>
          </div>

          {/* end: User Bar */}
          { !AuthManager.isAdmin() &&
            <div class="kt-header__topbar-item kt-header__topbar-item--quickpanel" data-toggle="kt-tooltip" title="" data-placement="top" data-original-title="Quick panel">
              <div class="kt-header__topbar-wrapper">
                <span class="kt-header__topbar-icon" id="kt_quick_panel_toggler_btn"><i class="flaticon2-cube-1"></i></span>
              </div>
            </div>
          }
        </div>

        {/*  end:: Header Topbar */}
      </div>
    )
  }
}

export default withRouter(Header)
