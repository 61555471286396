import React from 'react'
import TalentTable from "./components/tables/TalentTable";

export default class Talent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

    }
    this.documentsTable = React.createRef()
  }

  componentDidMount(){

  }

  render() {

    let {

    } = this.state

    return (
      <>
        <TalentTable
          title={"Talent"}
        />
      </>
    )
  }
}
