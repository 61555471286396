import React from 'react'
 import { Modal, Tabs, Tab } from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Switch from "react-switch";

import Notify from "../../../utils/Notify";
import Backend from "../../../utils/Backend"
import SmartList from "../common/SmartList";
import Edit from "../article/Edit";
import Members from "../company/Members";
import Subscription from "../company/Subscription";
import JobsTable from "../tables/JobsTable";
import ExternalDistributionTable from "../tables/ExternalDistributionTable";
import FeedControl from "../job/FeedControl";
import General from "../../../utils/General";

export default class AdminModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      show: props.show,
      admin: props.admin || {},
    }
  }

  _isValid(admin){
    let error = null;

    if(!admin.first_name){
      error = "Please enter the user's first name"
    }else if(!admin.last_name){
      error = "Please enter the user's last name"
    }else if(!admin.email){
      error = "Please enter the user's email"
    }else if(!admin.user?.id && !admin.password){
      error = "Please enter the user's password"
    }else if(!admin.level){
      error = "Please enter the user's level"
    }

    if(error){
      Notify.error(error)
    }

    return !error;
  }

  _handleClick(){
    let {
      admin,
    } = this.state

    if(admin.user?.id){
      this._update(admin)
    }else{
      if(!this._isValid(admin)){
        return
      }
      this._add(admin)
    }
  }

  _update(admin){
    Backend.updateAdmin(admin).then(response => {
      Notify.success("Admin Updated Successfully")
      this.props.onCancel()
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _add(admin){
    Backend.createAdmin(admin).then(response => {
      Notify.success("Admin Created Successfully")
      this.props.onCancel()
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  render() {
    let {
      loading,
      show,
      admin,
    } = this.state

    if(!admin || !show) return null

    let title = admin.user?.id ? `Update ${admin.user.first_name} ${admin.user.last_name}` : 'Create User'
    let buttonTitle = admin.user?.id ? `Update` : 'Create'

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
        className={this.props.modalClass}
      >
        <Modal.Header closeButton>
          <h5 className={"modal-title"}>{title}</h5>
        </Modal.Header>

        <Modal.Body>

          {
            !admin.user?.id &&
            <>
              <div className="form-group">
                <label>First Name</label>
                <input
                  className="form-control"
                  type="text"
                  value={admin.first_name}
                  onChange={e => {
                    admin.first_name = e.target.value
                    this.setState({admin})
                  }}
                />
              </div>

              <div className="form-group">
                <label>Last Name</label>
                <input
                  className="form-control"
                  type="text"
                  value={admin.last_name}
                  onChange={e => {
                    admin.last_name = e.target.value
                    this.setState({admin})
                  }}
                />
              </div>

              <div className="form-group">
                <label>Email</label>
                <input
                  className="form-control"
                  type="text"
                  value={admin.email}
                  onChange={e => {
                    admin.email = e.target.value
                    this.setState({admin})
                  }}
                />
              </div>

              <div className="form-group">
                <label>Password</label>
                <input
                  className="form-control"
                  type="password"
                  value={admin.password}
                  onChange={e => {
                    admin.password = e.target.value
                    this.setState({admin})
                  }}
                />
              </div>
            </>
          }

          <div className="form-group">
            <label>Role</label>
            <select
              className="form-control"
              value={admin.level}
              onChange={e => {
                admin.level = e.target.value
                this.setState({admin})
              }}
            >
              <option value="">Select Type</option>
              <option value="1">Staff</option>
              <option value="3">Account Manager</option>
              <option value="5">Super Admin</option>
            </select>
          </div>

        </Modal.Body>

        <Modal.Footer>
          <div className="col-md-4 text-center">
            <button
              id="reject" type="button" className="btn btn-secondary" style={{width: '100%'}}
              disabled={loading}
              onClick={() => this.props.onCancel()}
            >
              Close
            </button>
          </div>
          <div className="col-md-4 text-center">
            <button
              id="reject" type="button" className="btn btn-success" style={{width: '100%'}}
              disabled={loading}
              onClick={() => this._handleClick()}
            >
              {buttonTitle}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

AdminModal.defaultProps = {
  loading: false,
  modalClass: 'process-company',
  buttonClassName: 'primary',
}
