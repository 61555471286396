import React from "react"

import Div from "../Div"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Email from "../../../utils/Email"
import Notify from "../../../utils/Notify"

export default class AccountSettings extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      data: AuthManager.currentUser,
    }
  }

  isFormValid(){
    let {
      data
    } = this.state

    let {
      first_name,
      last_name,
      email
    } = data.user

    let error = null

    if(!first_name || first_name === ""){
      error = "Please enter a valid first name"
    }
    else if(!last_name || last_name === ""){
      error = "Please enter a valid last name"
    }
    else if(!Email.isValid(email)){
      error = "Please enter a valid email"
    }

    if(error){
      Notify.error(error)
      return false

    }

    return true
  }

  _update(){
    let {
      data
    } = this.state

    if(!this.isFormValid()){
      return
    }

    this.setState({ loading: true })
    Backend.updateAccount(data)
    .then(user => {
      AuthManager.currentUser = user
      Notify.success('Your details have been changed successfully')
      this.setState({
        loading: false
      })
    })
    .catch(error => {
      this.setState({
        loading: false
      })
      Notify.error(error.message)
    })
  }

  _handleChange(e) {
    let data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  _handleChangeUser(e) {
    let data = this.state.data
    data.user[e.target.name] = e.target.value
    this.setState({ data, error: null })
  }

  render() {
    let {
      data,
      loading
    } = this.state

    return (
      <>
        <Div
          className="kt-wizard-v2__content"
          data-ktwizard-type="step-content"
          data-ktwizard-state="current"
          disabled={loading}
        >
        <div className="kt-heading kt-heading--md">Account Settings</div>
          <div className="kt-form__section kt-form__section--first">
            <div className="kt-wizard-v2__form">

              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="first_name"
                  value={data.user.first_name}
                  onChange={e => this._handleChangeUser(e)}
                />
              </div>

              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="last_name"
                  value={data.user.last_name}
                  onChange={e => this._handleChangeUser(e)}
                />
              </div>

              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={data.user.email}
                  disabled
                  onChange={e => this._handleChangeUser(e)}
                />
              </div>

            </div>
          </div>
        </Div>
        <div className="kt-form__actions pull-right">
          <button
            className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
            disabled={loading}
            onClick={() => this._update()}
          >
            Save Changes
          </button>
        </div>
      </>
    )
  }
}
