import React from "react"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

export default class CompanyItem extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      index: props.index,
      company: props.company,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let {
      index,
      company,
    } = this.state

    return (
      <>
        <Draggable
          key={company.id.toString()}
          draggableId={company.id.toString()}
          index={index}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                company,
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              <div
                className="kt-portlet kt-portlet--tabs kt-portlet--height-fluid pt-3 pl-3 pr-3 pb-3"
                style={{
                  backgroundColor: "transparent",
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: 5,
                  boxShadow: "unset",
                  margin: 0,
                  height: '100%'
                }}
              >
                <h4>{company.name}</h4>
              </div>
            </div>
          )}
        </Draggable>

      </>
    )
  }
}

const grid = 8;

const getItemStyle = (company, isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : company.image_card ? `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${company.image_card})` : 'transparent',
  backgroundColor: "transparent",
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: 5,
  border: '1px solid #ebedf2',
  margin: 0,
  height: '100%',
  // border: 1,

  // styles we need to apply on draggables
  ...draggableStyle
});
