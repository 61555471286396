import React from "react"

import moment from 'moment'

import BaseTable from "./BaseTable"
import ActionCell from "./cells/ActionCell";
import General from "../../../utils/General";
import PillCell from "./cells/PillCell";

const STATUSES = {
  name: {
    api: "status",
    display: "Status",
  },
  values: [{
      label: "All",
      value: null
    },
    {
      label: "Pending",
      value: "pending"
    },
    {
      label: "Processed",
      value: "processed"
    },
    {
      label: "Reconciled",
      value: 'reconciled'
    },
    {
      label: "Rejected",
      value: 'rejected'
    },
    {
      label: "Escalated",
      value: 'escalated'
    }
  ]
}


const ICON = (
  <g stroke="none" strokewidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <path d="M5.84026576,8 L18.1597342,8 C19.1999115,8 20.0664437,8.79732479 20.1528258,9.83390904 L20.8194924,17.833909 C20.9112219,18.9346631 20.0932459,19.901362 18.9924919,19.9930915 C18.9372479,19.9976952 18.8818364,20 18.8264009,20 L5.1735991,20 C4.0690296,20 3.1735991,19.1045695 3.1735991,18 C3.1735991,17.9445645 3.17590391,17.889153 3.18050758,17.833909 L3.84717425,9.83390904 C3.93355627,8.79732479 4.80008849,8 5.84026576,8 Z M10.5,10 C10.2238576,10 10,10.2238576 10,10.5 L10,11.5 C10,11.7761424 10.2238576,12 10.5,12 L13.5,12 C13.7761424,12 14,11.7761424 14,11.5 L14,10.5 C14,10.2238576 13.7761424,10 13.5,10 L10.5,10 Z" fill="#000000"></path>
      <path d="M10,8 L8,8 L8,7 C8,5.34314575 9.34314575,4 11,4 L13,4 C14.6568542,4 16,5.34314575 16,7 L16,8 L14,8 L14,7 C14,6.44771525 13.5522847,6 13,6 L11,6 C10.4477153,6 10,6.44771525 10,7 L10,8 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
  </g>
)

export default class ExternalDistributionTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      loading: true,
      status: props.status,
      statuses: STATUSES,
      selectedStatus: STATUSES[0],
      mode: props.mode,
      source: props.source,
      company: props.company
    }

    this.table = React.createRef()
  }

  refresh(){
    if(this.table && this.table.current && !this.state.showProcessModal){
      this.table.current.refresh()
    }
  }

  _getColumns() {
    let columns = [
      {
        Header: 'Date',
        id: 'created_at',
        accessor: job => moment(job.created_at).format('DD MMM YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Company',
        id: 'company.name',
        accessor: 'company.name',
      },
      {
        Header: 'Title',
        id: 'title',
        accessor: 'title',
      },
      // {
      //   Header: 'Status',
      //   id: 'status',
      //   sortable: false,
      //   exportable: false,
      //   Cell: rowInfo => {
      //     let job = rowInfo.original
      //     let text = General.snakeCaseToTitleCase(job.career_url.status)
      //     let className = job.career_url.status === "unverified" ? 'label-light-primary' : job.career_url.status === "rejected" ? 'label-light-danger' : 'label-light-success'
      //     return (
      //       <PillCell
      //         text={text}
      //         className={className}
      //       />
      //     )
      //   }
      // },
      {
        Header: 'Actions',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let job = rowInfo.original
          return (
            <ActionCell
              showModal={() => this.setState({selectedJob: job, showProcessModal: true})}
            />
          )
        }
      },
    ]
    return columns
  }

  render() {
    let {
      selectedJob,
      showProcessModal,
      status,
      source,
      company
    } = this.state

    const columns = this._getColumns()

    let endpoint = `${window.Api.CompanyExternalDistributions}`;

    if(company){
      endpoint = `${window.Api.Companies}/${company.id}/external-distributions`
    }

    let props = {

    }

    return (
      <>
        <BaseTable
          {...props}
          ref={this.table}
          endpoint={endpoint}
          className={''}
          title={ this.props.title }
          columns={columns}
          defaultSorted={[

          ]}
          showPagination={this.props.showPagination}
          showPaginationTop={this.props.showPaginationTop}
          showPaginationBottom={this.props.showPaginationBottom}
          exportButtonsEnabled={false}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? 'visible' : 'hidden'
              }
            }
          }}
          getTheadThProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                textAlign: 'left',
                paddingLeft: 10,
                visibility: column.Header === "Actions" ? 'hidden' : 'visible',
              }
            }
          }}
        />
      </>
    )
  }
}

ExternalDistributionTable.defaultProps = {
  mode: null
}
