import React from 'react'

export default class ActionCell extends React.Component {
  constructor(props){
    super(props)

    this.state = {

    }
  }

  render() {

    return (
      <>
        {
          this.props.view &&
          <a href="#" className="btn btn-sm btn-icon btn-outline-primary mr-2"
             onClick={e => {
               e.preventDefault()
               this.props.onViewClicked()
             }}
          >
            <i className="la la-eye"></i>
          </a>
        }

        {
          this.props.edit &&
          <a href="#" className="btn btn-sm btn-icon btn-outline-primary mr-2"
             onClick={e => {
               e.preventDefault()
               this.props.onEditClicked()
             }}
          >
            <i className="la la-pencil"></i>
          </a>
        }

        {
          this.props.add &&
          <a href="#" className="btn btn-sm btn-icon btn-outline-primary mr-2"
             onClick={e => {
               e.preventDefault()
               this.props.onAddClicked()
             }}
          >
            <i className="la la-plus"></i>
          </a>
        }

        {
          this.props.stats &&
          <a href="#" className="btn btn-sm btn-icon btn-outline-primary mr-2"
             onClick={e => {
               e.preventDefault()
               this.props.onStatsClicked()
             }}
          >
            <i className="la la-bar-chart"></i>
          </a>
        }

        {
          this.props.delete &&
          <a href="#" className="btn btn-sm btn-icon btn-outline-primary mr-2"
             onClick={e => {
               e.preventDefault()
               this.props.onDeleteClicked()
             }}
          >
            <i className="la la-trash"></i>
          </a>
        }
      </>
    )
  }
}

ActionCell.defaultProps = {
  view: false,
  edit: false,
  add: false,
  delete: false
}
