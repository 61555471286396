import React from 'react'

import Card from "../Card";
import ImageEdit from "../common/ImageEdit";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AddressInput from "../common/AddressInput";
import SmartList from "../common/SmartList";
import BlockEntry from "./BlockEntry";
import General from "../../../utils/General";

const LABELS = {
  '1' : {
    'text_color': 'Title Color',
    'tertiary_color': 'Subtitle Color',
    'background_color': 'Background Color'
  },
  '2' : {
    'background_image': 'Background Image',
    'text_color': 'Text Color',
    'primary_color': 'Primary CTA Color',
    'secondary_color': 'Secondary CTA Color',
    'primary_cta_text': 'Primary CTA Text',
    'secondary_cta_text': 'Secondary CTA Text',
    'primary_cta_link': 'Primary CTA Link',
    'secondary_cta_link': 'Secondary CTA Link'
  },
  '3' : {
    'background_color': 'Background Color',
    'primary_color': 'Job Card Color',
    'tertiary_color': 'View More Text Color'
  },
  '4' : {
    'background_color': 'Background Color',
    'primary_color': 'Primary CTA Color'
  },
  '5' : {
    'background_color': 'Background Color',
    'primary_color': 'Primary CTA Color',
    'primary_cta_text': 'Primary CTA Text',
    'primary_cta_link': 'Primary CTA Link'
  },
  '6' : {
    'background_color': 'Background Color',
    'text_color': 'Text Color',
    'primary_color': 'Primary CTA Color',
    'primary_cta_text': 'Primary CTA Text',
    'tertiary_color': 'Bottom Border Color'
  },
  '7' : {
    'background_color': 'Background Color',
    'text_color': 'Text Color'
  },
  '8' : {
    'background_image': 'Background Image',
    'foreground_image': 'Foreground Image',
    'text_color': 'Text Color',
    'primary_color': 'Primary CTA Color',
    'primary_cta_text': 'Primary CTA Text',
    'primary_cta_link': 'Primary CTA Link'
  },
  '9' : {
    'text_color': 'Title Color',
    'tertiary_color': 'Subtitle Color',
    'background_color': 'Background Color'
  },
  '10' : {
    'background_color': 'Background Color'
  },
  '11' : {
    'background_color': 'Background Color'
  },
  '12' : {
    'background_color': 'Background Color'
  },
  '13' : {
    'background_color': 'Background Color',
    'primary_color': 'Line Color',
    'text_color': 'Title Color',
    'tertiary_color': 'Text Color',
  },
  '14' : {
    'background_color': 'Background Color',
    'primary_color': 'Active Tab Color'
  },
  '15' : {
    'text_color': 'Title Color',
    'tertiary_color': 'Subtitle Color',
    'background_color': 'Background Color',
  },
  '16' : {
    'text_color': 'Title Color',
    'tertiary_color': 'Subtitle Color',
    'background_color': 'Background Color',
  },
  '17' : {
    'background_color': 'Background Color',
    'text_color': 'Text Color'
  },
  '18' : {
    'text_color': 'Title Color',
    'tertiary_color': 'Text Color',
    'background_color': 'Background Color'
  }
}

export default class Block extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expand: props.expand || false,
      contentLinks: props.contentLinks,
      contentBlock: props.contentBlock,
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({contentBlock: nextProps.contentBlock, contentLinks: nextProps.contentLinks})
  }

  _handleClick(){
    let {
      contentBlock
    } = this.state

    if(contentBlock.id){
      this._update(contentBlock)
    }else{
      this._add(contentBlock)
    }
  }

  _handleDelete(){
    let {
      contentBlock
    } = this.state

    if(contentBlock.id){
      this._delete(contentBlock)
    }else{
      this.props.onDelete()
      Notify.success("Block Deleted!")
    }
  }

  _isValid(contentBlock){
    let error = null;

    if(contentBlock.block.id === 1){
      if(!contentBlock.title){
        error = "Please enter a title"
      }else if(!contentBlock.text_color){
        error = "Please enter a title color"
      }else if(!contentBlock.background_color){
        error = "Please enter a background color"
      }else if(!contentBlock.tertiary_color){
        error = "Please enter a subtitle color"
      }
    }else if(contentBlock.block.id === 2){
      if(!contentBlock.title){
        error = "Please enter a title"
      }else if(!contentBlock.background_image && !General.isValidVideoUrl(contentBlock.video)){
        error = "Please include a background image or video link"
      }else if(!contentBlock.overlay){
        error = "Please enter the overlay"
      }else if(!contentBlock.height){
        error = "Please enter the height"
      }else if(!contentBlock.text_color){
        error = "Please enter a title color"
      }else if(!contentBlock.primary_cta_text){
        error = "Please enter a primary cta text"
      }else if(!contentBlock.secondary_cta_text){
        error = "Please enter a secondary cta text"
      }else if(!contentBlock.primary_cta_link){
        error = "Please enter a primary cta link"
      }else if(!contentBlock.secondary_cta_link){
        error = "Please enter a secondary cta link"
      }else if(!contentBlock.primary_color){
        error = "Please enter a primary color"
      }else if(!contentBlock.secondary_color){
        error = "Please enter a secondary color"
      }
    }else if(contentBlock.block.id === 3){
      if(!contentBlock.pagination){
        error = "Please enter the number of jobs to paginate by"
      }else if(!contentBlock.background_color){
        error = "Please enter a background color"
      }else if(!contentBlock.primary_color){
        error = "Please enter a job card color"
      }else if(!contentBlock.tertiary_color){
        error = "Please enter a view more text color"
      }
    }else if(contentBlock.block.id === 4){

    }else if(contentBlock.block.id === 5){
      if(!contentBlock.background_color){
        error = "Please enter a background color"
      }

      if(!error){
        if(contentBlock.block_content_entry?.length > 0){
          contentBlock.block_content_entry.map((contentBlockEntry, index) => {
            let contentNumber = General.toOrdinal(index+1)
            if(!contentBlockEntry.title){
              error = `Please enter the title for the ${contentNumber} entry`
            }else if(!contentBlockEntry.text){
              error = `Please enter the text for the ${contentNumber} entry`
            }else if(!contentBlockEntry.icon){
              error = `Please enter the icon for the ${contentNumber} entry`
            }else if(!contentBlockEntry.primary_color){
              error = `Please enter the primary cta color for the ${contentNumber} entry`
            }else if(!contentBlockEntry.primary_cta_text){
              error = `Please enter the primary cta text for the ${contentNumber} entry`
            }else if(!contentBlockEntry.primary_cta_link){
              error = `Please enter the primary cta link for the ${contentNumber} entry`
            }
          })
        }else{
          error = 'Please add the content entries'
        }
      }
    }else if(contentBlock.block.id === 6){
      if(!contentBlock.title){
        error = "Please enter a title"
      }else if(!contentBlock.video){
        error = "Please enter the video URL"
      }else if(!contentBlock.background_color){
        error = "Please enter a background color"
      }else if(!contentBlock.text_color){
        error = "Please enter a text color"
      }else if(!contentBlock.primary_color){
        error = "Please enter a primary cta color"
      }else if(!contentBlock.primary_cta_text){
        error = "Please enter the primary cta text"
      }else if(!contentBlock.tertiary_color){
        error = "Please enter the bottom border color"
      }
    }else if(contentBlock.block.id === 7){
      if(!contentBlock.title){
        error = "Please enter a title"
      }else if(!General.isValidVideoUrl(contentBlock.video)){
        error = "Please enter a valid video URL"
      }else if(!contentBlock.background_color){
        error = "Please enter a background color"
      }else if(!contentBlock.text_color){
        error = "Please enter a text color"
      }
    }else if(contentBlock.block.id === 8){
      if(!contentBlock.title){
        error = "Please enter a title"
      }else if(!contentBlock.text){
        error = "Please enter a text"
      }else if(!General.isValidVideoUrl(contentBlock.video)){
        error = "Please enter a valid video URL"
      }else if(!contentBlock.overlay){
        error = "Please enter the overlay"
      }else if(!contentBlock.background_image){
        error = "Please enter a background image"
      }else if(!contentBlock.foreground_image){
        error = "Please enter a foreground image"
      }else if(!contentBlock.primary_cta_text){
        error = "Please enter a primary cta text"
      }else if(!contentBlock.primary_cta_link){
        error = "Please enter a primary cta link"
      }else if(!contentBlock.text_color){
        error = "Please enter a text color"
      }else if(!contentBlock.primary_color){
        error = "Please enter a primary cta color"
      }
    }else if(contentBlock.block.id === 9){
      if(!contentBlock.text_color){
        error = "Please enter the text color"
      }else if(!contentBlock.background_color){
        error = "Please enter a background color"
      }else if(!contentBlock.tertiary_color){
        error = "Please enter a subtitle color"
      }

      if(!error){
        if(contentBlock.block_content_entry?.length > 0){
          contentBlock.block_content_entry.map((contentBlockEntry, index) => {
            let contentNumber = General.toOrdinal(index+1)
            if(!contentBlockEntry.title){
              error = `Please enter the title for the ${contentNumber} entry`
            }else if(!contentBlockEntry.subtitle){
              error = `Please enter the subtitle for the ${contentNumber} entry`
            }else if(!contentBlockEntry.image){
              error = `Please enter the image for the ${contentNumber} entry`
            }
          })
        }else{
          error = 'Please add the content entries'
        }
      }
    }else if(contentBlock.block.id === 10){
      if(contentBlock.block_content_entry?.length > 0){
        contentBlock.block_content_entry.map((contentBlockEntry, index) => {
          let contentNumber = General.toOrdinal(index+1)
          if(!contentBlockEntry.title){
            error = `Please enter the title for the ${contentNumber} entry`
          }else if(!contentBlockEntry.subtitle){
            error = `Please enter the subtitle for the ${contentNumber} entry`
          }else if(!contentBlockEntry.image){
            error = `Please enter the image for the ${contentNumber} entry`
          }
        })
      }else{
        error = 'Please add the content entries'
      }
    }else if(contentBlock.block.id === 11){
      if(!contentBlock.background_color){
        error = "Please enter a background color"
      }

      if(!error){
        if(contentBlock.block_content_entry?.length > 0){
          contentBlock.block_content_entry.map((contentBlockEntry, index) => {
            let contentNumber = General.toOrdinal(index+1)
            if(!contentBlockEntry.title){
              error = `Please enter the title for the ${contentNumber} entry`
            }else if(!contentBlockEntry.subtitle){
              error = `Please enter the subtitle for the ${contentNumber} entry`
            }else if(!contentBlockEntry.text){
              error = `Please enter the text for the ${contentNumber} entry`
            }
          })
        }else{
          error = 'Please add the content entries'
        }
      }
    }else if(contentBlock.block.id === 12){
      if(!contentBlock.social_type){
        error = "Please select social type"
      }if(!contentBlock.social_link){
        error = "Please enter the social link"
      }else if(!contentBlock.background_color){
        error = "Please enter a background color"
      }
    }else if(contentBlock.block.id === 13){
      if(!contentBlock.text_color){
        error = "Please enter a text_color"
      }else if(!contentBlock.background_color){
        error = "Please enter a background color"
      }else if(!contentBlock.primary_color){
        error = "Please enter a line color"
      }else if(!contentBlock.tertiary_color){
        error = "Please enter a text color"
      }

      if(!error){
        if(contentBlock.block_content_entry?.length > 0){
          contentBlock.block_content_entry.map((contentBlockEntry, index) => {
            let contentNumber = General.toOrdinal(index+1)
            if(!contentBlockEntry.title){
              error = `Please enter the title for the ${contentNumber} entry`
            }else if(!contentBlockEntry.text){
              error = `Please enter the text for the ${contentNumber} entry`
            }
          })
        }else{
          error = 'Please add the content entries'
        }
      }
    }else if(contentBlock.block.id === 14){
      if(!contentBlock.background_color){
        error = "Please enter a background color"
      }else if(!contentBlock.primary_color){
        error = "Please enter the active tab color"
      }

      if(!error){
        if(contentBlock.block_content_entry?.length > 0){
          contentBlock.block_content_entry.map((contentBlockEntry, index) => {
            let contentNumber = General.toOrdinal(index+1)
            if(!contentBlockEntry.title){
              error = `Please enter the title for the ${contentNumber} entry`
            }else if(!contentBlockEntry.text){
              error = `Please enter the text for the ${contentNumber} entry`
            }
          })
        }else{
          error = 'Please add the content entries'
        }
      }
    }else if(contentBlock.block.id === 15){
      if(!contentBlock.text_color){
        error = "Please enter a text color"
      }else if(!contentBlock.background_color){
        error = "Please enter a background color"
      }else if(!contentBlock.tertiary_color){
        error = "Please enter a subtitle color"
      }

      if(!error){
        if(contentBlock.block_content_entry?.length > 0){
          contentBlock.block_content_entry.map((contentBlockEntry, index) => {
            let contentNumber = General.toOrdinal(index+1)
            if(!contentBlockEntry.title){
              error = `Please enter the title for the ${contentNumber} entry`
            }else if(!contentBlockEntry.text){
              error = `Please enter the text for the ${contentNumber} entry`
            }else if(!General.isValidVideoUrl(contentBlockEntry.video) && !contentBlockEntry.image){
              error = `Please enter an image or a valid video for the ${contentNumber} entry`
            }
          })
        }else{
          error = 'Please add the content entries'
        }
      }
    }else if(contentBlock.block.id === 16){
      if(!contentBlock.text_color){
        error = "Please enter a text color"
      }else if(!contentBlock.background_color){
        error = "Please enter a background color"
      }else if(!contentBlock.tertiary_color){
        error = "Please enter a subtitle color"
      }

      if(!error){
        if(contentBlock.block_content_entry?.length > 0){
          contentBlock.block_content_entry.map((contentBlockEntry, index) => {
            let contentNumber = General.toOrdinal(index+1)
            if(!contentBlockEntry.title){
              error = `Please enter the title for the ${contentNumber} entry`
            }else if(!contentBlockEntry.text){
              error = `Please enter the text for the ${contentNumber} entry`
            }else if(!General.isValidVideoUrl(contentBlockEntry.video) && !contentBlockEntry.image){
              error = `Please enter an image or a valid video for the ${contentNumber} entry`
            }
          })
        }else{
          error = 'Please add the content entries'
        }
      }
    }else if(contentBlock.block.id === 17){
      if(!contentBlock.title){
        error = "Please enter a title"
      }else if(!contentBlock.text){
        error = "Please enter a text"
      }else if(!contentBlock.image){
        error = "Please enter an image"
      }else if(!contentBlock.text_color){
        error = "Please enter a text color"
      }else if(!contentBlock.background_color){
        error = "Please enter a background color"
      }
    }

    if(error){
      Notify.error(error)
    }

    return !error;
  }

  _add(contentBlock){
    if(!this._isValid(contentBlock)){
      return
    }

    if(contentBlock.block.id === 3){
      contentBlock.job_location_filter = true
      contentBlock.job_type_filter = true
    }

    Backend.addBlock(contentBlock).then(contentBlock => {
      Notify.success("Block Added")
      this.setState({expand: !this.state.expand, contentBlock})
    }).catch(e => Notify.error(e.message))
  }

  _update(contentBlock){
    if(!this._isValid(contentBlock)){
      return
    }

    Backend.updateBlock(contentBlock).then(contentBlock => {
      Notify.success("Block Updated")
      this.setState({expand: !this.state.expand, contentBlock})
    }).catch(e => Notify.error(e.message))
  }

  _delete(contentBlock){
    Backend.deleteBlock(contentBlock).then(() => {
      Notify.success("Block Deleted")
      this.props.onDelete()
    }).catch(e => Notify.error(e.message))
  }

  _uploadImageFile(file, attribute){
    let {
      contentBlock
    } = this.state

    this.setState({ loading: true })
    Backend.uploadImage(file, "company")
      .then(image => {
        contentBlock[attribute] = image.image_url
        this.setState({ loading: false, contentBlock })
      })
      .catch(error => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _renderInput(title, attribute, type, size="col-md-6"){
    let {
      contentBlock
    } = this.state

    title = LABELS[contentBlock.block.id][attribute] || title

    return (
      <div className={size}>
        <label>{title}</label>
        <input
          className="form-control"
          type={type}
          value={contentBlock[attribute]}
          onChange={e => {
            contentBlock[attribute] = e.target.value
            this.setState({contentBlock})
          }}
        />
      </div>
    )
  }

  render() {
    let {
      expand,
      contentLinks,
      contentBlock,
      selectedPrimaryLinkType,
      selectedSecondaryLinkType
    } = this.state

    selectedPrimaryLinkType = contentBlock.primary_cta_link?.includes("section-") ? 'block' : 'link'
    selectedSecondaryLinkType = contentBlock.secondary_cta_link?.includes("section-") ? 'block' : 'link'

    let buttonTitle = contentBlock.id ? "Update" : "Add"

    return (
      <>
        <Card
          title={contentBlock.block.name}
          visible={expand}
          renderToolbar={() => {
            return (
              <div className="mt-auto mb-auto">
                <a
                  className="btn btn-danger ml-3 pull-right"
                  href="javascript:;"
                  onClick={() => this._handleDelete()}
                >
                  Delete
                </a>
                <a
                  className="btn btn-primary ml-3 pull-right"
                  href="javascript:;"
                  onClick={() => this.setState({expand: !expand})}
                >
                  Edit
                </a>
              </div>
            )
          }}
        >
          {
            contentBlock.block.title &&
            <div className="form-group row">
              {this._renderInput("Title", "title", "text", "col-md-12")}
            </div>
          }
          {
            contentBlock.block.subtitle &&
            <div className="form-group row">
              {this._renderInput("Subtitle", "subtitle", "text", "col-md-12")}
            </div>
          }
          {
            contentBlock.block.text &&
            <div className="form-group row">
              <div className={"col-md-12"}>
                <label>Text</label>
                <textarea
                  className="form-control"
                  rows={3}
                  value={contentBlock.text}
                  onChange={e => {
                    contentBlock.text = e.target.value
                    this.setState({contentBlock})
                  }}
                />
              </div>
            </div>
          }
          {
            contentBlock.block.video &&
            <div className="form-group row">
              {this._renderInput("Video", "video", "text", "col-md-12")}
            </div>
          }
          {
            contentBlock.block.location &&
            <div className="form-group row">
              <div className={"col-md-12"}>
                <label>Location</label>
                <AddressInput
                  address={contentBlock.location}
                  onUpdated={addressInput => {
                    let address = JSON.parse(JSON.stringify(addressInput))
                    address.address   = addressInput.line_1
                    address.city    = addressInput.city.name
                    address.state   = addressInput.city.state.name
                    address.country = addressInput.city.state.country.name
                    address.country_short = addressInput.city.state.country.short_code
                    address.latitude = addressInput.latitude.toFixed(7)
                    address.longitude = addressInput.longitude.toFixed(7)

                    contentBlock.location = address
                    contentBlock = {...contentBlock, ...address}
                    this.setState({ contentBlock })
                  }}
                />
              </div>
            </div>
          }
          {
            contentBlock.block.social &&
            <div className="form-group row">
              <div className={"col-md-6"}>
                <label>Social Type</label>
                <select
                  className="form-control"
                  value={contentBlock.social_type}
                  onChange={e => {
                    contentBlock.social_type = e.target.value
                    this.setState({contentBlock})
                  }}
                >
                  <option value="">Select Type</option>
                  <option value="twitter">Twitter</option>
                  <option value="instagram">Instagram</option>
                </select>
              </div>
              {this._renderInput("Social Link", "social_link", "text")}
            </div>
          }
          {
            contentBlock.block.primary_cta_text &&
            <div className="form-group row">
              {this._renderInput("Primary CTA", "primary_cta_text", "text", "col-md-12")}
            </div>
          }
          {
            contentBlock.block.primary_cta_link &&
            <div className="form-group row">
              <div className={"col-md-6"}>
                <label>Link Type</label>
                <select
                  className="form-control"
                  value={selectedPrimaryLinkType}
                  onChange={e => {
                    selectedPrimaryLinkType = e.target.value
                    if(selectedPrimaryLinkType === "link"){
                      contentBlock.primary_cta_link = ""
                    }else{
                      contentBlock.primary_cta_link = "section-"
                    }
                    this.setState({contentBlock, selectedPrimaryLinkType})
                  }}
                >
                  <option value="link">Link</option>
                  <option value="block">Block</option>
                </select>
              </div>
              {
                selectedPrimaryLinkType === "link" ?
                  this._renderInput("Link", "primary_cta_link", "text") :
                  <div className={"col-md-6"}>
                    <label>Link</label>
                    <select
                      className="form-control"
                      value={contentBlock.primary_cta_link}
                      onChange={e => {
                        contentBlock.primary_cta_link = e.target.value
                        this.setState({contentBlock})
                      }}
                    >
                      <option value="">Select Block</option>
                      {
                        contentLinks.map(contentLink => (
                          <option value={`section-${contentLink.value}`}>{contentLink.name}</option>
                        ))
                      }
                    </select>
                  </div>
              }
            </div>
          }
          {
            contentBlock.block.secondary_cta_text &&
            <div className="form-group row">
              {this._renderInput("Secondary CTA", "secondary_cta_text", "text", "col-md-12")}
            </div>
          }
          {
            contentBlock.block.secondary_cta_link &&
            <div className="form-group row">
              <div className={"col-md-6"}>
                <label>Link Type</label>
                <select
                  className="form-control"
                  value={selectedSecondaryLinkType}
                  onChange={e => {
                    selectedSecondaryLinkType = e.target.value
                    if(selectedSecondaryLinkType === "link"){
                      contentBlock.secondary_cta_link = ""
                    }else{
                      contentBlock.secondary_cta_link = "section-"
                    }
                    this.setState({contentBlock, selectedSecondaryLinkType})
                  }}
                >
                  <option value="link">Link</option>
                  <option value="block">Block</option>
                </select>
              </div>
              {
                selectedSecondaryLinkType === "link" ?
                  this._renderInput("Link", "secondary_cta_link", "text") :
                  <div className={"col-md-6"}>
                    <label>Link</label>
                    <select
                      className="form-control"
                      value={contentBlock.secondary_cta_link}
                      onChange={e => {
                        contentBlock.secondary_cta_link = e.target.value
                        this.setState({contentBlock})
                      }}
                    >
                      <option value="">Select Block</option>
                      {
                        contentLinks.map(contentLink => (
                          <option value={`section-${contentLink.value}`}>{contentLink.name}</option>
                        ))
                      }
                    </select>
                  </div>
              }
            </div>
          }
          {
            contentBlock.block.image &&
            <div className="form-group">
              <label>Image</label>
              <ImageEdit
                image={contentBlock.image}
                type={"avatar"}
                hidePreview={false}
                deletable={true}
                uploadImageFile={file => this._uploadImageFile(file, "image")}
                onDeleteClicked={() => {
                  contentBlock.image = null
                  this.setState({contentBlock})
                }}
              />
            </div>
          }
          {
            contentBlock.block.foreground_image &&
            <div className="form-group">
              <label>Foreground Image</label>
              <ImageEdit
                image={contentBlock.foreground_image}
                type={"banner"}
                hidePreview={false}
                deletable={true}
                uploadImageFile={file => this._uploadImageFile(file, "foreground_image")}
                onDeleteClicked={() => {
                  contentBlock.foreground_image = null
                  this.setState({contentBlock})
                }}
              />
            </div>
          }
          {
            contentBlock.block.background_image &&
            <div className="form-group">
              <label>Background Image</label>
              <ImageEdit
                image={contentBlock.background_image}
                type={"banner"}
                hidePreview={false}
                deletable={true}
                uploadImageFile={file => this._uploadImageFile(file, "background_image")}
                onDeleteClicked={() => {
                  contentBlock.background_image = null
                  this.setState({contentBlock})
                }}
              />
            </div>
          }
          {
            contentBlock.block.id === 3 &&
            <div className="form-group row">
              {this._renderInput("Pagination", "pagination", "number", "col-md-12")}
            </div>
          }
          {
            (
              contentBlock.block.id === 5 || contentBlock.block.id === 9 || contentBlock.block.id === 10 ||
              contentBlock.block.id === 11 || contentBlock.block.id === 13 || contentBlock.block.id === 14 ||
              contentBlock.block.id === 15 || contentBlock.block.id === 16 || contentBlock.block.id === 18
            ) &&
            <SmartList
              items={contentBlock.block_content_entry}
              renderItem={(entry, index) => (
                <BlockEntry
                  contentLinks={contentLinks}
                  contentBlock={contentBlock}
                  contentBlockEntry={entry}
                  onUpdated={updatedEntry => {
                    contentBlock.block_content_entry[index] = updatedEntry
                    this.setState({contentBlock})
                  }}
                />
              )}
              onAddItemPressed={() => {
                if(!contentBlock.block_content_entry){
                  contentBlock.block_content_entry = []
                }
                contentBlock.block_content_entry.push({})
                this.setState({contentBlock})
              }}
              onUpdated={items => {
                contentBlock.block_content_entry = items
                this.setState({contentBlock})
              }}
            />
          }
          <div className="form-group row">
            {
              contentBlock.block.overlay &&
              this._renderInput("Overlay", "overlay", "number")
            }
            {
              contentBlock.block.height &&
              this._renderInput("Height", "height", "number")
            }
          </div>
          {
            (contentBlock.block.text_color || contentBlock.block.background_color || contentBlock.block.tertiary_color ||
            contentBlock.block.primary_color || contentBlock.block.secondary_color) &&
            <h3>Colors</h3>
          }
          {
            contentBlock.block.text_color &&
            <div className="form-group row">
              {this._renderInput("Text Colour", "text_color", "color", "col-md-12")}
            </div>
          }
          {
            contentBlock.block.background_color &&
            <div className="form-group row">
              {this._renderInput("Background Colour", "background_color", "color", "col-md-12")}
            </div>
          }
          {
            contentBlock.block.tertiary_color &&
            <div className="form-group row">
              {this._renderInput("Tertiary Color", "tertiary_color", "color", "col-md-12")}
            </div>
          }
          <div className="form-group row">
            {
              contentBlock.block.primary_color &&
              this._renderInput("Primary Colour", "primary_color", "color")
            }
            {
              contentBlock.block.secondary_color &&
              this._renderInput("Secondary Colour", "secondary_color", "color")
            }
          </div>
          <h3>Padding/Margin</h3>
          <div className="form-group row">
            {this._renderInput("Padding Top (px)", "padding_top", "number")}
            {this._renderInput("Padding Bottom (px)", "padding_bottom", "number")}
          </div>
          <div className="form-group row">
            {this._renderInput("Margin Top (px)", "margin_top", "number")}
            {this._renderInput("Margin Bottom (px)", "margin_bottom", "number")}
          </div>
          <div className="form-group float-right">
            <div className="text-center">
              <button
                id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
                onClick={() => this._handleClick()}
              >
                {buttonTitle}
              </button>
            </div>
          </div>
        </Card>
      </>
    )
  }

}
