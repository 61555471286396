import React from "react"

import ContentLoader from "react-content-loader";

import Backend from '../../../utils/Backend'
import Notify from "../../../utils/Notify"

export default class ImageEdit extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      image: props.image,
      showImagePicker: false,
      imagePicker: props.imagePicker,
      aspectRatio: props.aspectRatio
    }

    this.imageCropperModal = React.createRef()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _onImageChange(e) {

    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);
    let file = filesArr[0]

    if(!this.props.cropImage){
      this.props.uploadImageFile(file)
      return
    }

    let url = URL.createObjectURL(file)
    this._setupCropper(url, file.type, file.name)
  }

  _setupCropper(url, type, name){
    let {
      aspectRatio
    } = this.state
    this.imageCropperModal.current.cropImage(url, type, name, {
      aspectRatio
    })
  }

  _uploadImageFile(file){
    this.setState({ loading: true })
    Backend.uploadImage(file)
      .then(image => {
        this.setState({ loading: false })
        this.props.onUpdated(image)
      })
      .catch(error => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _renderContent(imageStyle, loading){
    if(loading){
      return this._renderLoading(imageStyle)
    }

    let { imagePicker } = this.state

    let icon = this.state.image ? "fa-pen" : "fa-plus"
    return (
      <>
        { !this.props.hidePreview &&
        <div className="kt-avatar__holder bg-pos cursor-pointer" style={imageStyle}
           onClick={e => {
             if(imagePicker){
               e.preventDefault()
               this.setState({ showImagePicker: true })
             }
           }}
        ></div>
        }
        <label
          className="kt-avatar__upload"
          data-toggle="kt-tooltip"
          title=""
          data-original-title="Change logo"
          style={this.props.labelStyle}
          onClick={e => {
            if(imagePicker){
              e.preventDefault()
              this.setState({ showImagePicker: true })
            }
          }}
        >
          <i className={`fa ${icon}`}></i>
          <input type="file" accept=".png, .jpg, .jpeg" onChange={e => this._onImageChange(e)} />
        </label>
        { (this.props.deletable && this.state.image) &&
        <span
          className="kt-avatar__cancel"
          style={{ display: 'block' }}
          data-toggle="kt-tooltip"
          title=""
          data-original-title="Cancel avatar"
          onClick={e => {
            this.props.onDeleteClicked()
          }}
        >
            <i className="fa fa-times" style={{ marginLeft: 7, marginTop: 7, color: 'red'}}></i>
          </span>
        }
      </>
    )
  }

  _renderLoading(imageStyle){
    let style = { ...imageStyle }

    if(this.props.hidePreview){
      return null
    }

    if(style.height == 120){
      style.height += 2
    }
    else{
      style.height += 6
    }

    return (
      <ContentLoader speed={0.5} style={style}>
        <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
      </ContentLoader>
    )
  }

  render() {

    let {
      image,
      loading,
      showImagePicker
    } = this.state

    let url = image ? image : null

    let imageStyle = {
      backgroundColor: "#F3F6F9",
      backgroundImage: `url("${url}")`
    }
    let imageContainerStyle = {
      margin: '0 auto'
    }

    imageStyle.width = 120

    if (this.props.aspectRatio) {
      imageStyle.width = (this.props.aspectRatio) * (imageStyle.width);
    }

    imageStyle.height = 120

    if(this.props.type === "banner"){
      imageStyle.width = '100%'
      imageStyle.height = 160
      imageContainerStyle.width = '100%'
    }

    let shape = ""
    if(this.props.type === "avatar"){
      shape = "kt-avatar--circle"
    }

    if(loading){
      imageStyle.backgroundImage = null
    }

    return (
      <>
        <>
          <div className="form-group">
            <div className={`kt-avatar kt-avatar--outline `+shape} style={imageContainerStyle}>
              { this._renderContent(imageStyle, loading) }
            </div>
          </div>
        </>
      </>
    )
  }
}

ImageEdit.defaultProps = {
  type: "photo",
  deletable: false,
  hidePreview: true,
  imagePicker: false,
  cropImage: false,
  aspectRatio: 1,
}
