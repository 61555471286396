import React from 'react'
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import ImageEdit from "../common/ImageEdit";

export default class Edit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {
    this._load()
  }

  componentWillReceiveProps(nextProps) {
    // this.setState(this._getState(nextProps), () => this._load())
  }

  _getState(props) {
    return {
      ...props,
      channel: props.channel,
    }
  }

  _load(){
    Backend.getChannel(this.state.channel).then(channel => {
      this.setState({channel})
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _uploadImageFile(file, attribute){
    let {
      channel
    } = this.state

    this.setState({ loading: true })
    Backend.uploadImage(file, "company")
      .then(image => {
        channel[attribute] = image.image_url
        this.setState({ loading: false, channel })
      })
      .catch(error => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _update(){
    let {
      channel
    } = this.state

    Backend.updateChannel(channel).then(e => {
      Notify.success("Company Updated")
    }).catch(e => Notify.error(e.message))
  }

  render() {
    let {
      channel
    } = this.state

    return (
      <>
        <div className="form-group">
          <label>Logo</label>
          <ImageEdit
            image={channel.logo}
            type={"avatar"}
            hidePreview={false}
            deletable={true}
            uploadImageFile={file => this._uploadImageFile(file, "logo")}
            onDeleteClicked={() => {
              channel.logo = null
              this.setState({channel})
            }}
          />
        </div>
        <div className="form-group">
          <label>Banner</label>
          <ImageEdit
            image={channel.banner}
            type={"banner"}
            hidePreview={false}
            deletable={true}
            uploadImageFile={file => this._uploadImageFile(file, "banner")}
            onDeleteClicked={() => {
              channel.banner = null
              this.setState({channel})
            }}
          />
        </div>
        <div className="form-group">
          <label>Card</label>
          <ImageEdit
            image={channel.card}
            type={"banner"}
            hidePreview={false}
            deletable={true}
            uploadImageFile={file => this._uploadImageFile(file, "card")}
            onDeleteClicked={() => {
              channel.card = null
              this.setState({channel})
            }}
          />
        </div>
        <div className="form-group float-right">
          <div className="text-center">
            <button
              id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
              onClick={() => this._update()}
            >
              Update
            </button>
          </div>
        </div>
      </>
    )
  }
}
