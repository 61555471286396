import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.Api = {};

window.Api.Base = env.REACT_APP_API_BASE

window.Api.User = {};

window.Api.User.Base = window.Api.Base + "/user"

window.Api.User.Login                 = window.Api.User.Base + "/login"
window.Api.User.Logout                = window.Api.User.Base + "/logout"
window.Api.User.Info                  = window.Api.User.Base + "s"
window.Api.User.RequestResetPassword  = window.Api.User.Base + "/request-reset-password"
window.Api.User.ResetPassword         = window.Api.User.Base + "/reset-password"
window.Api.User.RefreshToken          = window.Api.User.Base + "/login/refresh"

window.Api.Register = window.Api.Base + "/login"

window.Api.Jobs                            = window.Api.Base + "/jobs"
window.Api.JobTypes                        = window.Api.Base + "/jobs/jobtypes"
window.Api.Applications                    = window.Api.Base + "/admin/applications"
window.Api.Companies                       = window.Api.Base + "/admin/companies"
window.Api.CompanyMembers                  = window.Api.Base + "/admin/companies/members"
window.Api.CompanyExternalDistributions    = window.Api.Base + "/admin/companies/external-distributions"
window.Api.Talent                          = window.Api.Base + "/admin/talent"
window.Api.Channels                        = window.Api.Base + "/channels"
window.Api.AdminChannels                   = window.Api.Base + "/admin/channels"
window.Api.Packages                        = window.Api.Base + "/packages"
window.Api.Coupons                         = window.Api.Base + "/coupons"
window.Api.BlogPosts                       = window.Api.Base + "/blogposts"
window.Api.Articles                        = window.Api.Base + "/articles"
window.Api.Admins                          = window.Api.Base + "/admin"
window.Api.Categories                      = window.Api.Base + "/categories"
window.Api.Blocks                          = window.Api.Base + "/blocks"
window.Api.Images                          = window.Api.Base + "/images"
window.Api.Exports                         = window.Api.Base + "/admin/exports"
window.Api.SourceStats                     = window.Api.Base + "/stats/source"
window.Api.WidgetStats                     = window.Api.Base + "/stats/widgets"
window.Api.JobsFilters                     = window.Api.Base + "/admin/jobs/filters"
window.Api.CompanyFilters                  = window.Api.Base + "/admin/companies/filters"
window.Api.ApplicationFilters              = window.Api.Base + "/admin/applications/filters"
window.Api.ExportJobs                      = window.Api.Base + "/admin/exports/jobs"
window.Api.ExportCompanies                 = window.Api.Base + "/admin/exports/companies"
window.Api.ExportApplications              = window.Api.Base + "/admin/exports/applications"
window.Api.ExportTalent                    = window.Api.Base + "/admin/exports/talent"
window.Api.ExportSourceStats               = window.Api.Base + "/admin/exports/source-stats"
window.Api.ExportWidgetStats               = window.Api.Base + "/admin/exports/widgets-stats"
window.Api.Partners                        = window.Api.Base + "/admin/partners"
window.Api.PartnerMembers                  = window.Api.Base + "/admin/partners/members"
window.Api.GenerateXML                     = window.Api.Base + "/export/partners-mini-xml"