import React from 'react'
 import { Modal, Tabs, Tab } from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Switch from "react-switch";

import Notify from "../../../utils/Notify";
import Backend from "../../../utils/Backend"
import SmartList from "../common/SmartList";
import Edit from "../channel/Edit";
import Members from "../company/Members";
import Subscription from "../company/Subscription";
import JobsTable from "../tables/JobsTable";
import ExternalDistributionTable from "../tables/ExternalDistributionTable";
import FeedControl from "../company/FeedControl";
import LazyLoadingList from "../common/LazyLoadingList";
import ChannelActivity from "../channel/ChannelActivity";
import ChannelInterest from "../channel/ChannelInterest";
import ChannelPackage from "../channel/ChannelPackage";

export default class ChannelActivityModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }
    this.list = React.createRef()
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      activeTab: "companies",
      show: props.show,
      channel: props.channel,
      loaded: 0,
      companies: [],
      interests: [],
      channels: [],
      packages: [],
    }
  }

  _update(){
    let {
      channel,
      interests,
      channels,
      packages,
      companies,
    } = this.state

    this.setState({loading: true})

    let data = {
      id: channel.id,
      channels,
      companies,
      packages,
    }

    Backend.updateChannel(data).then(response => {
      this.setState({loading: false})
      Notify.success("Channel Updated")
      this.props.onCancel()
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  render() {
    let {
      activeTab,
      loading,
      show,
      channel,
      companies,
      interests,
      channels,
      packages,
    } = this.state

    if(!channel || !show) return null

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
        className={this.props.modalClass}
      >
        <Modal.Header closeButton>
          <h5 className={"modal-title"}>Update {channel.name}</h5>
        </Modal.Header>

        <Modal.Body>

          <Tabs
            activeKey={activeTab}
            onSelect={(tab) => this.setState({activeTab: tab})}
          >
            <Tab eventKey={"companies"} title={"Companies"}>
              <h4>In Channel</h4>
              <ChannelActivity
                type={'companies'}
                channel={channel}
                inChannel={true}
                onProcessed={companies => this.setState({companies})}
                onAdd={company => {
                  companies.push(company)
                  this.setState({companies})
                }}
                onRemove={company => {
                  companies.splice(companies.indexOf(company), 1)
                }}
                loaded={() => this.setState({loaded: this.state.loaded+1})}
              />
              <h4>Not In Channel</h4>
              <ChannelActivity
                type={'companies'}
                channel={channel}
                inChannel={false}
                onAdd={company => {
                  companies.push(company)
                  this.setState({companies})
                }}
                onRemove={company => {
                  companies.splice(companies.indexOf(company), 1)
                }}
                loaded={() => {}}
              />
            </Tab>
            {/*<Tab eventKey={"job-types"} title={"Job Types"}>*/}
            {/*  <h4>In Channel</h4>*/}
            {/*  <ChannelInterest*/}
            {/*    type={'interests'}*/}
            {/*    channel={channel}*/}
            {/*    inChannel={true}*/}
            {/*    onProcessed={interests => this.setState({interests})}*/}
            {/*  />*/}
            {/*  <h4>Not In Channel</h4>*/}
            {/*  <ChannelInterest*/}
            {/*    type={'interests'}*/}
            {/*    channel={channel}*/}
            {/*    inChannel={false}*/}
            {/*    onAdd={jobType => {*/}
            {/*      interests.push(jobType)*/}
            {/*      this.setState({interests})*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Tab>*/}
            <Tab eventKey={"channels"} title={"Channels"}>
              <h4>In Channel</h4>
              <ChannelActivity
                type={'channels'}
                channel={channel}
                inChannel={true}
                onProcessed={channels => this.setState({channels})}
                onAdd={channel => {
                  channels.push(channel)
                  this.setState({channels})
                }}
                onRemove={channel => {
                  channels.splice(channels.indexOf(channel), 1)
                }}
                loaded={() => this.setState({loaded: this.state.loaded+1})}
              />
              <h4>Not In Channel</h4>
              <ChannelActivity
                type={'channels'}
                channel={channel}
                inChannel={false}
                onAdd={channel => {
                  channels.push(channel)
                  this.setState({channels})
                }}
                onRemove={channel => {
                  channels.splice(channels.indexOf(channel), 1)
                }}
                loaded={() => {}}
              />
            </Tab>
            <Tab eventKey={"packages"} title={"Packages"}>
              <h4>In Channel</h4>
              <ChannelPackage
                type={'packages'}
                channel={channel}
                inChannel={true}
                onProcessed={packages => this.setState({packages})}
                onAdd={package_ => {
                  packages.push(package_)
                  this.setState({packages})
                }}
                onRemove={package_ => {
                  packages.splice(packages.indexOf(package_), 1)
                }}
                loaded={() => this.setState({loaded: this.state.loaded+1})}
              />
              <h4>Not In Channel</h4>
              <ChannelPackage
                type={'packages'}
                channel={channel}
                inChannel={false}
                onAdd={package_ => {
                  packages.push(package_)
                  this.setState({packages})
                }}
                onRemove={package_ => {
                  packages.splice(packages.indexOf(package_), 1)
                }}
                loaded={() => {}}
              />
            </Tab>
          </Tabs>

        </Modal.Body>

        <Modal.Footer>
          <div className="col-md-4 text-center">
            <button
              id="reject" type="button" className="btn btn-secondary" style={{width: '100%'}}
              disabled={loading}
              onClick={() => this.props.onCancel()}
            >
              Close
            </button>
          </div>
          <div className="col-md-4 text-center">
            <button
              id="update" type="button" className="btn btn-success" style={{width: '100%'}}
              disabled={this.state.loaded !== 3}
              onClick={() => this._update()}
            >
              Update
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

ChannelActivityModal.defaultProps = {
  loading: false,
  modalClass: 'process-company',
  buttonClassName: 'primary',
}
