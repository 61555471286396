import React from 'react'
import { Modal, Tabs, Tab } from 'react-bootstrap';
import ImageEdit from "../common/ImageEdit";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

export default class BlogPostModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      show: props.show,
      blogPost: props.blogPost || {},
    }
  }

  _isValid(blogPost){
    let error = null;

    if(!blogPost.title){
      error = "Please enter the title"
    }else if(!blogPost.text){
      error = "Please enter the text"
    }else if(!blogPost.image){
      error = "Please include an image"
    }else if(!blogPost.link){
      error = "Please enter the link"
    }else if(!blogPost.posted_at){
      error = "Please enter the posted at"
    }

    if(error){
      Notify.error(error)
    }

    return !error;
  }

  _handleClick(){
    let {
      blogPost,
    } = this.state

    if(!this._isValid(blogPost)){
      return
    }

    if(blogPost.id){
      this._update(blogPost)
    }else{
      this._create(blogPost)
    }
  }

  _uploadImageFile(file, attribute){
    let {
      blogPost
    } = this.state

    this.setState({ loading: true })
    Backend.uploadImage(file, "blog")
      .then(image => {
        blogPost[attribute] = image.image_url
        this.setState({ loading: false, blogPost })
      })
      .catch(error => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _create(blogPost){
    Backend.createBlogPost(blogPost).then(() => {
      Notify.success("Blog Post Created")
      this.props.onUpdate()
    }).catch(e => Notify.error(e.message))
  }

  _update(blogPost){
    Backend.updateBlogPost(blogPost).then(() => {
      Notify.success("Blog Post Updated")
      this.props.onUpdate()
    }).catch(e => Notify.error(e.message))
  }

  render() {
    let {
      loading,
      show,
      blogPost,
    } = this.state

    if(!blogPost || !show) return null

    let title = blogPost.id ? `Update ${blogPost.title}` : 'Add Blog Post'
    let buttonTitle = blogPost.id ? `Update` : 'Add'

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
        className={this.props.modalClass}
      >
        <Modal.Header closeButton>
          <h5 className={"modal-title"}>{title}</h5>
        </Modal.Header>

        <Modal.Body>

          <div className="form-group">
            <label>Title</label>
            <input
              className="form-control"
              type="text"
              value={blogPost.title}
              onChange={e => {
                blogPost.title = e.target.value
                this.setState({blogPost})
              }}
            />
          </div>
          <div className="form-group">
            <label>Text</label>
            <textarea
              value={ blogPost.text }
              className="form-control"
              placeholder="Article body goes here..."
              rows={3}
              onChange={e => {
                blogPost.text = e.target.value
                this.setState({ blogPost})
              }}
            />
          </div>
          <div className="form-group">
            <label>Image</label>
            <ImageEdit
              image={blogPost.image}
              type={"banner"}
              hidePreview={false}
              deletable={true}
              uploadImageFile={file => this._uploadImageFile(file, "image")}
              onDeleteClicked={() => {
                blogPost.image = null
                this.setState({blogPost})
              }}
            />
          </div>
          <div className="form-group">
            <label>Link</label>
            <input
              className="form-control"
              type="text"
              value={blogPost.link}
              onChange={e => {
                blogPost.link = e.target.value
                this.setState({blogPost})
              }}
            />
          </div>
          <div className="form-group">
            <label>Post Date</label>
            <DatePicker
              selected={Date.parse(blogPost.posted_at) || ''}
              dateFormat={'yyyy-MM-dd'}
              className="form-control"
              placeholderText={"Post Date"}
              onChange={date => {
                blogPost.posted_at = moment(date).format('YYYY-MM-DD')
                this.setState({blogPost})
              }}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>

        </Modal.Body>

        <Modal.Footer>
          <div className="col-md-4 text-center">
            <button
              id="reject" type="button" className="btn btn-secondary" style={{width: '100%'}}
              disabled={loading}
              onClick={() => this.props.onCancel()}
            >
              Close
            </button>
          </div>
          <div className="col-md-4 text-center">
            <button
              id="reject" type="button" className="btn btn-success" style={{width: '100%'}}
              disabled={loading}
              onClick={() => this._handleClick()}
            >
              {buttonTitle}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

BlogPostModal.defaultProps = {
  loading: false,
  modalClass: 'process-company',
  buttonClassName: 'primary',
}
