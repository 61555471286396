import React from 'react'
import Modal from 'react-bootstrap/Modal'

export default class BlockPickerModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      show: props.show,
      blocks: props.blocks
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      show,
      blocks,
    } = this.props

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
        className="process-company"
      >
        <Modal.Header closeButton>
          <Modal.Title>Blocks</Modal.Title>
        </Modal.Header>


        <Modal.Body>

          <div
            className={"row"}
          >
            {
              blocks.map(block => (
                <div
                  className={"col-md-6 pb-4"}
                >
                  <div
                    style={{height: 200, width: '100%'}}
                  >
                    <div
                      style={{background: `url(${block.block_image})`, cursor: "pointer"}}
                      onClick={() => this.props.onAdd(block)}
                    >
                      <img src={block.block_image} alt="" width="100%" height="100%"/>
                    </div><br/>
                    <div>
                      <p style={{textAlign: "center"}}>{block.name}</p>
                    </div>
                  </div>

                </div>
              ))
            }
          </div>

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}
