import React from "react"

import Div from "../Div"

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Email from "../../../utils/Email"
import Notify from "../../../utils/Notify"
import SmartList from "../common/SmartList";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FetchHelper from "../../../utils/FetchHelper";
import CompanyItem from "./CompanyItem";
import General from "../../../utils/General";

export default class PopularCompanies extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      popularCompanies: [],
      companies: [],
      loading: false,
      data: AuthManager.currentUser,
      popularCompaniesEndpoint: `${window.Api.Companies}?popular=true`,
      companiesEndpoint: `${window.Api.Companies}`
    }
  }

  componentDidMount() {
    this._loadPopularCompanies()
    this._loadCompanies()
  }

  _loadPopularCompanies(){
    let {
      popularCompaniesEndpoint,
      popularCompanies,
    } = this.state

    this.setState({ loading: true})

    FetchHelper.get(popularCompaniesEndpoint).then(response => {
      popularCompaniesEndpoint = response.next
      let showLoadMore = popularCompaniesEndpoint != null
      popularCompanies = [
        ...popularCompanies,
        ...response.results
      ]
      this.setState({
        popularCompaniesEndpoint,
        popularCompanies,
        showLoadMore,
        loading: false
      })
    }).catch(e => {
      Notify.error(e.message)
      this.setState({ loading: false})
    })
  }

  _search = General.debounce(searchTerm => {
    this.setState({
      searchTerm,
      companies: [],
      companiesEndpoint: `${window.Api.Companies}?search=${searchTerm}` ,
      showLoadMore: false,
    }, () => this._loadCompanies())
  }, 500, false)

  _loadCompanies(){
    let {
      companiesEndpoint,
      companies,
    } = this.state

    this.setState({ loading: true})

    FetchHelper.get(companiesEndpoint).then(response => {
      companiesEndpoint = response.next
      let showLoadMore = companiesEndpoint != null
      companies = [
        ...companies,
        ...response.results
      ]
      this.setState({
        companiesEndpoint,
        companies,
        showLoadMore,
        loading: false
      })
    }).catch(e => {
      Notify.error(e.message)
      this.setState({ loading: false})
    })
  }

  _savePopularCompanies(){
    let {
      popularCompanies
    } = this.state

    let data = {
      companies: popularCompanies
    }

    this.setState({ loading: true })
    Backend.updatePopularCompanies(data)
      .then(() => {
        Notify.success('Popular companies updated')
        this.setState({
          loading: false
        })
      })
      .catch(error => {
        this.setState({
          loading: false
        })
        Notify.error(error.message)
      })
  }

  onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.state[source.droppableId],
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === 'droppable2') {
        state = { selected: items };
      }

      this.setState(state);
    } else {
      const result = move(
        this.state[source.droppableId],
        this.state[destination.droppableId],
        source,
        destination
      );

      this.setState({
        companies: result.companies,
        popularCompanies: result.popularCompanies
      });
    }
  }

  _renderLoadMore(){
    let {
      loading,
      showLoadMore,
    } = this.state

    if(loading){
      return <p className="w-100 text-center">Loading...</p>
    }

    if(!showLoadMore){
      return null
    }

    return (
      <div className="col-12">
        <a
          className="btn btn--secondary w-100"
          style={{
            color: "#222",
            borderColor: "#222",
            backgroundColor: "transparent"
          }}
          onClick={() => this._loadCompanies()}
          disabled={!showLoadMore}
        >
          Load More
        </a>
      </div>
    )
  }

  render() {
    let {
      loading,
      companies,
      searchTerm,
      popularCompanies
    } = this.state

    return (
      <>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div className="col-lg-6">
            <Droppable droppableId="popularCompanies">
              {(provided, snapshot) => (
                <>
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {
                      popularCompanies.map((popularCompany, index) => (
                        <Draggable
                          key={`popular${popularCompany.id.toString()}`}
                          draggableId={`popular${popularCompany.id.toString()}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <CompanyItem
                                index={index}
                                company={popularCompany}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))
                    }
                  </div>
                  <div className="col-12 pl-0 pr-0 pt-3">
                    <button
                      className="btn btn-success w-100"
                      disabled={loading}
                      onClick={() => this._savePopularCompanies()}
                    >
                      Save
                    </button>
                  </div>
                </>
              )}
            </Droppable>
          </div>
          <div className="col-lg-6">
            <Droppable droppableId="companies">
              {(provided, snapshot) => (
                <>
                    <input
                      type="text"
                      name="search"
                      placeholder="Search"
                      className="form-control mb-3"
                      defaultValue={searchTerm}
                      onChange={e => {
                        let searchTerm = e.target.value
                        this._search(searchTerm)
                      }}
                    />
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >

                    {
                      companies.map((company, index) => (
                        <Draggable
                          key={`company${company.id.toString()}`}
                          draggableId={`company${company.id.toString()}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <CompanyItem
                                index={index}
                                company={company}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))
                    }
                    {this._renderLoadMore()}
                  </div>
                </>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </>
    )
  }
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'transparent',
  border: 1,

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : null,
  border: '1px solid #ebedf2',
  padding: grid,
  width: '100%',
  height: 600,
  overflowY: "scroll"
});