import React from 'react'

import Aside from './components/common/Aside'
import AccountSettings from './components/settings/AccountSettings'
import PasswordSettings from './components/settings/PasswordSettings'

const TAB_ACCOUNT = {
  title:"Account Settings",
  subtitle:"Update your account details",
  icon:"cogwheel",
}

const TAB_PASSWORD = {
  title:"Password Settings",
  subtitle:"Manages your password",
  icon:"lock",
}


const TABS = [
  TAB_ACCOUNT,
  TAB_PASSWORD,
]

export default class Settings extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTabIndex: 0,
      tabs: TABS
    }
  }

  _renderContent(tabIndex){
    let tab = this.state.tabs[tabIndex]
    switch(tab){
      case TAB_ACCOUNT:       return <AccountSettings/>
      case TAB_PASSWORD:      return <PasswordSettings/>
      default:                return null
    }
  }

  render() {
    let {
      tabs,
      activeTabIndex
    } = this.state

    return (

      <div className="kt-portlet">
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">

            <Aside
              tabs={tabs}
              activeTabIndex={activeTabIndex}
              onClick={(tab, index) => {
                return this.setState({ activeTabIndex: index })
              }}
            />

            <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
              <div className="kt-form">

                { this._renderContent(activeTabIndex) }

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
