import React from 'react'
 import { Modal, Tabs, Tab } from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Switch from "react-switch";

import Notify from "../../../utils/Notify";
import Backend from "../../../utils/Backend"
import SmartList from "../common/SmartList";
import Edit from "../channel/Edit";
import Members from "../company/Members";
import Subscription from "../company/Subscription";
import JobsTable from "../tables/JobsTable";
import ExternalDistributionTable from "../tables/ExternalDistributionTable";
import FeedControl from "../company/FeedControl";
import LazyLoadingList from "../common/LazyLoadingList";
import FetchHelper from "../../../utils/FetchHelper";
import General from "../../../utils/General";

export default class ChannelPackage extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      endpoint: `${window.Api.Channels}/${props.channel.id}/packages?in_channel=${props.inChannel}&type=${props.type}`,
      packages: [],
      showLoadMore: false,
    }
  }

  componentDidMount() {
    let {
      type,
      channel,
      inChannel
    } = this.state

    this._load(channel, inChannel, type)
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      type: props.type,
      channel: props.channel,
      inChannel: props.inChannel
    }
  }

  _search = General.debounce(searchTerm => {
    this.setState({
      searchTerm,
      packages: [],
      endpoint: `${window.Api.Channels}/${this.state.channel.id}/packages?in_channel=${this.state.inChannel}&type=${this.state.type}&search=${searchTerm}` ,
      showLoadMore: false,
    }, () => this._load())
  }, 500, false)

  _load(){
    let {
      type,
      channel,
      endpoint,
      packages,
      inChannel,
    } = this.state

    this.setState({ loading: true})

    FetchHelper.get(endpoint).then(response => {
      if(!inChannel){
        endpoint = response.next

        packages = [
          ...packages,
          ...response.results
        ]
      }else{
        packages = response
        endpoint = null
      }
      let showLoadMore = endpoint != null

      this.setState({
        endpoint,
        packages,
        showLoadMore,
        loading: false
      }, () => {
        this._processInChannel()
        this.props.loaded()
      })
    }).catch(e => {
      Notify.error(e.message)
      this.setState({ loading: false})
    })
  }

  _processInChannel(){
    let {
      packages,
      inChannel,
    } = this.state

    let processedPackages = []

    if(inChannel){
      processedPackages = packages.map(package_ => package_.id)
      this.props.onProcessed(processedPackages)
    }
  }

  _renderLoadMore(){
    let {
      loading,
      showLoadMore,
    } = this.state

    if(loading){
      return <p className="w-100 text-center">Loading...</p>
    }

    if(!showLoadMore){
      return null
    }

    return (
      <div className="col-12">
        <a
          className="btn btn--secondary w-100"
          style={{
            color: "#222",
            borderColor: "#222",
            backgroundColor: "transparent"
          }}
          onClick={() => this._load()}
          disabled={!showLoadMore}
        >
          Load More
        </a>
      </div>
    )
  }

  render() {
    let {
      inChannel,
      packages,
      searchTerm
    } = this.state

    return (
      <>
        {
          !inChannel &&
          <div className="form-group">
            <input
              type="text"
              name="search"
              placeholder="Search"
              className="form-control mb-3"
              defaultValue={searchTerm}
              onChange={e => {
                let searchTerm = e.target.value
                this._search(searchTerm)
              }}
            />
          </div>
        }
        <div style={{height: 300, overflow: 'scroll', marginBottom: 30}}>
          <div className="row col-lg-12">
            {
              packages.map((package_, index) => {
                let title = package_.title
                return (
                  <div className={"col-lg-4"}>
                    <div className="d-flex align-items-center mb-3">
                      <Switch
                        className="react-switch pull-right"
                        onChange={checked => {
                          package_.in_channel = checked
                          packages[index] = package_
                          if(!checked){
                            this.setState({packages}, () => this.props.onRemove(package_.id))
                          }else{
                            this.setState({packages}, () => this.props.onAdd(package_.id))
                          }
                        }}
                        checked={package_.in_channel}
                        aria-labelledby="neat-label"
                        onColor="#5867dd"
                        offColor="#d5d5d5"
                        checkedIcon={null}
                        uncheckedIcon={null}
                      />
                      <div className="d-flex flex-column font-weight-bold ml-3">
                        <a href="#" className="text-dark text-hover-primary mb-1 font-size-lg">{title}</a>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
          {this._renderLoadMore()}
        </div>
      </>
    )
  }
}

ChannelPackage.defaultProps = {
  loading: false,
  modalClass: 'process-company',
  buttonClassName: 'primary',
}
